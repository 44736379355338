import { gsap } from 'gsap';

/* eslint-disable max-len */
export const isTouchDevice = () => {
  if (typeof navigator === 'undefined') return false;

  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = (query) => window.matchMedia(query).matches;
  /* eslint-disable-next-line no-mixed-operators, no-undef */
  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    return true;
  }
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
};

export const flatAndSort = (object, key) => {
  const keys = Object.keys(object);
  const sortedKeys = keys.sort((a, b) => object[a][key] - object[b][key]);
  const sortedObject = [];
  sortedKeys.forEach((k) => sortedObject.push({
    key: k,
    ...object[k],
  }));
  return sortedObject;
};

export const isMobile = () => {
  console.warn('isMobile() function is deprecated, use is() function instead!'); // eslint-disable-line
  return window.innerWidth < 1024;
};

export const is = (breakpoint) => {
  const b = window.getComputedStyle(document.documentElement).getPropertyValue('--breakpoints');
  return b === `"${breakpoint}"`;
};

export const isSafari = () => typeof navigator !== 'undefined' && navigator.vendor && navigator.vendor.includes('Apple') && navigator.userAgent && !navigator.userAgent.includes('CriOS') && !navigator.userAgent.includes('Chrome') && !navigator.userAgent.includes('FxiOS');

export const isIOS = () => !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

export const isEdge = () => typeof window !== 'undefined' && window.navigator.userAgent.includes('Edge');

export const isIE = () => typeof window !== 'undefined' && (window.navigator.userAgent.includes('MSIE') || window.navigator.userAgent.includes('Trident/'));

export const degrees = (rad) => (rad * 180) / Math.PI;

export const radians = (deg) => (deg * Math.PI) / 180;

export const angleBetween = (v1, v2) => Math.atan2(v2.y - v1.y, v2.x - v1.x);

export const norm = (val, max, min) => (val - min) / (max - min);

export const distanceBetween = (v1, v2) => Math.sqrt((v1.x - v2.x) * (v1.x - v2.x) + (v1.y - v2.y) * (v1.y - v2.y));

export const viewport = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
  ratio: window.innerWidth / window.innerHeight,
});

export const lerp = (a, b, n) => ((1 - n) * a) + (n * b);

export const loadPicture = (src) => {
  const image = new Image();
  const errorHandler = () => new Error(`Failed to load image's URL: ${src}`);
  const promiseFunction = (resolve) => {
    if (typeof image.decode !== 'undefined') {
      image.decoding = 'sync';
      image.src = src;
      image.decode().then(() => {
        if (typeof window.requestIdleCallback !== 'undefined') {
          requestAnimationFrame(() => requestIdleCallback(() => resolve(image)));
        } else {
          requestAnimationFrame(() => requestAnimationFrame(() => resolve(image)));
        }
      }).catch((e) => {
        // Prerendering?
      });
    } else {
      const imageHandler = () => {
        image.removeEventListener('load', imageHandler);
        image.removeEventListener('error', errorHandler);
        image.remove();

        resolve(image);
      };

      image.addEventListener('load', imageHandler());
      image.addEventListener('error', errorHandler());

      image.src = src;
    }
  };

  return new Promise(promiseFunction);
};

/* eslint-disable quote-props, no-nested-ternary */
export const sendGAEvent = (category, action, label, value) => {
  const dataLayer = window.dataLayer || [];

  dataLayer.push({
    'eventCategory': category,
    'eventAction': action,
    'eventLabel': label,
    'eventValue': value,
  });
};

export const attachGAEvents = (events) => {
  events.forEach((event) => {
    const nodes = document.querySelectorAll(event.query_selector);

    nodes.forEach((node) => node.addEventListener(event.type === 'custom' ? event.custom_type : event.type, () => {
      sendGAEvent(event.details.category, event.details.action, event.details.label, event.details.value);
    }));
  });
};

export const createParallaxEl = (el, config, actor = null) => {
  const img = el.querySelector('img');

  el.parallaxAnimation = {};
  el.parallaxAnimation.tl = gsap.timeline({
    paused: true,
  });

  if (actor) actor.updateScaleUniform(config.from.scale);

  el.parallaxAnimation.tl.fromTo(
    img,
    config.from,
    {
      ...config.to,
      onUpdate: () => {
        if (actor && config.from.scale !== config.to.scale) actor.updateScaleUniform(gsap.getProperty(img, 'scale'));
        if (actor && config.from.yPercent !== undefined) actor.updateOriginY(0.5 + (gsap.getProperty(img, 'yPercent') * -0.05));
      },
    },
  );

  el.parallaxAnimation.progressTween = () => {
    const bounding = el.getBoundingClientRect();
    const durationDistance = window.innerHeight + bounding.height;
    const currentProgress = Math.abs(window.innerHeight - bounding.top) / durationDistance;

    el.parallaxAnimation.tl.progress(currentProgress);
  };
};

export const createParallaxContEl = (el) => {
  const img = el.querySelector('[data-item]');

  el.parallaxAnimation = {};
  el.parallaxAnimation.tl = gsap.timeline({
    paused: true,
  });
  const yFinal = ((el.getBoundingClientRect().height - img.getBoundingClientRect().height) / img.getBoundingClientRect().height) * 100;
  el.parallaxAnimation.tl.fromTo(img, {
    yPercent: 0,
  }, {
    yPercent: yFinal,
    ease: 'power1.inOut',
  });

  el.parallaxAnimation.progressTween = () => {
    const bounding = el.getBoundingClientRect();
    const durationDistance = window.innerHeight + bounding.height;
    const currentProgress = Math.abs(window.innerHeight - bounding.top) / durationDistance;

    el.parallaxAnimation.tl.progress(currentProgress);
  };
};
