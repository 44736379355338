import { GoogleMapsModule } from './GoogleMapsModule';
import { memoizeAsync } from '../../../../core/src/lib/HelperFunctions';
import { mKey, TMarkerKey } from './GoogleMapsMarkerModule';

export type TPlacesResponse = Partial<google.maps.places.PlaceResult>;

type TGooglePlacesModuleParams = {
  placesModule: GoogleMapsModule;
};

export class GooglePlacesModule {
  private readonly placesModule: GoogleMapsModule;

  private readonly placesService: google.maps.places.PlacesService;

  private readonly placesAutocomplete: google.maps.places.AutocompleteService;

  readonly searchResults = {} as Record<TMarkerKey, TPlacesResponse>;

  readonly reversedSearchResults = {} as Record<string/* TPlacesResponse['place_id'] */, google.maps.LatLng>;

  private userPosition: Position | undefined;

  constructor(params: TGooglePlacesModuleParams) {
    this.placesModule = params.placesModule;
    this.placesService = new google.maps.places.PlacesService(this.placesModule.map);
    this.placesAutocomplete = new google.maps.places.AutocompleteService();
    navigator.geolocation.getCurrentPosition((location) => {
      this.userPosition = location;
    });
  }

  onSearch = async (query: string) => {
    const results = await this.getPlaceDetailsFromQuery(query);

    // Add all results to cache
    results.forEach((result) => {
      const location = result?.geometry?.location;
      if (location) {
        this.searchResults[mKey(location)] = result;
        this.reversedSearchResults[result.place_id as string] = location;
      }
    });

    return results;
  };

  private readonly getPlaceDetailsFromQuery = async (query: string) => {
    const queryResults = await this.findPlacesFromQuery(query);
    const results = await Promise.all(queryResults.map((result) => {
      const place_id = result?.place_id;
      if (place_id) {
        return this.getPlaceDetails(place_id);
      }
      return Promise.resolve(undefined);
    }));

    return results.filter((place): place is Partial<google.maps.places.PlaceResult> => place != null);
  };

  private readonly findPlacesFromQuery = memoizeAsync((query: string) => {
    return new Promise<google.maps.places.QueryAutocompletePrediction[]>((resolve, reject) => {
      this.placesAutocomplete.getQueryPredictions(
        {
          input: query,
          bounds: this.userPosition != null ? {
            east: this.userPosition.coords.longitude,
            north: this.userPosition.coords.latitude,
            south: this.userPosition.coords.latitude,
            west: this.userPosition.coords.longitude,
          } : undefined,
        },
        (results, status) => {
          if (!results || status !== google.maps.places.PlacesServiceStatus.OK) {
            return reject(status);
          }

          return resolve(results);
        },
      );
    });
  });

  private readonly findPlaceFromQuery = memoizeAsync((query: string) => {
    return new Promise<google.maps.places.PlaceResult[]>((resolve, reject) => {
      this.placesService.findPlaceFromQuery(
        {
          query,
          fields: ['place_id'],
        },
        (results, status) => {
          if (!results || status !== google.maps.places.PlacesServiceStatus.OK) {
            return reject(status);
          }

          return resolve(results);
        },
      );
    });
  });

  private readonly getPlaceDetails = memoizeAsync((placeId: string): Promise<TPlacesResponse> => {
    return new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
      this.placesService.getDetails(
        {
          placeId,
          fields: [
            'name',
            'place_id',
            'address_components',
            'formatted_address',
            'international_phone_number',
            'geometry.location',
          ],
        },
        (results, status) => {
          if (!results || status !== google.maps.places.PlacesServiceStatus.OK) {
            return reject(status);
          }

          return resolve(results);
        },
      );
    });
  });
}
