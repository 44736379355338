import { Config } from '../config/Config';

export const AppStoreLinks = Object.freeze({
  cgrAppleStore() {
    return `https://apps.apple.com/app/id${Config.CGR_APPLE_ID}`;
  },
  cgrAndroidStore() {
    return `https://play.google.com/store/apps/details?id=${Config.CGR_ANDROID_ID}`;
  },
  chmMacLink() {
    return Config.CHM_RELEASES_MAC_DOWNLOAD;
  },
  chmWindowsLink() {
    return Config.CHM_RELEASES_WIN_DOWNLOAD;
  },
  chmLinuxLink() {
    return Config.CHM_RELEASES_LINUX_DOWNLOAD;
  },
});
