import * as React from 'react';
import { WindowContext } from './WindowContext';

type TUseOnClickOutsideParams = {
  onClickOutside: () => void;
  ref: React.MutableRefObject<any> | undefined;
};

export function useOnClickOutside({
  onClickOutside,
  ref,
}: TUseOnClickOutsideParams) {
  const { windowRef } = React.useContext(WindowContext);

  const handleClickOutside = (event) => {
    if (!ref) {
      return;
    }

    if (ref.current && !ref.current.contains(event.target)) {
      onClickOutside();
    }
  };

  React.useEffect(() => {
    const document = windowRef.current.document;
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
}
