import * as React from 'react';
import styled from 'styled-components';
import { HeaderStyle, MediaMaxWidth } from './HeaderStyle';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useOnHostLogout } from '../../app/user/UseOnHostLogout';
import { SvgAlacarteLogo } from '../../assets/Assets';
import { fixedHeight } from '../css';
import { Colors } from '../../config/Colors';
import { hexToRGB } from '../../../../core/src/lib/HelperFunctions';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';
import { LocalizedLink } from '../router/LocalizedLink';
import { HeaderLink } from './HeaderLink';
import { HeaderButtonRed } from './HeaderButtonRed';
import { Str } from '../localized/Str';
import { DivLinkItem } from '../DivLinkItem';
import { Lang } from '../Lang';
import { Strings } from '../../../../core/src/locale/Strings';
import { UserContext } from '../../app/user/UserContext';

const navControlArea = 'responsive-navbar-nav';
const navBreakpoint = 'lg';

export const Header = () => {
  const { isLoggedIn } = React.useContext(UserContext);
  const onHostLogout = useOnHostLogout();
  return (
    <StyledNavbar
      collapseOnSelect
      expand={navBreakpoint}
      sticky={'top'}
    >
      <StyledContainer fluid={navBreakpoint}>
        <StyledBrand>
          <LocalizedLink
            route={RoutesLanding.Home.name}
            hash={RoutesLanding.Home.hashes.top}
            search={{}}
            title={(
              <StyledHeaderLogo src={SvgAlacarteLogo}/>
            )}
          />
        </StyledBrand>
        <StyledToggle
          aria-controls={navControlArea}
        />
        <StyledCollapse
          id={navControlArea}>
          <StyledNavLeft>
            <StyledNavItem key={'landing-header-info-nav'}>
              <LocalizedLink
                hashLinkKey={'landing-header-info-hash'}
                route={RoutesLanding.Home.name}
                hash={RoutesLanding.Home.hashes.info}
                search={{}}
                title={(
                  <HeaderLink
                    title={<Str k={Strings.keys.landingHeaderInfo}/>}
                  />
                )}
              />
            </StyledNavItem>
            <StyledNavItem key={'landing-header-contact-nav'}>
              <LocalizedLink
                hashLinkKey={'landing-header-contact-hash'}
                route={RoutesLanding.Home.name}
                hash={RoutesLanding.Home.hashes.contact}
                search={{}}
                title={(
                  <HeaderLink
                    title={<Str k={Strings.keys.landingHeaderContact}/>}
                  />
                )}
              />
            </StyledNavItem>
            <StyledNavItem key={'landing-header-download-nav'}>
              <LocalizedLink
                hashLinkKey={'landing-header-download-hash'}
                route={RoutesLanding.Home.name}
                hash={RoutesLanding.Home.hashes.download}
                search={{}}
                title={(
                  <HeaderLink
                    title={<Str k={Strings.keys.landingHeaderDownload}/>}
                  />
                )}
              />
            </StyledNavItem>
            <StyledNavItem key={'landing-header-pricing-nav'}>
              <LocalizedLink
                hashLinkKey={'landing-header-pricing-hash'}
                route={RoutesLanding.Plans.name}
                hash={RoutesLanding.Plans.hashes.top}
                search={{}}
                title={(
                  <HeaderLink
                    title={<Str k={Strings.keys.landingHeaderPricing}/>}
                  />
                )}
              />
            </StyledNavItem>
          </StyledNavLeft>
          <StyledNav>
            <StyledNavItem>
              <Lang/>
            </StyledNavItem>
            <StyledNavItem>
              <StyledDivider/>
            </StyledNavItem>
            {!isLoggedIn && (
              <StyledNavItem key={'landing-header-login-nav'}>
                <LocalizedLink
                  hashLinkKey={'landing-header-login-hash'}
                  route={RoutesLanding.Login.name}
                  hash={RoutesLanding.Login.hashes.top}
                  search={{}}
                  title={(
                    <HeaderLink
                      title={<Str k={Strings.keys.landingHeaderLogin}/>}
                    />
                  )}
                />
              </StyledNavItem>
            )}
            {isLoggedIn && (
              <StyledNavItem key={'landing-header-profile-nav'}>
                <LocalizedLink
                  hashLinkKey={'landing-header-profile-hash'}
                  route={RoutesLanding.Profile.name}
                  hash={RoutesLanding.Profile.hashes.top}
                  search={{}}
                  title={(
                    <HeaderButtonRed
                      title={<Str k={Strings.keys.landingHeaderProfile}/>}
                    />
                  )}
                />
              </StyledNavItem>
            )}
            {!isLoggedIn && (
              <StyledNavItem key={'landing-header-signup-nav'}>
                <LocalizedLink
                  hashLinkKey={'landing-header-signup-hash'}
                  route={RoutesLanding.Signup.name}
                  hash={RoutesLanding.Signup.hashes.top}
                  search={{}}
                  title={(
                    <HeaderButtonRed
                      title={<Str k={Strings.keys.landingHeaderSignup}/>}
                    />
                  )}
                />
              </StyledNavItem>
            )}
            {isLoggedIn && (
              <StyledNavItem key={'landing-header-logout-nav'}>
                <DivLinkItem
                  title={(
                    <HeaderLink
                      title={<Str k={Strings.keys.landingHeaderLogout}/>}
                    />
                  )}
                  onClick={onHostLogout}
                />
              </StyledNavItem>
            )}
          </StyledNav>
        </StyledCollapse>
      </StyledContainer>
    </StyledNavbar>
  );
};

const StyledNavbar = styled(Navbar)`
  ${fixedHeight(HeaderStyle.height)};
  background-color: ${Colors.white};
  border-bottom: 1px solid ${hexToRGB(Colors.accentDark, 0.3)};
  z-index: 9999;


  .show .nav-item {
    padding: 10px 0;
    width: 100%;
  }

  .collapsing .nav-item {
    padding: 10px 0;
    width: 100%;
  }

  .show {
    border-bottom: 1px solid ${hexToRGB(Colors.accentDark, 0.3)};
    padding: 0 0 2% 0;
  }
`;

const StyledContainer = styled(Container)`
  background-color: ${Colors.white};
`;

const StyledBrand = styled(Navbar.Brand)`
  margin-right: 24px;
`;

const StyledToggle = styled(Navbar.Toggle)`
  border: 0;

  :focus,
  :active,
  :focus {
    box-shadow: none;
    outline: none;
  }
`;

const StyledCollapse = styled(Navbar.Collapse)`
`;

const StyledNav = styled(Nav)`
  & > * {
    margin-right: ${HeaderStyle.itemSpace}px;
  }
`;

const StyledNavLeft = styled(StyledNav)`
  margin-right: auto !important;
`;

const StyledNavItem = styled(Nav.Item)`
  align-items: center;
  background-color: ${Colors.white};
  display: flex;
  justify-content: center;
`;

const StyledHeaderLogo = styled.img`
  ${fixedHeight(34)};
`;

const StyledDivider = styled.div`
  background-color: ${Colors.accentDark};
  height: 80%;
  opacity: 0.3;
  width: 1px;
  @media ${MediaMaxWidth.lg} {
    width: 20%;
    height: 1px;
  }
`;
