import { gsap } from 'gsap';
import { Elements } from '../../Elements';

export default class Process {
  constructor() {
    this.ctx = Elements.process;

    this.inTransition = false;
    this.currentList = 'restaurant';

    this.restaurant = {
      button: Elements.processRestaurant,
      list: Elements.processRestaurantList,
    };
    this.customer = {
      button: Elements.processCustomer,
      list: Elements.processCustomerList,
    };
  }

  init() {
    gsap.set([...this.customer.list.querySelectorAll('.icon-list__item--icon')], {
      autoAlpha: 0,
    });
    gsap.set([...this.customer.list.querySelectorAll('.icon-list__item--dotted')], {
      autoAlpha: 0,
    });

    this.addEvents();
  }

  addEvents() {
    this.restaurant.button.addEventListener('click', () => this.changeList('restaurant'));
    this.customer.button.addEventListener('click', () => this.changeList('customer'));
  }

  changeList(enter) {
    if (this.inTransition || enter === this.currentList) return;
    this.inTransition = true;
    this.currentList = enter;

    this.dsk = window.innerWidth >= 768;

    const current = this[enter];
    const old = this[enter === 'customer' ? 'restaurant' : 'customer'];
    const currentIcons = [...current.list.querySelectorAll('.icon-list__item--icon')];
    const currentDots = [...current.list.querySelectorAll('.icon-list__item--dotted')];
    const oldIcons = [...old.list.querySelectorAll('.icon-list__item--icon')];
    const oldDots = [...old.list.querySelectorAll('.icon-list__item--dotted')];
    const tl = gsap.timeline({
      onComplete: () => {
        this.inTransition = false;
      },
    });

    old.button.classList.remove('filter-button--active');
    current.button.classList.add('filter-button--active');

    tl.addLabel('start')
      .to(oldIcons, {
        x: this.dsk ? -50 : 0,
        y: this.dsk ? 0 : -50,
        autoAlpha: 0,
        ease: 'old',
        duration: 0.5,
        stagger: 0.1,
      }, 'start')
      .fromTo(oldDots, {
        webkitClipPath: 'inset(-0.01% -0.02% -0.03% 0%)',
        clipPath: 'inset(-0.01% -0.02% -0.03% 0%)',
      }, {
        webkitClipPath: this.dsk ? 'inset(-0.01% 100% -0.03% 0%)' : 'inset(-0.01% -0.02% 100% -0.03%)',
        clipPath: this.dsk ? 'inset(-0.01% 100% -0.03% 0%)' : 'inset(-0.01% -0.02% 100% -0.03%)',
        x: this.dsk ? -50 : 0,
        y: this.dsk ? 0 : -50,
        ease: 'old',
        duration: 0.5,
        stagger: 0.1,
      }, 'start+=0.1')
      .fromTo(currentIcons, {
        x: this.dsk ? 50 : 0,
        y: this.dsk ? 0 : 50,
        autoAlpha: 0,
      }, {
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: 'old',
        duration: 0.5,
        stagger: 0.1,
      }, 'start+=1')
      .fromTo(currentDots, {
        webkitClipPath: this.dsk ? 'inset(-0.01% 0% -0.03% 100%)' : 'inset(100% -0.01% -0.02% -0.03%)',
        clipPath: this.dsk ? 'inset(-0.01% 0% -0.03% 100%)' : 'inset(100% -0.01% -0.02% -0.03%)',
        x: 0,
        y: 0,
        autoAlpha: 1,
      }, {
        webkitClipPath: 'inset(-0.01% -0.02% -0.03% 0%)',
        clipPath: 'inset(-0.01% -0.02% -0.03% 0%)',
        ease: 'old',
        duration: 0.5,
        stagger: 0.1,
      }, 'start+=1.2');
  }
}
