import * as React from 'react';

import { QuestionLinkRegister } from '../../components/QuestionLink';
import styled from 'styled-components';
import {
  fixedHeight,
  fixedWidth,
  FullCenteredRow,
} from '../../components/css';
import { Colors } from '../../config/Colors';
import { LoaderPulseWhite } from '../../components/Spinners';
import { FormDefLogin } from '../../components/formLogin/FormDefLogin';
import { useFormLogin } from '../../components/formLogin/useFormLogin';
import { Strings } from '../../../../core/src/locale/Strings';
import { useFormFocusController } from '../../../../lib-react/src/hooks/useFormFocusController';
import { FormDefSignup } from '../../components/formSignup/FormDefSignup';
import { StyledHotKeys } from '../../components/StyledHotKeys';
import { FormRow } from '../../components/form/FormRow';
import { FormTextField } from '../../components/form/FormTextField';
import { Str } from '../../components/localized/Str';
import { FormGlobalError } from '../../components/form/FormGlobalError';
import { DivLinkItem } from '../../components/DivLinkItem';

export const LoginForm = () => {
  const {
    form,
    onSubmit,
  } = useFormLogin();

  const {
    onGeneralFocus,
    getInputRef,
  } = useFormFocusController();

  return (
    <StyledHotKeys
      keyMap={{ ENTER: 'Enter' }}
      handlers={{ ENTER: onGeneralFocus }}
    >
      <Root>
        <FormContainer>
          <FormRow>
            <FormTextField
              form={form}
              type="email"
              definition={FormDefLogin.email}
              label={<Str k={Strings.keys.landingLoginEmailLabel}/>}
              inputRef={getInputRef({ tabIndex: 0 })}
            />
          </FormRow>
          <FormRow>
            <FormTextField
              form={form}
              type="password"
              definition={FormDefLogin.password}
              label={<Str k={Strings.keys.landingLoginPasswordLabel}/>}
              inputRef={getInputRef({
                tabIndex: 1,
                clickThis: true,
                focusNext: true,
                clickNext: true,
              })}
            />
          </FormRow>
        </FormContainer>
        <ErrorFullCenteredRow>
          <FormGlobalError
            form={form}
            fieldName={FormDefSignup.meta_globalError.name}
          />
        </ErrorFullCenteredRow>
        <ButtonFullCenteredRow>
          <DivLinkItem
            onClick={onSubmit}
            LoadingComponent={LoaderPulseWhite}
            title={<Str k={Strings.keys.landingLoginSubmitLabel}/>}
            wrap={({ children }) => (
              <LoginButtonRed>
                {children}
              </LoginButtonRed>
            )}
            submitElementRef={getInputRef({
              tabIndex: 2,
              clickThis: true,
              focusNext: true,
              clickThisOnGeneralFocus: true,
            })}
          />
        </ButtonFullCenteredRow>
        <StyledQuestionLinkRegister/>
      </Root>
    </StyledHotKeys>
  );
};

const Root = styled.form`
`;

export const LoginButtonRed = styled(FullCenteredRow)`
  ${fixedHeight(55)};
  ${fixedWidth(305)};
  background-color: ${Colors.red};
  color: ${Colors.white};
  flex: 1;
  font-size: 11px;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 15px;
  padding: 0 35px;
  text-align: center;
  text-transform: uppercase;
`;

const ErrorFullCenteredRow = styled(FullCenteredRow)`
  margin-top: 0;
`;

const ButtonFullCenteredRow = styled(FullCenteredRow)`
  margin-top: 20px;
`;

const StyledQuestionLinkRegister = styled(QuestionLinkRegister)`
  margin-top: 20px;
`;

export const FormContainer = styled.div`
  margin-top: 30px;
  ${fixedWidth(305)};
`;
