import * as React from 'react';
import { UTitle } from './css';
import styled from 'styled-components';

type TScreenHeaderProps = {
  title: React.ReactNode;
  description?: React.ReactNode;
  className?: string;
};

export const ScreenHeader = ({
  title,
  description = <></>,
  className,
}: TScreenHeaderProps) => {
  return (
    <Root className={className}>
      <UTitle>
        {title}
      </UTitle>
      <DescriptionContainer>
        {description}
      </DescriptionContainer>
    </Root>
  );
};

const Root = styled.div`
  padding: 8% 8% 0 8%;
`;

const DescriptionContainer = styled.div`
  color: #2B2C33;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  margin: 25px auto;
  max-width: calc(100vw - 8%);
  text-align: center;
`;
