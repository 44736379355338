import * as React from 'react';
import { TClassNameProps } from './TReactChildrenProps';
import { Str } from './localized/Str';
import styled from 'styled-components';
import { Colors } from '../config/Colors';
import { TRoutesLandingNames, RoutesLanding } from '../../../core/src/lib/apis/routes/RoutableLanding';
import { LocalizedLink } from './router/LocalizedLink';
import { Strings } from '../../../core/src/locale/Strings';

type TQuestionLinkProps = {
  question: React.ReactNode;
  linkText: React.ReactNode;
  route: TRoutesLandingNames;
  className?: string;
};

export const QuestionLink = ({
  question,
  linkText,
  route,
  className,
}: TQuestionLinkProps) => {
  return (
    <Root className={className}>
      {question}
      {' '}
      <LocalizedLink
        title={linkText}
        route={route}
        search={{}}
      />
    </Root>
  );
};

const Root = styled.div`
  color: #2B2C33;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
`;

export const QuestionLinkLogin = ({ className }: TClassNameProps) => {
  return (
    <QuestionLink
      className={className}
      question={<Str k={Strings.keys.landingSignupLoginctaLabel}/>}
      linkText={<StyledLinkString k={Strings.keys.landingSignupLoginctaText} />}
      route={RoutesLanding.Login.name}
    />
  );
};

export const QuestionLinkRegister = ({ className }: TClassNameProps) => {
  return (
    <QuestionLink
      className={className}
      question={<Str k={Strings.keys.landingSignInNoAccount}/>}
      linkText={<StyledLinkString k={Strings.keys.landingLoginSignupctaText} />}
      route={RoutesLanding.Signup.name}
    />
  );
};

const StyledLinkString = styled(Str)`
  color: ${Colors.red};
  text-decoration: underline;
`;
