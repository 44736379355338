import * as React from 'react';
import styled from 'styled-components';
import { fixedHeight } from '../../components/css';
import {
  hocBuildSpinner,
  LoaderPulse,
} from '../../components/Spinners';
import { ProfileBox, TProfileBoxProps } from './ProfileBox';
import { DivLinkItem } from '../../components/DivLinkItem';

type TProfileBoxSimpleActionProps = Omit<TProfileBoxProps, 'action'> & {
  ctaText: React.ReactNode;
  ctaOnClick: () => any;
  negativeThemeColor: string;
};

export const ProfileBoxSimpleAction = (props: TProfileBoxSimpleActionProps) => {
  const {
    ctaText,
    ctaOnClick,
    themeColor,
    negativeThemeColor,
  } = props;
  const LoadingComponent = hocBuildSpinner(LoaderPulse, { color: negativeThemeColor });
  return (
    <ProfileBox
      {...props}
      action={(
        <DivLinkItem
          onClick={ctaOnClick}
          LoadingComponent={LoadingComponent}
          title={ctaText}
          wrap={({ children }) => (
            <ProfileButton negativeThemeColor={negativeThemeColor} themeColor={themeColor}>
              {children}
            </ProfileButton>
          )}
        />
      )}
    />
  );
};

const ProfileButton = styled.div<{ themeColor: string; negativeThemeColor: string }>`
  ${fixedHeight(55)};
  align-items: center;
  background-color: ${({ themeColor }) => themeColor};
  color: ${({ negativeThemeColor }) => negativeThemeColor};
  display: flex;
  flex: 1;
  font-size: 11px;
  font-weight: 800;
  justify-content: center;
  letter-spacing: 2px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`;
