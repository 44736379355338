import * as React from 'react';

import { FormDefSignup } from '../../components/formSignup/FormDefSignup';
import { useFormContact } from '../../components/formContact/useFormContact';
import { FormDefContact } from '../../components/formContact/FormDefContact';
import { LoaderPulseWhite } from '../../components/Spinners';
import { Strings } from '../../../../core/src/locale/Strings';
import styled from 'styled-components';
import { useFormFocusController } from '../../../../lib-react/src/hooks/useFormFocusController';
import { StyledHotKeys } from '../../components/StyledHotKeys';
import { FormGlobalError } from '../../components/form/FormGlobalError';
import { FormRow } from '../../components/form/FormRow';
import { FormTextField } from '../../components/form/FormTextField';
import { Str } from '../../components/localized/Str';
import { FormCheckbox } from '../../components/form/FormCheckbox';
import { DivLinkItem } from '../../components/DivLinkItem';
import { RegisterButtonRed } from '../signup/SignupForm';

type TContactFormProps = {
  //
};

export const ContactForm = (props: TContactFormProps) => {
  const {
    form,
    onSubmit,
  } = useFormContact();

  const {
    onGeneralFocus,
    getInputRef,
  } = useFormFocusController();

  return (
    <StyledHotKeys
      keyMap={{ ENTER: 'Enter' }}
      handlers={{ ENTER: onGeneralFocus }}
    >
      <form
        id="form-contact"
        className="form">
        <FormGlobalError
          form={form}
          fieldName={FormDefSignup.meta_globalError.name}
        />
        <FormRow>
          <FormTextField
            siblingCount={2}
            variant="standard"
            form={form}
            definition={FormDefContact.name}
            label={<Str k={Strings.keys.landingContactName}/>}
            inputRef={getInputRef({ tabIndex: 0 })}
          />
          <FormTextField
            siblingCount={2}
            variant="standard"
            form={form}
            definition={FormDefContact.hostName}
            label={<Str k={Strings.keys.landingContactHostName}/>}
            inputRef={getInputRef({ tabIndex: 1 })}
          />
        </FormRow>
        <FormRow>
          <FormTextField
            siblingCount={2}
            variant="standard"
            form={form}
            definition={FormDefContact.email}
            label={<Str k={Strings.keys.landingContactEmail}/>}
            inputRef={getInputRef({ tabIndex: 2 })}
          />
          <FormTextField
            siblingCount={2}
            variant="standard"
            form={form}
            definition={FormDefContact.phone}
            label={<Str k={Strings.keys.landingContactPhone}/>}
            inputRef={getInputRef({ tabIndex: 3 })}
          />
        </FormRow>
        <SignupSectionSubmit>
          <FormCheckbox
            form={form}
            definition={FormDefSignup.meta_acceptConditions}
            label={<Str k={Strings.keys.landingFormFieldTacLabel}/>}
            inputRef={getInputRef({
              tabIndex: 4,
              clickThis: true,
              focusNext: true,
            })}
          />
          <DivLinkItem
            onClick={onSubmit}
            submitElementRef={getInputRef({
              tabIndex: 5,
              clickThis: true,
              focusNext: true,
              clickThisOnGeneralFocus: true,
            })}
            LoadingComponent={LoaderPulseWhite}
            title={<Str k={Strings.keys.landingContactSendRequest}/>}
            wrap={({ children }) => (
              <RegisterButtonRed>
                {children}
              </RegisterButtonRed>
            )}
          />
        </SignupSectionSubmit>
      </form>
    </StyledHotKeys>
  );
};

const SignupSectionSubmit = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  & > * {
    margin-top: 20px;
  }
`;
