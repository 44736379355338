import * as React from 'react';
import styled from 'styled-components';
import { fixedWidth } from './css';
import { LoaderClipAccentDark20 } from './Spinners';
import { useLocale } from '../locale/useLocale';
import { Colors } from '../config/Colors';
import { usePrevious } from '../../../lib-react/src/hooks/usePrevious';
import { useLoadingCallback } from '../../../lib-react/src/hooks/useLoadingCallback';

type TDivLinkItem = {
  title: React.ReactNode;
  onClick?: () => Promise<void>;
  LoadingComponent?: typeof LoaderClipAccentDark20;
  wrap?: (p: { children: React.ReactNode }) => React.ReactNode;
  tabIndex?: number;
  submitElementRef?: any;
};
export const DivLinkItem = ({
  title,
  onClick,
  tabIndex,
  submitElementRef = React.useRef<any>(null),
  LoadingComponent = LoaderClipAccentDark20,
  wrap = ({ children }: { children: React.ReactNode }) => children,
}: TDivLinkItem) => {
  const [onLinkClick, loading] = useLoadingCallback(onClick);

  const widthRef = React.useRef<any>(null);

  // Recalculate width on locale change
  // This needs to be done pre-render
  const locale = useLocale();
  const previousLocale = usePrevious(locale);
  if (previousLocale != locale && !loading) {
    widthRef.current = undefined;
  }

  React.useEffect(() => {
    if (loading) {
      return;
    }
    widthRef.current = submitElementRef.current?.offsetWidth;
  });

  return (
    <StyledLinkItem>
      <StyledDivLinkItem
        tabIndex={tabIndex || -1}
        ref={submitElementRef}
        fixWidth={loading}
        width={widthRef.current}
        onClick={onLinkClick}
        showCursor={!loading}>
        {wrap({
          children: (
            <>
              {loading && (
                <LoadingComponent/>
              )}
              {!loading && title}
            </>
          ),
        })}
      </StyledDivLinkItem>
    </StyledLinkItem>
  );
};

const StyledLinkItem = styled.div`
`;

const StyledDivLinkItem = styled.div<{ width?: number; fixWidth: boolean; showCursor: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${({ width, fixWidth }) => ((!width || fixWidth) ? '' : fixedWidth(width))};
  ${({ showCursor }) => (showCursor ? 'cursor: pointer;' : '')};
  :focus {
    outline-width: 1px;
    outline-color ${Colors.accentDark};
  }
`;
