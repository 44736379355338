export const HeaderStyle = {
  height: 60,
  itemSpace: 30,
  contentHeight: 45,
};

export const BreakPoints = {
  xsMax: '576px',
  md: '768px',
  lg: '992px',
  xlMin: '1200px',
};

export const MediaMinWidth = {
  sm: `(min-width: ${BreakPoints.xsMax})`,
  md: `(min-width: ${BreakPoints.md})`,
  lg: `(min-width: ${BreakPoints.lg})`,
  xl: `(min-width: ${BreakPoints.xlMin})`,
};

export const MediaMaxWidth = {
  xs: `(max-width: ${BreakPoints.xsMax})`,
  sm: `(max-width: ${BreakPoints.md})`,
  md: `(max-width: ${BreakPoints.lg})`,
  lg: `(max-width: ${BreakPoints.xlMin})`,
};
