import * as _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { Config } from '../../config/Config';
import { isDevelopment } from '../../../../core/src/lib/Environment';
import { Log } from '../../config/Instance';
import { AnalyticsEvents, TEvent, TEventData, TEventKeys } from './AnalyticsEvents';
import { ELocale } from '../../locale/Locale';
import Posthog from 'posthog-js';
import { isPreRendering } from '../ReactSnap';

type TAnalyticsProfile = {
  hostId: string;
  email: string;
  address?: string;
  subscriptionStartedOn?: string | number;
  subscriptionType?: string | number;
  locale: ELocale;
};

class AnalyticsBase {
  static cleanupEventData<K extends TEventKeys>(data: TEvent<K>): TEventData<K> {
    const defaultData = AnalyticsEvents[data.eventId].defaultData;
    return _.reduce(_.keys(defaultData), (acc, key) => {
      const val = !_.isEmpty(data[key])
        ? data[key]
        : defaultData[key];

      if (val !== undefined) {
        acc[key] = val;
      }

      return acc;
    }, {} as any);
  }

  static eventToEventId<K extends TEventKeys>(data: TEvent<K>): string {
    const eventIdPostfixKey = AnalyticsEvents[data.eventId].eventIdPostfixKey;
    if (AnalyticsEvents[data.eventId].eventIdPostfixKey == null) {
      return data.eventId;
    }

    const postfixValue = data[eventIdPostfixKey];
    if (postfixValue == null) {
      throw new Error(`Analytics, eventToEventId postfixValue from key ${eventIdPostfixKey} is null`);
    }

    return `${data.eventId}/${postfixValue}`;
  }

  constructor() {
    mixpanel.init(Config.LANDING_MIXPANEL_API_KEY, { debug: isDevelopment(Config.env) });
    Posthog.init(Config.LANDING_POSTHOG_API_KEY, { api_host: 'https://app.posthog.com' });
  }

  readonly userIdentify = async (params: TAnalyticsProfile) => {
    await mixpanel.identify(params.hostId);
    _.forOwn(params, (val, key) => mixpanel.people.set(key, val));
    Posthog.identify(params.hostId, params);
  };

  readonly userAlias = async ({ hostId }: { hostId: string }) => {
    mixpanel.alias(hostId);
    Posthog.alias(hostId);
  };

  readonly reset = async () => {
    mixpanel.reset();
    Posthog.reset();
  };

  readonly track = async <K extends TEventKeys>(event: TEvent<K>) => {
    const data = AnalyticsBase.cleanupEventData(event);

    if (isDevelopment()) {
      Log.v('Analytics', 'track', `tracking ${event.eventId}`, data);
    }

    const id = AnalyticsBase.eventToEventId(event);
    mixpanel.track(id, data);
    Posthog.capture(id, data);
  };
}

export const Analytics: AnalyticsBase = !isPreRendering() ? new AnalyticsBase() : <{ [k in keyof AnalyticsBase]: any }>{
  reset: _.noop,
  track: _.noop,
  userAlias: _.noop,
  userIdentify: _.noop,
};
