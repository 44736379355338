import { gsap } from 'gsap';
import debounce from 'lodash/debounce';
import Info from './components/Info';

import Process from './components/Process';
import CustomEase from '../assets/libs/CustomEase';

import ScrollTo from '../assets/libs/ScrollToPlugin';
import { Log } from '../../config/Instance';

gsap.registerPlugin(CustomEase, ScrollTo);

CustomEase.create('old', '0.65, 0, 0.25, 1');

function safe(name, func) {
  try {
    func();
  } catch (e) {
    Log.v('main.js', 'safe', `Error running ${name}`, e);
  }
}

export const initializeHomeBindings = (infoData) => {
  // init info class
  const info = new Info(infoData);
  safe('info', () => info.init());

  // init process class
  const process = new Process();
  safe('process', () => process.init());

  // add resize function
  const resize = () => {
    const vh = window.innerHeight * 0.01;
    document.body.style.setProperty('--vh', `${vh}px`);

    info.resize();
  };
  resize();

  const debouncedResize = debounce(() => resize(), 250);
  window.addEventListener('resize', debouncedResize);

  return () => {
    window.removeEventListener('resize', debouncedResize);
  };
};
