import * as React from 'react';
import { useNavigator } from '../navigation/Navigator';
import { AppAlacarteApiAccess } from '../../lib/api/AppAlacarteApiAccess';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';

export function useStripeOnManageSubscriptionClick() {
  const { navigatorRef } = useNavigator();
  return React.useCallback(async () => {
    const navigator = navigatorRef.current;
    const profileHref = navigator.getHrefToRoute({
      route: RoutesLanding.Profile.name,
      hash: RoutesLanding.Profile.hashes.top,
      search: {},
    });

    const { redirect } = await AppAlacarteApiAccess.stripeCustomerPortalSessionCreate.request({ body: { redirectUrl: profileHref } });

    window.location.href = redirect;
  }, [navigatorRef]);
}
