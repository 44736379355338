import { useLocale } from '../../locale/useLocale';
import { useHistory } from 'react-router';
import { AbsNavigator, TNavigatorParams } from './AbsNavigator';
import {
  RoutableLanding,
  TRoutesLandingInParams,
  TRoutesLandingNames,
} from '../../../../core/src/lib/apis/routes/RoutableLanding';
import { RequireOnlyOne } from '../../../../core/src/lib/Types';
import { useSyncedDataRef } from '../../../../lib-react/src/hooks/useSyncedDataRef';
import { ELocale } from '../../../../core/src/locale/Locale';

type TNavigationParamPath<R extends TRoutesLandingNames> = RequireOnlyOne<{
  route?: R;
  path?: string;
}, 'route' | 'path'>;

export type TNavigationParam<R extends TRoutesLandingNames = any> = TNavigationParamPath<R> & {
  locale?: ELocale;
  hash?: string;
  search: TRoutesLandingInParams[R];
};

export class Navigator extends AbsNavigator {
  protected static readonly searchParamUserRefresh = 'userRefreshParamKey';

  constructor(params: TNavigatorParams) {
    super(params);
  }

  readonly location = <R extends TRoutesLandingNames>(params: TNavigationParam<R>) => {
    return this.generateLocation({
      ...params,
      search: params.route
        ? this.makeSearchParamsForRoute(params.route, params.search)
        : params.search,
    });
  };

  readonly locationHref = <R extends TRoutesLandingNames>(params: TNavigationParam<R>) => {
    const location = this.location(params);
    return location.href;
  };

  readonly getHrefToRoute = <R extends TRoutesLandingNames>(params: TNavigationParam<R>) => {
    const url = this.generateLocation({
      ...params,
      search: params.route
        ? this.makeSearchParamsForRoute(params.route, params.search)
        : params.search,
    });

    return url.href;
  };

  readonly routeReplace = <R extends TRoutesLandingNames>(params: TNavigationParam<R>) => {
    const location = this.location(params);
    this.history.replace({
      pathname: location.pathname,
      hash: location.hash,
      search: location.search,
    });
  };

  readonly routePush = <R extends TRoutesLandingNames>(params: TNavigationParam<R>) => {
    const location = this.location(params);
    this.history.push({
      pathname: location.pathname,
      hash: location.hash,
      search: location.search,
    });
  };

  private readonly makeSearchParamsForRoute = <R extends TRoutesLandingNames>(name: R, params: TRoutesLandingInParams[R]) => {
    // @ts-ignore // todo fix types
    return RoutableLanding[name].searchParams(params);
  };
}

export function useNavigator() {
  const locale = useLocale();
  const history = useHistory();

  const navigator = new Navigator({
    locale,
    history,
  });

  const navigatorRef = useSyncedDataRef(navigator);
  return {
    navigator,
    navigatorRef,
  };
}
