import * as _ from 'lodash';
import { memoizeAsync } from '../../../core/src/lib/HelperFunctions';
import { Log } from '../config/Instance';
import { ELocale } from '../locale/Locale';

declare let Tawk_API: {
  toggle: () => void;
  popup: () => void;
  showWidget: () => void;
  hideWidget: () => Promise<void>;
  maximize: () => void;
  setAttributes: (p: any, cb: (err: Error) => void) => void;
  onLoaded: boolean;
};

type TTawkToProfile = {
  meta_email: string;
  meta_name: string;
  address?: string;
  subscriptionStartedOn?: string | number;
  subscriptionType?: string | number;
  locale: ELocale;
};

class TawkToBase {
  private static isLoaded() {
    return _.get(Tawk_API, 'onLoaded', false);
  }

  private static runOnlyIfLoaded<F extends (...any: any[]) => any>(name: string, f: F): F {
    return ((...params: any[]) => {
      if (!TawkToBase.isLoaded()) {
        Log.v('TawkTo', 'runOnlyIfLoaded', `Called ${name} but Tawk_API not loaded yet, skipping`);
        return;
      }

      return f(...params);
    }) as F;
  }

  readonly waitForLoaded = memoizeAsync(() => {
    if (TawkToBase.isLoaded()) {
      return Promise.resolve(true);
    }
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (TawkToBase.isLoaded()) {
          clearInterval(interval);
          return resolve(true);
        }
      }, 200);
    });
  });

  readonly openChat = TawkToBase.runOnlyIfLoaded('openChat', () => {
    return Tawk_API.maximize();
  });

  readonly hideWidget = TawkToBase.runOnlyIfLoaded('hideWidget', () => {
    return Tawk_API.hideWidget();
  });

  readonly userIdentify = TawkToBase.runOnlyIfLoaded('userIdentify', async (params: Partial<TTawkToProfile>) => {
    Tawk_API.setAttributes(params, (error) => {
      Log.e('TawkTo', 'userIdentify', `Falied to set attributes ${error}`);
    });
  });

  readonly reset = async () => {
    return this.userIdentify({
      meta_name: '',
      meta_email: '',
      address: '',
      subscriptionStartedOn: '',
      subscriptionType: '',
    });
  };
}

export const TawkTo = new TawkToBase();
