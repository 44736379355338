import * as React from 'react';

import { Colors } from '../../config/Colors';
import styled from 'styled-components';
import { LegalDocumentFrame } from './LegalDocumentFrame';
import { ScreenFrame } from '../../components/ScreenFrame';
import { ScreenHeader } from '../../components/ScreenHeader';
import { Str } from '../../components/localized/Str';
import { Strings } from '../../../../core/src/locale/Strings';
import { LocalizedLink } from '../../components/router/LocalizedLink';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';

export const ScreenLegalTermsApp = () => (
  <LegalDocumentFrame
    id={RoutesLanding.LegalTermsApp.hashes.top}
    titleKey={Strings.keys.landingLegalTermsAppTitle}
    descriptionKey={Strings.keys.landingLegalTermsAppDescription}
    contentKey={Strings.keys.landingLegalTermsAppContent}
  />
);

export const ScreenLegalTermsLanding = () => (
  <LegalDocumentFrame
    id={RoutesLanding.LegalTermsLanding.hashes.top}
    titleKey={Strings.keys.landingLegalTermsLandingTitle}
    descriptionKey={Strings.keys.landingLegalTermsLandingDescription}
    contentKey={Strings.keys.landingLegalTermsLandingContent}
  />
);

export const ScreenLegalPrivacyPolicy = () => (
  <LegalDocumentFrame
    id={RoutesLanding.LegalPrivacyPolicy.hashes.top}
    titleKey={Strings.keys.landingLegalPrivacyTitle}
    descriptionKey={Strings.keys.landingLegalPrivacyDescription}
    contentKey={Strings.keys.landingLegalPrivacyContent}
  />
);

export const ScreenLegal = () => {
  return (
    <ScreenFrame id={RoutesLanding.Legal.hashes.top}>
      <ScreenHeader
        title={<Str k={Strings.keys.landingScreenLegalTitle}/>}
        description={<Str k={Strings.keys.landingScreenLegalDescription}/>}
      />
      <LegalList>
        <LegalLink>
          <LocalizedLink
            route={RoutesLanding.LegalTermsApp.name}
            hash={RoutesLanding.LegalTermsApp.hashes.top}
            search={{}}
            title={<Str k={Strings.keys.landingLegalTermsAppLink}/>}
          />
        </LegalLink>
        <LegalLink>
          <LocalizedLink
            route={RoutesLanding.LegalTermsLanding.name}
            hash={RoutesLanding.LegalTermsLanding.hashes.top}
            search={{}}
            title={<Str k={Strings.keys.landingLegalTermsLandingLink}/>}
          />
        </LegalLink>
        <LegalLink>
          <LocalizedLink
            route={RoutesLanding.LegalPrivacyPolicy.name}
            hash={RoutesLanding.LegalPrivacyPolicy.hashes.top}
            search={{}}
            title={<Str k={Strings.keys.landingLegalPrivacyLink}/>}
          />
        </LegalLink>
      </LegalList>
    </ScreenFrame>
  );
};

const LegalList = styled.div`
  color: ${Colors.red};
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 32px;
`;

const LegalLink = styled.div`
  color: ${Colors.accentDark};
  font-size: 16px;
  letter-spacing: 0;
  line-height: 32px;
  text-decoration: underline;
`;
