import * as React from 'react';
import styled from 'styled-components';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { SvgGlobe } from '../assets/Assets';

import { useLocale } from '../locale/useLocale';
import { Colors } from '../config/Colors';
import { useRouteMatch } from 'react-router';
import { useNavigator } from '../app/navigation/Navigator';
import { Strings as CoreStrings } from '../../../core/src/locale/Strings';
import { enumToArray } from '../../../core/src/lib/HelperFunctions';
import { ELocale } from '../../../core/src/locale/Locale';

export const Lang = () => {
  const currentLocale = useLocale();
  const routeMatch = useRouteMatch();

  const navigatorRef = useNavigator();
  const onLocaleClick = React.useCallback((locale: ELocale) => {
    const path = `${routeMatch.path}`.includes(':locale')
      ? routeMatch.path
      : `/:locale${routeMatch.path}`;
    navigatorRef.navigator.routeReplace({
      path,
      locale,
      search: {},
    });

    // Dismiss the overlay
    document.body.click();
  }, [navigatorRef, routeMatch]);

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose={true}
      overlay={(
        <Popover id="Lang">
          <PopoverBody>
            {enumToArray<ELocale>(ELocale).map((locale) => (
              <LangRoot key={locale} onClick={() => onLocaleClick(locale)}>
                <LangEmojiContainer>
                  {CoreStrings.landingLocaleEmoji()(locale)}
                </LangEmojiContainer>
                <LangNameContainer selected={currentLocale.toLowerCase() === locale.toLowerCase()}>
                  {CoreStrings.localeName()(locale)}
                </LangNameContainer>
              </LangRoot>
            ))}
          </PopoverBody>
        </Popover>
      )}
    >
      <StyledSvgGlobe src={SvgGlobe}/>
    </OverlayTrigger>
  );
};

const StyledSvgGlobe = styled.img`
  cursor: pointer;
  height: 20px;
  width: 20px;
`;

const PopoverBody = styled(Popover.Body)`
  padding: 0;
`;

const LangRoot = styled.div`
  background-color: ${Colors.white};
  color: ${Colors.accentDark};
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: row;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 15px;
  padding: 8px 24px;

  :hover {
    background-color: ${Colors.aLaCarteTheme};
  }



`;

const LangEmojiContainer = styled.div`
  margin-right: 8px;
`;

const LangNameContainer = styled.div<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? Colors.red : Colors.accentDark)};
  font-weight: ${({ selected }) => (selected ? 600 : 400)};
`;
