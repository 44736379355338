import * as React from 'react';

import { useGetLocalizedString } from '../../locale/useLocale';
import styled from 'styled-components';

type TLocalizedSource = {
  k: string | undefined;
  type: string;
  className?: string;
};

export const LocalizedSource = ({
  k,
  type,
  className,
}: TLocalizedSource) => {
  const getLocalizedString = useGetLocalizedString();
  if (!k) {
    return null;
  }

  const val = getLocalizedString(k);
  if (!val) {
    return (
      <StyledSource
        className={className}
        src={k}
      />
    );
  }

  return (
    <StyledSource
      className={className}
      src={val}
      type={type}
    />
  );
};

const StyledSource = styled.source`

`;
