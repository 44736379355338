import * as React from 'react';
import { TWizardScreenBaseProps, WizardScreenBase } from './WizardScreenBase';
import { useEnsureTawkNotExpanded } from '../useEnsureTawkNotExpanded';

type TWizardScreenProps = TWizardScreenBaseProps & {
  //
};

export const WizardScreen = (props: TWizardScreenProps) => {
  useEnsureTawkNotExpanded();
  return (
    <WizardScreenBase
      {...props}
    />
  );
};
