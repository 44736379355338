import { useForm } from 'react-hook-form';
import * as _ from 'lodash';
import * as React from 'react';

export const useFormAny = () => useForm<any, any>();

export type TAnyForm = ReturnType<typeof useFormAny>;

export type TFormFieldDefinition<Name = any, Value = any> = {
  name: Name;
  defaultValue: Value;
  validation: Record<string, any>;
};

export type TFormValueTypes<Form extends { [k: string]: TFormFieldDefinition }> = {
  [K in keyof Form]: Form[K]['defaultValue']
};

export function fieldIsRequired(definition: TFormFieldDefinition) {
  return !!_.find(definition.validation, ({ value }, key) => {
    return key === 'required' && value === true;
  });
}

export function useWatchError(form: TAnyForm, fieldName: string) {
  const [error, setError] = React.useState('');
  const _err = form.formState.errors[fieldName]?.message;
  React.useEffect(() => setError(_err ?? ''), [setError, _.isEmpty(_err)]);
  return error;
}
