import * as React from 'react';

import styled from 'styled-components';
import { isPreRendering } from '../lib/ReactSnap';
import { Link } from 'react-router-dom';
import { RoutesLanding } from '../../../core/src/lib/apis/routes/RoutableLanding';
import { AbsNavigator } from '../app/navigation/AbsNavigator';
import { fixedHeight, fixedWidth } from './css';
import { enumToArray } from '../../../core/src/lib/HelperFunctions';
import { ELocale } from '../../../core/src/locale/Locale';

// Echo all links in header so we're sure react-snap traverses them
const siteMap = enumToArray<ELocale>(ELocale).flatMap((eLocale) => {
  return Object.values(RoutesLanding).flatMap((route) => {
    return AbsNavigator.routePathnames(route.name, eLocale);
  });
});

export const SiteMap = () => {
  if (!isPreRendering()) {
    return null;
  }

  return (
    <HiddenLinks>
      {siteMap.map((route) => (
        <Link key={route} to={route}>{route}</Link>
      ))}
    </HiddenLinks>
  );
};

const HiddenLinks = styled.div`
  visibility: hidden;
  ${fixedHeight(0)};
  ${fixedWidth(0)};
`;
