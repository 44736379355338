import { TPlacesResponse } from './GooglePlacesModule';

export const GoogleAddressComponentTypes = {
  country: 'country' as const,
  administrative_area_level_1: 'administrative_area_level_1' as const,
  administrative_area_level_2: 'administrative_area_level_2' as const,
  postal_code: 'postal_code' as const,
} as const;

export type TAddressComponentType = keyof typeof GoogleAddressComponentTypes;

export function googleFindAddressComponent(
  obj: TPlacesResponse,
  type: TAddressComponentType,
) {
  return (obj.address_components ?? []).find((addressComponent) => {
    return addressComponent.types.includes(type);
  });
}
