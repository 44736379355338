import * as React from 'react';

import styled from 'styled-components';
import { Str } from '../../components/localized/Str';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';
import { AppStoreLinks } from '../../../../core/src/lib/AppStoreLinks';
import { Strings } from '../../../../core/src/locale/Strings';
import { SvgStoreApple, SvgStoreGoogle } from '../../assets/Assets';

type THomeSectionDownloadProps = {
  //
};

export const HomeSectionDownload = (props: THomeSectionDownloadProps) => {
  return (
    <section id={RoutesLanding.Home.hashes.download} className="download" data-anchorref="download">
      <div className="download__wrapper u-wrapper">
        <DownloadTitle><Str k={Strings.keys.landingDownloadTitle}/></DownloadTitle>

        <div className="download__icons">
          <a href={AppStoreLinks.cgrAppleStore()} target="_blank" rel="noopener noreferrer">
            <img src={SvgStoreApple}/>
          </a>
          <a href={AppStoreLinks.cgrAndroidStore()} target="_blank" rel="noopener noreferrer">
            <img src={SvgStoreGoogle}/>
          </a>
        </div>
      </div>
    </section>
  );
};

const DownloadTitle = styled.h3`
  color: #2B2C33;
  font-size: max(14px, (42 / 1440) * 100 * 1vw);
  font-weight: 900;
  line-height: 1;
  text-align: center;
`;
