import * as React from 'react';
import { Str } from '../../components/localized/Str';
import { ContactForm } from './ContactForm';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';
import { Strings } from '../../../../core/src/locale/Strings';

type THomeSectionContactProps = {
  //
};

export const HomeSectionContact = (props: THomeSectionContactProps) => {
  return (
    <section id={RoutesLanding.Home.hashes.contact} className="contact" data-anchorref="contact">
      <div className="contact__wrapper u-wrapper">
        <h3 className="u-title"><Str k={Strings.keys.landingContactTitle}/></h3>
        <p><Str k={Strings.keys.landingContactContent}/></p>
        <ContactForm/>
      </div>
    </section>
  );
};
