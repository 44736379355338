import * as React from 'react';
import styled from 'styled-components';
import { Header } from '../../components/header/Header';
import { Widget } from '@typeform/embed-react';
import { Footer } from '../../components/Footer';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';

function rand(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function ScreenStart() {
  return (
    <Root id={RoutesLanding.Start.hashes.top}>
      <Header/>
      <Content>
        <StyledWidget
          id="gG1bunDK"
          inlineOnMobile={true}
          hidden={{
            start: rand(1, 6),
            menu: rand(1, 3),
            email: rand(1, 3),
            share: rand(1, 3),
          }}
        />
      </Content>
      <Footer/>
    </Root>
  );
}

const Root = styled.div`
  height: 100%;
  overflow-y: hidden;
`;

const Content = styled.div`
  height: calc(100% - 140px);
  margin-top: -4px;
  overflow-y: hidden;
`;

const StyledWidget = styled(Widget)`
  height: 100%;
`;
