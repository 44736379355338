import * as React from 'react';
import { useParams } from 'react-router';
import { Strings } from '../../../core/src/locale/Strings';
import { ELang, ELocale, langToELocale } from '../../../core/src/locale/Locale';

export function useLocale(): ELocale {
  const { locale } = useParams<{ locale: ELang }>();
  return langToELocale(`${locale}`.toLowerCase() as ELang);
}

export function useGetLocalizedString() {
  const locale = useLocale();
  return React.useCallback((key: string) => {
    return (Strings[key] && Strings[key]()(locale) || key);
  }, [locale]);
}
