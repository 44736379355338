type TEvents = typeof AnalyticsEvents;

export type TEventKeys = keyof TEvents;

export type TEventData<K extends TEventKeys> = TEvents[K]['defaultData'];

export type TEvent<K extends TEventKeys> = Partial<TEventData<K>> & {
  eventId: K;
};

export const AnalyticsEvents = {
  WizardSalesman: {
    eventId: 'WizardSalesman' as const,
    eventIdPostfixKey: 'stepId' as const,
    defaultData: {
      sessionId: null as any as string,
      stepId: null as any as string,
      referralId: undefined as any as string,
      email: undefined as any as string,
      hostCoordinates: undefined as any as string,
      hostPlaceId: undefined as any as string,
      hostAddressLine1: undefined as any as string,
      hostPhone: undefined as any as string,
      hostCountry: undefined as any as string,
      hostState: undefined as any as string,
      hostPostalCode: undefined as any as string,
      hostCity: undefined as any as string,
    },
  } as const,
} as const;
