import * as React from 'react';

import ReactDOM from 'react-dom';
import './css/index.css';
import reportWebVitals from './reportWebVitals';
import { Log } from './config/Instance';
import { App } from './App';

const rootElement = document.getElementById('root');

if (rootElement?.hasChildNodes()) {
  Log.v('index.tsx', '_', 'hydrating');
  ReactDOM.hydrate(<App />, rootElement);
} else {
  Log.v('index.tsx', '_', 'rendering');
  ReactDOM.render(<App />, rootElement);
}

reportWebVitals();
