import * as _ from 'lodash';
import { enumToArray } from '../lib/HelperFunctions';

export enum ELang {
  en = 'en',
  it = 'it',
  es = 'es',
}

export enum ELocale {
  'en-US' = 'en-US',
  'it-IT' = 'it-IT',
  'es-ES' = 'es-ES',
}

const mapLocaleToLang: {
  [K in ELocale]: ELang;
} = {
  [ELocale['en-US']]: ELang.en,
  [ELocale['it-IT']]: ELang.it,
  [ELocale['es-ES']]: ELang.es,
};

export const ELOCALE_GLOBAL_DEFAULT = ELocale['it-IT'];

const mapLangToLocale = _.invert(mapLocaleToLang) as {
  [K in ELang]: ELocale;
};

export const SupportedLocales: ELocale[] = enumToArray<ELocale>(ELocale);

export function langToELocale(lang: ELang): ELocale {
  return mapLangToLocale[lang] || ELOCALE_GLOBAL_DEFAULT;
}

export function localeToLang(locale: ELocale): ELang {
  return mapLocaleToLang[locale] || mapLocaleToLang[ELOCALE_GLOBAL_DEFAULT];
}
