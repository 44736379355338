import * as React from 'react';
import { initializeHomeBindings } from '../../legacy/scripts/main';
import { useGetInfoSectionData } from './useGetInfoSectionData';

export function useInitializeHomeBindings() {
  const infoSectionData = useGetInfoSectionData();
  React.useLayoutEffect(() => {
    return initializeHomeBindings(infoSectionData);
  }, [infoSectionData]);
}
