import * as React from 'react';
import { useGetLocalizedString } from '../../locale/useLocale';
import styled from 'styled-components';
import { Colors } from '../../config/Colors';
import { Strings as CoreStrings } from '../../../../core/src/locale/Strings';

type TStringProps<K extends keyof typeof CoreStrings['keys']> = {
  k: K;
  params?: typeof CoreStrings[K];
  className?: string;
};

export function Str<K extends keyof typeof CoreStrings['keys']>({
  k,
  className,
}: TStringProps<K>) {
  const getLocalizedString = useGetLocalizedString();
  if (!k) {
    return null;
  }

  const val = getLocalizedString(k);
  if (!val) {
    return (
      <StyledSpan className={className}>{k}</StyledSpan>
    );
  }

  return (
    <StyledSpan
      className={className}
      dangerouslySetInnerHTML={{ __html: val }}
    />
  );
}

const StyledSpan = styled.span`
  a {
    color: ${Colors.red};
    text-decoration: underline;
  }
`;
