import {
  ESubscriptionFeatureFlags,
  ESubscriptionType,
} from '../../../../core/src/models/db/hostSubscription/HostSubscriptionTypes';

export const subscriptionTypeToFeatureFlag: { [K in ESubscriptionType]: ESubscriptionFeatureFlags[] } = {
  Starter: [],
  Premium: [
    ESubscriptionFeatureFlags.guestOrders,
  ],
  Gold: [
    ESubscriptionFeatureFlags.guestOrders,
  ],
};
