import { Loader } from '@googlemaps/js-api-loader';
import { Config } from '../../config/Config';
import { GoogleMapsModule } from './GoogleMapsModule';
import { TPlacesResponse } from './GooglePlacesModule';

export const mapInitCenter = {
  lat: 45.58733545683333,
  lng: 9.99564020174943,
};

export type TGoogleMapsLoadParams = {
  mapDivId: string;
  onSinglePlacePick: (obj: TPlacesResponse) => void;
  onMultipleSearchResults: (obj: TPlacesResponse[]) => void;
  center?: typeof mapInitCenter;
};

export async function googleMapsLoad({
  mapDivId,
  onSinglePlacePick,
  onMultipleSearchResults,
  center = mapInitCenter,
}: TGoogleMapsLoadParams) {
  const loader = new Loader({
    apiKey: Config.firebase.apiKey,
    version: 'weekly',
    libraries: ['places'],
  });

  const google = await loader.load();
  const mapElement = document.getElementById(mapDivId);
  if (!mapElement) {
    throw new Error(`googleMapsLoad, googleMapsLoad, element with id=${mapDivId} not found`);
  }

  const map = new google.maps.Map(mapElement, {
    center,
    zoom: 8,

    // Apply map styling
    mapId: 'b4204af8052fb223',

    // Disable default ui and apply custom ui
    disableDefaultUI: true,
  });

  map.setOptions({ zoomControl: true });

  return new GoogleMapsModule({
    loader,
    google,
    map,
    onSinglePlacePick,
    onMultipleSearchResults,
  });
}

