import * as React from 'react';
import { Analytics } from '../../lib/analytics/Analytics';
import { useLocale } from '../../locale/useLocale';
import { TawkTo } from '../../lib/TawkTo';
import { useSyncedDataRef } from '../../../../lib-react/src/hooks/useSyncedDataRef';
import { FIELD_ID, FIELD_NAME } from '../../../../core/src/db/DbDefs';
import { FIELD_HOST_EMAIL } from '../../../../core/src/models/db/host/HostTypes';
import { UserContext } from './UserContext';

export function useUserIdentificationParams() {
  const { host, hostPublicSubscription, isLoggedIn } = React.useContext(UserContext);
  const locale = useLocale();
  return useSyncedDataRef({
    host,
    hostPublicSubscription,
    locale,
    isLoggedIn,
  });
}

export function useUserAnalyticsIdentification() {
  const userIdentifierRef = useUserIdentificationParams();
  React.useEffect(() => {
    (async () => {
      const identifier = userIdentifierRef.current;
      const host = identifier.host;
      const hostPublicSubscription = identifier.hostPublicSubscription;

      if (!identifier.isLoggedIn || !host || !hostPublicSubscription) {
        await Analytics.reset();
        return;
      }

      await Analytics.userIdentify({
        hostId: host[FIELD_ID],
        email: host[FIELD_HOST_EMAIL],
        address: host.address,
        subscriptionStartedOn: hostPublicSubscription.startedOn,
        subscriptionType: hostPublicSubscription.stripeSubscriptionType,
        locale: identifier.locale,
      });
    })();
  }, [userIdentifierRef]);
}

export function useUserTawkToIdentification() {
  const userIdentifierRef = useUserIdentificationParams();
  React.useEffect(() => {
    (async () => {
      const identifier = userIdentifierRef.current;
      const host = identifier.host;
      const hostPublicSubscription = identifier.hostPublicSubscription;

      await TawkTo.waitForLoaded();
      if (!identifier.isLoggedIn || !host || !hostPublicSubscription) {
        await TawkTo.reset();
        return;
      }

      await TawkTo.userIdentify({
        meta_name: `${host[FIELD_NAME]}`,
        meta_email: host[FIELD_HOST_EMAIL],
        address: host.address,
        subscriptionStartedOn: hostPublicSubscription.startedOn,
        subscriptionType: hostPublicSubscription.stripeSubscriptionType,
        locale: identifier.locale,
      });
    })();
  }, [userIdentifierRef]);
}
