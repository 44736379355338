import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../config/Colors';
import { HeaderStyle } from './HeaderStyle';
import {
  fixedHeight,
  Row,
} from '../css';

type THeaderButtonProps = {
  title: React.ReactNode;

};

export const HeaderButtonRed = ({ title }: THeaderButtonProps) => (
  <Root>
    {title}
  </Root>
);

const Root = styled(Row)`
  ${fixedHeight(HeaderStyle.contentHeight)};
  align-items: center;
  background-color: ${Colors.red};
  color: ${Colors.white};
  font-size: 11px;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 15px;
  padding: 0 35px;
  text-align: center;
  text-transform: uppercase;
`;
