import { AppAlacarteRoutes } from '../../../core/src/lib/apis/AppAlacarteRoutes';
import { THost } from '../../../core/src/models/db/host/HostTypes';
import html2pdf from 'html2pdf.js';

export async function hostQrResultToPdf(user: THost, result: typeof AppAlacarteRoutes['hostQrCreate']['response']) {
  // @ts-ignore
  html2pdf(buildBaseHtml(user, result), {
    filename: `${user?.id}.pdf`,
    margin: [32, 32, 32, 32],
    enableLinks: false,
    image: {
      type: 'png',
      quality: 1,
    },
  });
}

function buildBaseHtml(user: THost, result: typeof AppAlacarteRoutes['hostQrCreate']['response']) {
  return `
    <!doctype html>
    <head>
      <title>à la carte | ${user?.email}</title>
    </head>
    <body>
      <div style="height: ${elementHeight * result.length}px">
        ${result.map(buildItemHtml)}
      </div>
    </body>
    </html>
  `;
}

function buildItemHtml(result: typeof AppAlacarteRoutes['hostQrCreate']['response'][number]) {
  return `
    <div style="height: ${elementHeight}px">
      <h6>${result.qrContent}</h6>
      <div style="display: flex; flex: 1; align-items: center; justify-content: center;">
        <img src="${result.qr}"/>
      </div>
    </div>
  `;
}

const elementHeight = 425;
