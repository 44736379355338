import * as React from 'react';

import { TRoutesLandingNames } from '../../../../core/src/lib/apis/routes/RoutableLanding';
import {
  TNavigationParam,
  useNavigator,
} from '../../app/navigation/Navigator';
import { Redirect } from 'react-router-dom';

type TLocalizedRedirectProps<R extends TRoutesLandingNames> = TNavigationParam<R> & {
  //
};

export function LocalizedRedirect<R extends TRoutesLandingNames>({ ...locationParams }: TLocalizedRedirectProps<R>) {
  const { navigator } = useNavigator();
  const location = navigator.location(locationParams);
  return (
    <Redirect
      to={{
        pathname: location.pathname,
        hash: location.hash,
        search: location.search,
      }}
    />
  );
}

