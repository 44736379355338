import { ValidationRule } from 'react-hook-form/dist/types/validator';
import validator from 'validator';

type TWithError = {
  errorString: string;
};

type TDefaultValidation = TWithError & {
  fieldName: string;
};

type TDefaultFieldValidator = TWithError & {
  value: number | string;
};

type TSetErrorParams = TDefaultValidation & {
  form: any;
  fieldName: string;
  errorString: string;
};

type TFieldsAreEqualParams = TDefaultValidation & {
  equalToFieldName: string;
};

export class Validators {
  private constructor() {
    //
  }

  static validateEmail(value: string) {
    return validator.isEmail(value, { domain_specific_validation: true });
  }

  static validateMinLen(value: string, min: number) {
    return validator.isLength(value, { min });
  }

  static makeMinLength(params: TDefaultFieldValidator) {
    return {
      minLength: <ValidationRule<number | string>>{
        value: params.value,
        message: params.errorString,
      },
    };
  }

  static makeMaxLength(params: TDefaultFieldValidator) {
    return {
      maxLength: <ValidationRule<number | string>>{
        value: params.value,
        message: params.errorString,
      },
    };
  }

  static makeRequired(params: TWithError) {
    return {
      required: <ValidationRule<boolean>>{
        value: true,
        message: params.errorString,
      },
    };
  }

  static setError(params: TSetErrorParams) {
    params.form.setError(
      params.fieldName,
      {
        type: 'manual',
        message: params.errorString,
      },
      { shouldFocus: true },
    );
    return false;
  }

  static fieldsAreEqual(getParams: () => TFieldsAreEqualParams) {
    return function (form, value) {
      const params = getParams();
      const equalToValue = form.getValues()[params.equalToFieldName];
      const match = equalToValue === value;

      if (match) {
        return true;
      }

      return Validators.setError({
        form,
        ...params,
      });
    };
  }

  static booleanIsTrue(getParams: () => TDefaultValidation) {
    return function (form, value) {
      const params = getParams();
      if (value) {
        return true;
      }

      return Validators.setError({
        form,
        ...params,
      });
    };
  }

  static isValidEmail(getParams: () => TDefaultValidation) {
    return function (form, value) {
      const params = getParams();
      if (Validators.validateEmail(value)) {
        return true;
      }

      return Validators.setError({
        form,
        ...params,
      });
    };
  }

  static isValidPhone(getParams: () => TDefaultValidation) {
    return function (form, value) {
      const params = getParams();
      if (validator.isMobilePhone(value)) {
        return true;
      }

      return Validators.setError({
        form,
        ...params,
      });
    };
  }
}
