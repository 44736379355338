import * as React from 'react';
import { StyledHotKeys } from '../../StyledHotKeys';
import { WizardScreen } from '../WizardScreen';
import { Str } from '../../localized/Str';
import { Strings } from '../../../../../core/src/locale/Strings';
import { WizardTextField } from '../WizardTextField';
import { useFormFocusController } from '../../../../../lib-react/src/hooks/useFormFocusController';
import { TWizardSalesmanState, useWizardSalesmanState } from './useWizardSalesmanState';
import { useWizardSalesmanErrors } from './useWizardSalesmanErrors';
import styled from 'styled-components';
import { useWizardSalesmanInfoOnContinue } from './UseWizardSalesmanInfoOnContinue';
import { addIfTrue } from '../../../../../core/src/lib/HelperFunctions';
import * as _ from 'lodash';
import { Validators } from '../../../lib/validators/Validators';
import { useWizardSalesmanAnalyticsTracker } from './useWizardSalesmanAnalyticsTracker';
import { WizardTheme } from './WizardTheme';

export const ScreenWizardSalesmanInfo = () => {
  useWizardSalesmanAnalyticsTracker();

  const {
    onGeneralFocus,
    getInputRef,
  } = useFormFocusController();

  const {
    state,
    setState,
  } = useWizardSalesmanState();

  const onContinue = useWizardSalesmanInfoOnContinue();

  const errors = useWizardSalesmanErrors({
    mapStateToErrors(st: TWizardSalesmanState) {
      return {
        ...addIfTrue('name', !_.isEmpty(st.name) && !Validators.validateMinLen(st.name ?? '', 3), 'landingFormFieldRequired'),
        ...addIfTrue('surname', !_.isEmpty(st.surname) && !Validators.validateMinLen(st.surname ?? '', 3), 'landingFormFieldRequired'),
      };
    },
  });

  return (
    <StyledHotKeys
      keyMap={{ ENTER: 'Enter' }}
      handlers={{ ENTER: onGeneralFocus }}
    >
      <WizardScreen
        theme={WizardTheme}
        title={<Str k={Strings.keys.landingWizardSalesInfoTitle}/>}
        description={<Str k={Strings.keys.landingWizardSalesInfoDescription}/>}
        globalError={errors.globalError}
        content={(
          <InfoContentRoot>
            <WizardTextField
              theme={WizardTheme}
              variant="standard"
              label={<Str k={Strings.keys.landingWizardSalesInfoNameLabel}/>}
              value={state.name || ''}
              setValue={(name) => setState({ name })}
              error={errors.errors.name}
              inputRef={getInputRef({
                tabIndex: 0,
                focusNext: true,
              })}
            />
            <WizardTextField
              theme={WizardTheme}
              variant="standard"
              label={<Str k={Strings.keys.landingWizardSalesInfoSurnameLabel}/>}
              value={state.surname || ''}
              setValue={(surname) => setState({ surname })}
              error={errors.errors.surname}
              inputRef={getInputRef({
                tabIndex: 1,
                focusNext: true,
              })}
            />
            <WizardTextField
              theme={WizardTheme}
              variant="standard"
              label={<Str k={Strings.keys.landingWizardSalesInfoPhoneLabel}/>}
              value={state.hostPhone || ''}
              setValue={(hostPhone) => setState({ hostPhone })}
              error={errors.errors.hostPhone}
              inputRef={getInputRef({
                tabIndex: 2,
                clickNext: true,
              })}
            />
          </InfoContentRoot>
        )}
        onPositive={onContinue}
        positiveText={<Str k={Strings.keys.landingWizardSalesInfoPositive}/>}
        onNegative={onContinue}
        negativeText={<Str k={Strings.keys.landingWizardSalesInfoNegative}/>}
        positiveElementRef={getInputRef({
          tabIndex: 3,
          clickThis: true,
          focusNext: true,
          clickThisOnGeneralFocus: true,
        })}
      />
    </StyledHotKeys>
  );
};

const InfoContentRoot = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  & > * {
    margin-top: 16px;
  }
`;
