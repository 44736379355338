import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../config/Colors';
import { MediaMaxWidth } from '../../components/header/HeaderStyle';

type TPlanBoxProps = {
  themeColor: string;
  themeColorNegative: string;
  title: React.ReactNode;
  description: React.ReactNode;
  priceNew: React.ReactNode;
  priceInfo: React.ReactNode;
  priceDescription: React.ReactNode;
  priceCheckoutText: React.ReactNode;
  priceBenefits: React.ReactNode[];
  onCheckoutClick: () => Promise<void>;
  highlightColor?: string;
  highlightText?: React.ReactNode;
};

export const PlanBox = ({
  themeColor,
  themeColorNegative,
  title,
  description,
  priceNew,
  priceInfo,
  priceDescription,
  priceCheckoutText,
  priceBenefits,
  onCheckoutClick,
  highlightColor = 'transparent',
  highlightText = <></>,
}: TPlanBoxProps) => {
  return (
    <SuperRoot>
      {highlightColor && (
        <StyledHighlight color={highlightColor}>
          {highlightText}
        </StyledHighlight>
      )}
      <Root>
        <StyledContent>
          <BoxTitle>
            {title}
          </BoxTitle>
          <BoxDescription>
            {description}
          </BoxDescription>
          <PriceContent>
            <BoxPriceNew>
              {priceNew}
            </BoxPriceNew>
            <BoxPriceCount>
              {priceInfo}
            </BoxPriceCount>
          </PriceContent>
          <BoxPriceDescription>
            {priceDescription}
          </BoxPriceDescription>
          <StyledBoxButton
            themeColor={themeColor}
            themeColorNegative={themeColorNegative}
            onClick={onCheckoutClick}>
            {priceCheckoutText}
          </StyledBoxButton>
        </StyledContent>
        <StyledBenefitsList>
          {priceBenefits.map((benefit, index) => (
            <StyledBenefitsListItem key={index}>
              {benefit}
            </StyledBenefitsListItem>
          ))}
        </StyledBenefitsList>
      </Root>
    </SuperRoot>
  );
};

const boxWidth = 376;
const boxHeight = 1500;
const contentHeight = 320;
const highlightHeight = 30;

type TRootProps = {
  themeColor: string;
  themeColorNegative: string;
};

const setHeight = (height: number) => `
  min-height: ${height}px;
  max-height: ${height}px;
  
  @media ${MediaMaxWidth.lg} {
    min-height: unset;
    max-height: unset;
  }
`;

const SuperRoot = styled.article`
  max-width: ${boxWidth}px;
  min-width: ${boxWidth}px;
  ${setHeight(boxHeight + highlightHeight)};
`;

const Root = styled.div`
  border: 1px solid ${Colors.lightGray1};
  display: flex;
  flex-direction: column;
  max-width: ${boxWidth}px;
  min-width: ${boxWidth}px;
  ${setHeight(boxHeight)};
  padding: 40px 30px;
  text-align: left;
`;

const StyledHighlight = styled.div<{ color: string }>`
  align-items: center;
  background-color: ${({ color }) => color};
  color: ${Colors.white};
  display: flex;
  font-size: 11px;
  font-weight: 800;
  height: ${highlightHeight}px;
  justify-content: center;
  letter-spacing: 1px;
  line-height: 15px;
  text-align: center;
  width: ${boxWidth}px;
`;

const BoxTitle = styled.div`
  color: #2B2C33;
  font-size: 24px;
  font-weight: 900;
  line-height: 44px;
  margin-bottom: 20px;
`;

const BoxDescription = styled.div`
  color: #2B2C33;
  font-size: 16px;
  line-height: 22px;
`;

const PriceContent = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  max-height: 52px;
`;

const BoxPriceNew = styled.div`
  color: #2B2C33;
  font-size: 42px;
  font-weight: 900;
  line-height: 44px;
`;

const BoxPriceCount = styled.div`
  color: #A6A7B3;
  font-size: 18px;
  line-height: 44px;
  margin-left: 8px;
`;

const BoxPriceDescription = styled.div`
  color: #2B2C33;
  font-size: 14px;
  line-height: 19px;
  margin-top: 5px;
`;

const StyledBoxButton = styled.div<TRootProps>`
  align-items: center;
  background-color: ${({ themeColor }) => themeColor};
  border: 1px solid #2B2C33;
  box-sizing: border-box;
  color: ${({ themeColorNegative }) => themeColorNegative};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 800;
  height: 56px;
  justify-content: center;
  letter-spacing: 2px;
  line-height: 19px;
  margin-top: 29.5px;
  min-height: 56px;
  text-align: center;
  width: 100%;
`;

const StyledContent = styled.div`
  height: ${contentHeight}px;
  max-height: ${contentHeight}px;
  min-height: ${contentHeight}px;
`;

const StyledBenefitsList = styled.ul`
  margin-top: 40px;
  text-align: left;
`;

const StyledBenefitsListItem = styled.li`
  color: #2B2C33;
  font-size: 16px;
  line-height: 36px;
  padding: 2px 0;
`;
