import styled from 'styled-components';

export function fixedHeight(px: number) {
  return `
    min-height: ${px}px;
    max-height: ${px}px;
    height: ${px}px;
  `;
}
export function fixedWidth(px: number) {
  return `
    min-width: ${px}px;
    max-width: ${px}px;
    width: ${px}px;
  `;
}

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CenteredRow = styled(Row)`
  align-items: center;
`;

export const FullCenteredRow = styled(CenteredRow)`
  justify-content: center;
`;

export const UTitle = styled.div`
  color: #2B2C33;
  font-size: 42px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 44px;
  text-align: center;
`;
