import * as React from 'react';
import { hostQrResultToPdf } from '../../lib/hostQrResultToPdf';
import { Colors } from '../../config/Colors';
import { parseIntOrDefault, safeDelay } from '../../../../core/src/lib/HelperFunctions';
import styled from 'styled-components';
import { User } from '../../app/user/User';
import { ESubscriptionFeatureFlags } from '../../../../core/src/models/db/hostSubscription/HostSubscriptionTypes';
import * as _ from 'lodash';
import { AppAlacarteApiAccess } from '../../lib/api/AppAlacarteApiAccess';
import { ProfileBoxSimpleAction } from './ProfileBoxSimpleAction';
import { Str } from '../../components/localized/Str';
import { Strings } from '../../../../core/src/locale/Strings';
import { UserContext } from '../../app/user/UserContext';

export const ProfileBoxGenerateQrs = () => {
  const { hostPublicSubscription } = React.useContext(UserContext);
  return (
    <>
      {User.hasAccessToFeature(hostPublicSubscription, ESubscriptionFeatureFlags.guestOrders) && (
        <ProfileBoxGenerateHostTableQrs/>
      )}
      <ProfileBoxGenerateHostQrs/>
    </>
  );
};

export const ProfileBoxGenerateHostTableQrs = () => {
  const { host } = React.useContext(UserContext);
  const [generateQrCount, setGenerateQrCount] = React.useState(`${_.size(host?.tables)}`);

  const onGenerateQrClick = React.useCallback(async () => {
    const qrParams = _.range(1, parseIntOrDefault(generateQrCount, 0) + 1).map((value) => ({
      tableNumber: `${value}`,
      skipGuestsCheck: `${false}`,
    }));

    const generatedQrs = await AppAlacarteApiAccess.hostQrCreate.request({ body: qrParams });
    await hostQrResultToPdf(host as any, generatedQrs);

    // Add delay to counter for browser processing file to download
    await safeDelay(2000);
  }, [host, generateQrCount]);

  return (
    <ProfileBoxSimpleAction
      themeColor={Colors.red}
      negativeThemeColor={Colors.white}
      contentTitle={<Str k={Strings.keys.landingProfileboxQrsHosttableTitle}/>}
      contentDescription={<Str k={Strings.keys.landingProfileboxQrsHosttableDescription}/>}
      content={(
        <Content>
          <StyledInput
            type="number"
            placeholder="00"
            min="1"
            max="200"
            value={generateQrCount}
            onChange={(e) => setGenerateQrCount(e.target.value)}
          />
        </Content>
      )}
      ctaText={<Str k={Strings.keys.landingProfileboxQrsHosttableAction}/>}
      ctaOnClick={onGenerateQrClick}
    />
  );
};

export const ProfileBoxGenerateHostQrs = () => {
  const { host } = React.useContext(UserContext);

  const [qrSrc, setQrSrc] = React.useState<string | undefined>();
  React.useEffect(() => {
    (async () => {
      try {
        const generatedQrs = await AppAlacarteApiAccess.hostQrCreate.request({
          // Create one qr with all default params
          body: [{}],
        });

        setQrSrc(generatedQrs[0].qr);
      } catch (e) {
        //
      }
    })();
  }, [setQrSrc]);

  const onGenerateQrClick = React.useCallback(async () => {
    const generatedQrs = await AppAlacarteApiAccess.hostQrCreate.request({
      // Create one qr with all default params
      body: [{}],
    });

    await hostQrResultToPdf(host as any, generatedQrs);

    // Add delay to counter for browser processing file to download
    await safeDelay(2000);
  }, [host]);

  return (
    <ProfileBoxSimpleAction
      themeColor={Colors.red}
      negativeThemeColor={Colors.white}
      contentTitle={<Str k={Strings.keys.landingProfileboxQrsHostTitle}/>}
      contentDescription={<Str k={Strings.keys.landingProfileboxQrsHostDescription}/>}
      content={(
        <HostQrContent>
          <HostQrQrImg src={qrSrc}/>
        </HostQrContent>
      )}
      ctaText={<Str k={Strings.keys.landingProfileboxQrsHostAction}/>}
      ctaOnClick={onGenerateQrClick}
    />
  );
};

const Content = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`;

const StyledInput = styled.input`
  border-bottom: 1px solid ${Colors.accentDark};
  color: ${Colors.accentDark};
  font-size: 42px;
  font-weight: 900;
  height: 71px;
  letter-spacing: 0;
  line-height: 40px;
  text-align: center;

  width: 120px;

  ::placeholder {
    color: ${Colors.accentLight}
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [type=number] {
    -moz-appearance: textfield;
  }
`;

const HostQrContent = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
`;

const HostQrQrImg = styled.img`
  margin-bottom: 8px;
  max-width: 132px;
`;
