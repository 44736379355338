import { PackageSecrets } from './PackageSecrets';
import { Process } from '../../../core/src/config/Process';
import { ELOCALE_GLOBAL_DEFAULT } from '../../../core/src/locale/Locale';

export const Config = {
  appName: '@alacarte/alacartemenu.it',
  defaultLocale: ELOCALE_GLOBAL_DEFAULT,
  env: Process.env.NODE_ENV,
  LANDING_MIXPANEL_API_KEY: PackageSecrets.val.LANDING_MIXPANEL_API_KEY || '',
  LANDING_POSTHOG_API_KEY: PackageSecrets.val.LANDING_POSTHOG_API_KEY || '',
  firebase: {
    apiKey: PackageSecrets.val.FIREBASE_API_KEY || '',
    authDomain: PackageSecrets.val.FIREBASE_AUTH_DOMAIN || '',
    databaseURL: PackageSecrets.val.FIREBASE_DB_URL || '',
    projectId: PackageSecrets.val.FIREBASE_PROJECT_ID || '',
    messagingSenderId: PackageSecrets.val.FIREBASE_MESSAGING_SENDER_ID || '',
    appId: PackageSecrets.val.FIREBASE_APP_ID || '',
    measurementId: PackageSecrets.val.FIREBASE_MEASUREMENT_ID || '',
  },

  STRIPE_PRICE_STARTER_PLAN_ID: PackageSecrets.val.STRIPE_PRICE_STARTER_PLAN_ID || '',
  STRIPE_PRICE_PREMIUM_PLAN_ID: PackageSecrets.val.STRIPE_PRICE_PREMIUM_PLAN_ID || '',
  STRIPE_PRICE_GOLD_PLAN_ID: PackageSecrets.val.STRIPE_PRICE_GOLD_PLAN_ID || '',

  STRIPE_COUPON_WIZARDSALESMAN_ID: (PackageSecrets.val.STRIPE_COUPON_WIZARDSALESMAN_ID || ''),
};

