import * as React from 'react';
import { Str } from '../../components/localized/Str';
import { Strings } from '../../../../core/src/locale/Strings';

type THomeSectionAdvantagesProps = {
  //
};

export const HomeSectionAdvantages = (props: THomeSectionAdvantagesProps) => {
  return (
    <section className="advantages">
      <div className="advantages__wrapper u-wrapper">
        <h3 className="u-title"><Str k={Strings.keys.landingAdvantagesTitle}/></h3>
        <ul className="advantages__blocks">
          <li>
            <h4><Str k={Strings.keys.landingAdvantagesBlocks0Title}/></h4>
            <p><Str k={Strings.keys.landingAdvantagesBlocks0Content}/></p>
          </li>
          <li>
            <h4><Str k={Strings.keys.landingAdvantagesBlocks1Title}/></h4>
            <p><Str k={Strings.keys.landingAdvantagesBlocks1Content}/></p>
          </li>
          <li>
            <h4><Str k={Strings.keys.landingAdvantagesBlocks2Title}/></h4>
            <p><Str k={Strings.keys.landingAdvantagesBlocks2Content}/></p>
          </li>
          <li>
            <h4><Str k={Strings.keys.landingAdvantagesBlocks3Title}/></h4>
            <p><Str k={Strings.keys.landingAdvantagesBlocks3Content}/></p>
          </li>
        </ul>
      </div>
    </section>
  );
};
