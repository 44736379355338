import * as React from 'react';
import styled from 'styled-components';
import {
  fixedHeight,
  fixedWidth,
} from '../../components/css';
import { ProfileBoxHeader } from '../../components/ProfileBoxHeader';

export type TProfileBoxProps = {
  contentTitle: React.ReactNode;
  contentDescription?: React.ReactNode;
  action: React.ReactNode;
  themeColor: string;
  content?: React.ReactNode;
};

export const ProfileBox = ({
  contentTitle,
  contentDescription,
  themeColor,
  action,
  content,
}: TProfileBoxProps) => {
  return (
    <Root themeColor={themeColor}>
      <SubRoot themeColor={themeColor}>
        <Content>
          <ProfileBoxHeader
            title={contentTitle}
            description={contentDescription}
          />
          {content}
        </Content>
        {action}
      </SubRoot>
    </Root>
  );
};

const Root = styled.div<{ themeColor: string }>`
  margin: 20px auto;
`;

const SubRoot = styled.div<{ themeColor: string }>`
  border: 8px solid ${({ themeColor }) => themeColor};
  ${fixedWidth(340)};
  ${fixedHeight(385)};
  color: #2B2C33;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 800;
  justify-content: space-between;
  letter-spacing: 1px;
  line-height: 19px;
  margin: 0 20px;
  padding: 30px;
  text-align: center;
`;

const Content = styled.div`
  ${fixedHeight(254)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
