import * as React from 'react';
import styled from 'styled-components';
import { fixedHeight, fixedWidth, FullCenteredRow } from '../css';
import { TWithWindowDimensionsProps, withWindowDimensions } from '../useWindowDimensions';
import { LoaderClipWhite20 } from '../Spinners';
import { DivLinkItem } from '../DivLinkItem';

export type TWizardTheme = {
  colorPrimary: string;
  colorSecondary: string;
  colorBackground: string;
  colorAccent: string;
};

export type TWithWizardTheme = {
  theme: TWizardTheme;
};

export type TWizardScreenBaseProps = TWithWizardTheme & {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  description?: React.ReactNode;
  content?: React.ReactNode;
  premise?: React.ReactNode;
  onNegative?: () => (Promise<any> | void);
  negativeText?: React.ReactNode;
  onPositive?: () => (Promise<any> | void);
  positiveText?: React.ReactNode;
  positiveElementRef?: any;
  globalError?: string | undefined;
};

export const WizardScreenBase = ({
  theme,
  title,
  subtitle,
  description,
  content,
  premise,
  onNegative,
  negativeText,
  onPositive,
  positiveText,
  positiveElementRef,
  globalError,
}: TWizardScreenBaseProps) => {
  return (
    <FullScreen theme={theme}>
      <Root theme={theme}>
        <FixedHeight>
          {title && (
            <Title theme={theme}>
              {title}
            </Title>
          )}
        </FixedHeight>
        <FixedHeight>
          {subtitle && (
            <SubTitle theme={theme}>
              {subtitle}
            </SubTitle>
          )}
        </FixedHeight>
        <FixedHeightAlignedBottom>
          {description && (
            <Description theme={theme}>
              {description}
            </Description>
          )}
        </FixedHeightAlignedBottom>
        <ContentFlex>
          {content}
        </ContentFlex>
        <GlobalError>
          {globalError}
        </GlobalError>
        <FixedHeightAlignedBottom>
          {premise && (
            <Premise theme={theme}>
              {premise}
            </Premise>
          )}
        </FixedHeightAlignedBottom>
        <FixedHeightAlignedBottom>
          <ActionsFlex>
            <DivLinkItem
              title={negativeText}
              onClick={onNegative}
              LoadingComponent={LoaderClipWhite20}
              wrap={({ children }) => (
                <ButtonNegative
                  visible={!!negativeText}
                  disabled={!onNegative}
                  theme={theme}
                >
                  {children}
                </ButtonNegative>
              )}
            />
            <DivLinkItem
              title={positiveText}
              onClick={onPositive}
              LoadingComponent={LoaderClipWhite20}
              submitElementRef={positiveElementRef}
              wrap={({ children }) => (
                <ButtonPositive
                  visible={!!positiveText}
                  disabled={!onPositive}
                  theme={theme}
                >
                  {children}
                </ButtonPositive>
              )}
            />
          </ActionsFlex>
        </FixedHeightAlignedBottom>
      </Root>
    </FullScreen>
  );
};

const FullScreen = styled.div<TWithWizardTheme>`
  background-color: ${({ theme }) => theme.colorBackground};
  height: 100vh;
`;

const textSizer = 0.9;

const FixedHeight = styled(FullCenteredRow)`
  margin: 6px 0;
`;

const FixedHeightAlignedBottom = styled(FixedHeight)`
  align-items: flex-end;
  display: flex;
`;

const Root = withWindowDimensions(styled.div<TWithWindowDimensionsProps<TWithWizardTheme>>`
  background-color: ${({ theme }) => theme.colorBackground};
  color: ${({ theme }) => theme.colorPrimary};
  display: flex;
  flex-direction: column;
  height: ${({ dimensions }) => dimensions.height}px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 10% 5% 5% 5%;
  width: 100vw;
`);

const Title = styled.div<TWithWizardTheme>`
  font-size: ${2.1 * textSizer}rem;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 44px;
  text-align: center;
`;

const SubTitle = styled.div<TWithWizardTheme>`
  font-size: ${1.8 * textSizer}rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 44px;
  text-align: center;
`;

const Description = styled.div<TWithWizardTheme>`
  color: ${({ theme }) => theme.colorSecondary};
  font-size: ${1.3 * textSizer}rem;
  letter-spacing: 0;
  line-height: 22px;
  max-width: 670px;
  text-align: center;
`;

const GlobalError = styled.div<TWithWizardTheme>`
  color: ${({ theme }) => theme.colorAccent};
  font-size: ${1.3 * textSizer}rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  text-align: right;
`;

const ContentFlex = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

const Premise = styled.div<TWithWizardTheme>`
  color: ${({ theme }) => theme.colorSecondary};
  font-size: 0.7rem;
  margin-top: 12px;
`;

const ActionsFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

type TButtonProps = TWithWizardTheme & {
  visible: boolean;
  disabled: boolean;
};

const Button = styled.div<TButtonProps>`
  ${fixedHeight(40)};
  ${fixedWidth(130)};
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 0.7rem;
  font-weight: 800;
  justify-content: center;
  letter-spacing: 1px;
  line-height: 15px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  text-align: center;
  text-transform: uppercase;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

const ButtonPositive = styled(Button)<TButtonProps>`
  background-color: ${({ theme }) => theme.colorAccent};
  color: ${({ theme }) => theme.colorPrimary};
`;

const ButtonNegative = styled(Button)<TButtonProps>`
  background-color: transparent;
  color: ${({ theme }) => theme.colorPrimary};
  justify-content: flex-start;
  margin-left: 28px;
`;
