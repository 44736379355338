import * as React from 'react';
import * as _ from 'lodash';
import { NoUndefinedField } from '../../../core/src/lib/Types';
import { useEnterKeyDown } from './useKeyDownListener';

type TElOptions = {
  tabIndex: number;
  clickThis?: boolean;
  focusNext?: boolean;
  clickNext?: boolean;
  clickThisOnGeneralFocus?: boolean;
};

export function useFormFocusController() {
  const elementRefOptions = [] as NoUndefinedField<TElOptions>[];
  const elementRefs = [] as React.MutableRefObject<any>[];

  function onKeyDownForElement(el: HTMLElement, event: any) {
    const currentIndex = elementRefs.findIndex(({ current }) => el === current);

    const elementRefOptionsCurrent = elementRefOptions[currentIndex];
    const elementRefCurrent = elementRefs[currentIndex];

    if (elementRefOptionsCurrent && elementRefOptionsCurrent.clickThis) {
      elementRefCurrent && elementRefCurrent.current && elementRefCurrent.current.click();
    }

    const _nextIndex = currentIndex + 1;
    const nextIndex = _nextIndex % elementRefs.length;
    const elementRefNext = elementRefs[nextIndex];
    if (elementRefOptionsCurrent && elementRefOptionsCurrent.focusNext) {
      elementRefNext && elementRefNext.current && elementRefNext.current.focus();
    }

    if (elementRefOptionsCurrent && elementRefOptionsCurrent.clickNext) {
      elementRefNext && elementRefNext.current && elementRefNext.current.click();
    }
  }

  React.useEffect(() => {
    elementRefs.forEach((elementRef) => {
      const el: HTMLElement | undefined = elementRef.current;
      if (el == null) {
        return;
      }

      el.onkeydown = function (event: any) {
        if (_.get(event, 'key') === 'Enter') {
          event && event.preventDefault();
          event && event.stopPropagation();
          onKeyDownForElement(el, event);
        }
      };
    });
  });

  function getInputRef(options: TElOptions) {
    const tabIndex = options.tabIndex;

    elementRefOptions[tabIndex] = {
      clickThis: false,
      focusNext: true,
      clickNext: false,
      clickThisOnGeneralFocus: false,
      ...options,
    };

    elementRefs[tabIndex] = elementRefs[tabIndex]
      ? elementRefs[tabIndex]
      : { current: null };

    return elementRefs[tabIndex];
  }

  const onGeneralFocus = useEnterKeyDown((event: any) => {
    elementRefOptions.forEach((options, index) => {
      if (options.clickThisOnGeneralFocus) {
        const elem = elementRefs[index];
        elem && elem.current && elem.current.click();
      }
    });
  });

  return {
    getInputRef,
    onGeneralFocus,
  };
}
