import { gsap } from 'gsap';
import {
  isTouchDevice,
  loadPicture,
} from '../utils';
import { Elements } from '../../Elements';

export default class Info {
  data = {};

  constructor(data) {
    this.data = data;
    this.ctx = Elements.info;
    this.accordionsContainer = Elements.infoAccordions;

    this.inTransition = false;
    this.currentList = 'restaurant';
    this.timer = null;
    this.isTouchDevice = isTouchDevice();

    this.restaurant = {
      button: this.ctx.querySelector('[data-ref="restaurant"]'),
      cont: this.ctx.querySelector('[data-list="restaurant"]'),
      frame: this.ctx.querySelector('.info__column--picture [data-frame="restaurant"]'),
      framePicture: this.ctx.querySelector('.info__column--picture [data-frame="restaurant"] img[data-frame]'),
      picture: this.ctx.querySelector('.info__column--picture [data-frame="restaurant"] img[data-picture]'),
      list: [...this.ctx.querySelectorAll('[data-list="restaurant"] .accordion')],
      currentIndex: 0,
    };
    this.customer = {
      button: this.ctx.querySelector('[data-ref="customer"]'),
      cont: this.ctx.querySelector('[data-list="customer"]'),
      frame: this.ctx.querySelector('.info__column--picture [data-frame="customer"]'),
      framePicture: this.ctx.querySelector('.info__column--picture [data-frame="customer"] img[data-frame]'),
      picture: this.ctx.querySelector('.info__column--picture [data-frame="customer"] img[data-picture]'),
      list: [...this.ctx.querySelectorAll('[data-list="customer"] .accordion')],
      currentIndex: 0,
    };
  }

  init = () => {
    gsap.set(this.customer.list, {
      y: -45,
      autoAlpha: 0,
    });
    gsap.set(this.customer.frame, {
      autoAlpha: 0,
      y: -80,
    });
    gsap.set([...this.ctx.querySelectorAll('hr')], {
      scaleX: 0,
    });

    this.addEvents();

    this.addTimer();
    if (!this.isTouchDevice) this.timer.pause();
    this.addIntersectionObserver();

    this.loadPictures();
  };

  addIntersectionObserver = () => {
    const options = {
      root: Elements.app,
      rootMargin: '0px',
      threshold: 0.1,
    };
    const callback = (entry) => {
      // if (entry[0].isIntersecting) this.timer.play();
      // else this.timer.pause();
    };
    this.observer = new IntersectionObserver(callback, options);
    this.observer.observe(this.ctx);
  };

  addEvents() {
    this.restaurant.button.addEventListener('click', () => this.changeList('restaurant'));
    this.customer.button.addEventListener('click', () => this.changeList('customer'));

    [...this.restaurant.list, ...this.customer.list].forEach((accordion) => {
      const head = accordion.querySelector('.accordion__head');

      head.addEventListener('click', (event) => {
        const active = document.querySelector('.accordion--active');
        const container = accordion.querySelector('.accordion__content');
        const { height } = accordion.querySelector('.accordion__wrapper').getBoundingClientRect();

        if (active) {
          const activeCont = active.querySelector('.accordion__content');
          active.classList.remove('accordion--active');
          activeCont.style.maxHeight = '0px';
        }

        if (!active || !active.querySelector('.accordion__head').isSameNode(event.target)) {
          accordion.classList.add('accordion--active');
          container.style.maxHeight = `${height}px`;
        }

        if (this.timer && !this.isTouchDevice) this.resetTimer();
      });
    });

    if (!this.isTouchDevice) {
      this.accordionsContainer.addEventListener('mouseenter', () => {
        if (this.timer) this.timer.pause();
      });
      this.accordionsContainer.addEventListener('mouseleave', () => {
        if (this.timer) this.timer.play();
      });
    }
  }

  loadPictures = () => {
    const listItems = [...this.data.info.restaurant.list, ...this.data.info.customer.list];
    const promisesArray = [];

    listItems.forEach((item) => promisesArray.push(loadPicture(item.dsk.src)));

    Promise.all(promisesArray);
  };

  resize() {
    const accordion = document.querySelector('.accordion--active');

    if (accordion) {
      const container = accordion.querySelector('.accordion__content');
      const { height } = accordion.querySelector('.accordion__wrapper').getBoundingClientRect();
      container.style.maxHeight = `${height}px`;
    }
  }

  getTimerIndicator() {
    const { currentIndex } = this[this.currentList];

    return this[this.currentList].list[currentIndex].querySelector('hr');
  }

  addTimer() {
    if (this.isTouchDevice) return;

    const newIndex = (this[this.currentList].currentIndex + 1) % this[this.currentList].list.length;
    const timerIndicator = this.getTimerIndicator();

    this.switchDskImage();

    this.timer = gsap.to(timerIndicator, {
      scaleX: 1,
      duration: 10,
      ease: 'power1.inOut',
      onComplete: () => {
        gsap.to(timerIndicator, {
          scaleX: 0,
          duration: 0.8,
          ease: 'old',
        });

        this.timer = null;
        this[this.currentList].currentIndex = newIndex;
        this[this.currentList].list[newIndex].querySelector('.accordion__head').click();

        this.addTimer();
      },
    });
  }

  resetTimer() {
    if (this.isTouchDevice) return;

    const timerIndicator = this.getTimerIndicator();
    const newIndex = this[this.currentList].list.findIndex((item) => item.classList.contains('accordion--active'));
    if (newIndex > -1) this[this.currentList].currentIndex = newIndex;

    this.timer.kill();
    gsap.to(timerIndicator, {
      scaleX: 0,
      duration: 0.8,
      ease: 'old',
    });
    this.addTimer();
    this.timer.pause();
  }

  changeList(enter) {
    if (this.inTransition || enter === this.currentList) return;
    this.inTransition = true;

    if (this.timer && !this.isTouchDevice) {
      const timerIndicator = this.getTimerIndicator();
      this[this.currentList].currentIndex = 0;

      gsap.to(timerIndicator, {
        scaleX: 0,
        duration: 0.8,
        ease: 'old',
      });
      this.timer.kill();
      this.timer = null;

      const currentActive = this[this.currentList].list.find((item) => item.classList.contains('accordion--active'));
      if (currentActive) currentActive.querySelector('.accordion__head').click();
      this[enter].list[0].querySelector('.accordion__head').click();
    }

    this.currentList = enter;

    this.dsk = window.innerWidth >= 768;

    const current = this[enter];
    const old = this[enter === 'customer' ? 'restaurant' : 'customer'];
    const tl = gsap.timeline({
      onComplete: () => {
        this.inTransition = false;
        this.addTimer();
        current.cont.focus();
      },
    });

    old.cont.classList.remove('info__accordions-wrapper--active');
    current.cont.classList.add('info__accordions-wrapper--active');

    old.button.classList.remove('filter-button--active');
    current.button.classList.add('filter-button--active');

    tl.addLabel('start')
      .to(old.list, {
        y: 45,
        autoAlpha: 0,
        ease: 'old',
        duration: 0.5,
        stagger: 0.1,
      }, 'start')
      .to(old.picture, {
        autoAlpha: 0,
        ease: 'old',
        duration: 0.5,
      }, 'start')
      .fromTo(current.list, {
        y: -45,
        autoAlpha: 0,
      }, {
        y: 0,
        autoAlpha: 1,
        ease: 'old',
        duration: 0.5,
        stagger: 0.1,
      }, 'start+=1')
      .to(old.frame, {
        y: 80,
        autoAlpha: 0,
        ease: 'old',
        duration: 0.5,
        stagger: 0.1,
      }, 'start')
      .fromTo(current.frame, {
        y: -80,
        autoAlpha: 0,
      }, {
        y: 0,
        autoAlpha: 1,
        ease: 'old',
        duration: 0.5,
        stagger: 0.1,
      }, 'start+=1');
  }

  switchDskImage() {
    const { currentIndex } = this[this.currentList];
    const { list } = this.data.info[this.currentList];
    const newImage = document.createElement('img');
    const url = list[currentIndex].dsk.src;

    newImage.setAttribute('data-picture', '');
    newImage.setAttribute('src', url);

    gsap.set(newImage, {
      autoAlpha: 0,
    });

    this[this.currentList].frame.insertBefore(newImage, this[this.currentList].framePicture);

    gsap.to(newImage, {
      autoAlpha: 1,
      duration: 0.75,
      ease: 'old',
      onComplete: () => {
        this[this.currentList].picture?.remove();
        this[this.currentList].picture = newImage;
      },
    });
  }
}
