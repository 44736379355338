import * as React from 'react';
import styled from 'styled-components';
import { QuestionLinkLogin } from '../../components/QuestionLink';
import { useFormSignup } from '../../components/formSignup/useFormSignup';
import { FormCheckbox } from '../../components/form/FormCheckbox';
import { PlacesPicker } from '../../components/placesPicker/PlacesPicker';
import { Strings } from '../../../../core/src/locale/Strings';
import {
  fixedHeight,
  FullCenteredRow,
} from '../../components/css';
import { FormRow } from '../../components/form/FormRow';
import { Colors } from '../../config/Colors';
import { LoaderPulseWhite } from '../../components/Spinners';
import { FormDefSignup } from '../../components/formSignup/FormDefSignup';
import { useFormFocusController } from '../../../../lib-react/src/hooks/useFormFocusController';
import { StyledHotKeys } from '../../components/StyledHotKeys';
import { FormGlobalError } from '../../components/form/FormGlobalError';
import { SignupFormSection } from './SignupFormSection';
import { FormTextField } from '../../components/form/FormTextField';
import { Str } from '../../components/localized/Str';
import { DivLinkItem } from '../../components/DivLinkItem';

export const SignupForm = () => {
  const {
    form,
    onSubmit,
    onPlacePick,
  } = useFormSignup();

  const {
    onGeneralFocus,
    getInputRef,
  } = useFormFocusController();

  return (
    <StyledHotKeys
      keyMap={{ ENTER: 'Enter' }}
      handlers={{ ENTER: onGeneralFocus }}
    >
      <Root className="form u-wrapper">
        <StyledQuestionLinkSignIn/>
        <FormContent>
          <FormGlobalError
            form={form}
            fieldName={FormDefSignup.meta_globalError.name}
          />
          <SignupFormSection title={<Str k={Strings.keys.landingSignupBasicinfoTitle}/>}>
            <FormRow>
              <FormTextField
                siblingCount={2}
                form={form}
                definition={FormDefSignup.name}
                label={<Str k={Strings.keys.landingSignupNameLabel}/>}
                helperText={<Str k={Strings.keys.landingSignupNameHelper}/>}
                inputRef={getInputRef({ tabIndex: 0 })}
              />
              <FormTextField
                siblingCount={2}
                form={form}
                definition={FormDefSignup.surname}
                label={<Str k={Strings.keys.landingSignupSurnameLabel}/>}
                helperText={<Str k={Strings.keys.landingSignupSurnameHelper}/>}
                inputRef={getInputRef({ tabIndex: 1 })}
              />
            </FormRow>
          </SignupFormSection>

          <SignupFormSection title={<Str k={Strings.keys.landingSignupAccessinfoTitle}/>}>
            <FormRow>
              <FormTextField
                siblingCount={2}
                form={form}
                type="email"
                definition={FormDefSignup.email}
                label={<Str k={Strings.keys.landingSignupEmailLabel}/>}
                helperText={<Str k={Strings.keys.landingSignupEmailHelper}/>}
                inputRef={getInputRef({ tabIndex: 2 })}
              />
              <FormTextField
                siblingCount={2}
                form={form}
                type="email"
                definition={FormDefSignup.meta_confirmEmail}
                label={<Str k={Strings.keys.landingSignupConfirmemailLabel}/>}
                helperText={<Str k={Strings.keys.landingSignupConfirmemailHelper}/>}
                inputRef={getInputRef({ tabIndex: 3 })}
              />
            </FormRow>
            <FormRow>
              <FormTextField
                siblingCount={2}
                form={form}
                type="password"
                definition={FormDefSignup.password}
                label={<Str k={Strings.keys.landingSignupPasswordLabel}/>}
                helperText={<Str k={Strings.keys.landingSignupPasswordHelper}/>}
                inputRef={getInputRef({ tabIndex: 4 })}
              />
              <FormTextField
                siblingCount={2}
                form={form}
                type="password"
                definition={FormDefSignup.meta_confirmPassword}
                label={<Str k={Strings.keys.landingSignupConfirmpasswordLabel}/>}
                helperText={<Str k={Strings.keys.landingSignupConfirmpasswordHelper}/>}
                inputRef={getInputRef({ tabIndex: 5 })}
              />
            </FormRow>
          </SignupFormSection>

          <SignupFormSection title={<Str k={Strings.keys.landingSignupVenueinfoTitle}/>}>
            <FormRow>
              <FormTextField
                siblingCount={2}
                form={form}
                definition={FormDefSignup.hostName}
                label={<Str k={Strings.keys.landingSignupHostnameLabel}/>}
                helperText={<Str k={Strings.keys.landingSignupHostnameHelper}/>}
                inputRef={getInputRef({ tabIndex: 6 })}
              />
              <FormTextField
                siblingCount={2}
                form={form}
                definition={FormDefSignup.hostDescription}
                label={<Str k={Strings.keys.landingSignupHostdescriptionLabel}/>}
                helperText={<Str k={Strings.keys.landingSignupHostdescriptionHelper}/>}
                inputRef={getInputRef({ tabIndex: 7 })}
              />
            </FormRow>
            <FormRow>
              <FormTextField
                siblingCount={2}
                form={form}
                definition={FormDefSignup.hostVatId}
                label={<Str k={Strings.keys.landingSignupHostvatidLabel}/>}
                helperText={<Str k={Strings.keys.landingSignupHostvatidHelper}/>}
                inputRef={getInputRef({ tabIndex: 8 })}
              />
              <FormTextField
                siblingCount={2}
                form={form}
                definition={FormDefSignup.hostDenomination}
                label={<Str k={Strings.keys.landingSignupHostdenominationLabel}/>}
                helperText={<Str k={Strings.keys.landingSignupHostdenominationHelper}/>}
                inputRef={getInputRef({ tabIndex: 9 })}
              />
            </FormRow>
          </SignupFormSection>

          <SignupFormSection title={<Str k={Strings.keys.landingSignupVenuelocationTitle}/>}>
            <FormRow>
              <PlacesPicker
                onSinglePlacePick={onPlacePick}
                inputRef={getInputRef({ tabIndex: 10 })}
              />
            </FormRow>
            <FormRow/>
            <FormRow>
              <FormTextField
                siblingCount={2}
                form={form}
                definition={FormDefSignup.hostAddressLine1}
                label={<Str k={Strings.keys.landingSignupVenueaddressLabel}/>}
                helperText={<Str k={Strings.keys.landingSignupVenueaddressHelper}/>}
                inputRef={getInputRef({ tabIndex: 11 })}
              />
              <FormTextField
                siblingCount={2}
                form={form}
                definition={FormDefSignup.hostPhone}
                label={<Str k={Strings.keys.landingSignupVenuephoneLabel}/>}
                helperText={<Str k={Strings.keys.landingSignupVenuephoneHelper}/>}
                inputRef={getInputRef({ tabIndex: 12 })}
              />
            </FormRow>
          </SignupFormSection>
        </FormContent>

        <SignupSectionSubmit>
          <SignupCheckboxes>
            <FormCheckbox
              form={form}
              definition={FormDefSignup.newsletterAccepted}
              label={<Str k={Strings.keys.landingSignupAgreenewsletterLabel}/>}
              inputRef={getInputRef({
                tabIndex: 13,
                clickThis: true,
                focusNext: true,
              })}
            />
            <FormCheckbox
              form={form}
              definition={FormDefSignup.meta_acceptConditions}
              label={<Str k={Strings.keys.landingFormFieldTacLabel}/>}
              inputRef={getInputRef({
                tabIndex: 14,
                clickThis: true,
                focusNext: true,
              })}
            />
          </SignupCheckboxes>
          <DivLinkItem
            onClick={onSubmit}
            LoadingComponent={LoaderPulseWhite}
            title={<Str k={Strings.keys.landingSignupSubmitLabel}/>}
            tabIndex={15}
            submitElementRef={getInputRef({
              tabIndex: 15,
              clickThis: true,
              focusNext: true,
              clickThisOnGeneralFocus: true,
            })}
            wrap={({ children }) => (
              <RegisterButtonRed>
                {children}
              </RegisterButtonRed>
            )}
          />
          <StyledQuestionLinkSignIn/>
        </SignupSectionSubmit>
      </Root>
    </StyledHotKeys>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormContent = styled.div`
  margin-top: 20px;
`;

const SignupSectionSubmit = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  & > * {
    margin-top: 20px;
  }
`;

const SignupCheckboxes = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledQuestionLinkSignIn = styled(QuestionLinkLogin)`
  margin-top: 20px;
`;

export const RegisterButtonRed = styled(FullCenteredRow)`
  ${fixedHeight(55)};
  background-color: ${Colors.red};
  color: ${Colors.white};
  flex: 1;
  font-size: 11px;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 15px;
  padding: 0 35px;
  text-align: center;
  text-transform: uppercase;
`;
