import { createTheme } from '@mui/material';
import { Colors } from './config/Colors';

export const defaultMuiTheme = createTheme({
  palette: {
    primary: { main: Colors.accentDark },
    secondary: { main: Colors.red },
    text: { primary: Colors.accentDark },
  },
});
