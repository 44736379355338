import * as React from 'react';
import styled from 'styled-components';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';
import { ScreenFrame } from '../../components/ScreenFrame';
import { SignupForm } from './SignupForm';

export const ScreenSignup = () => {
  return (
    <ScreenFrame id={RoutesLanding.Signup.hashes.top}>
      <ContentRoot>
        <Content>
          <SignupForm/>
        </Content>
      </ContentRoot>
    </ScreenFrame>
  );
};

const ContentRoot = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
`;
