import * as React from 'react';
import { User } from '../user/User';
import { useNavigator } from './Navigator';
import { Log } from '../../config/Instance';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';
import { UserContext } from '../user/UserContext';

export function useNavigateOnLoginSuccess() {
  const { isLoggedIn, hostPublicSubscription } = React.useContext(UserContext);
  const { navigatorRef } = useNavigator();

  return React.useCallback(() => {
    if (!isLoggedIn) {
      /* todo isLoggedInFalse even after authSignInWithEmailAndPassword Success indicates a concurrency issue
       * @alacarte/core Firebase@authSignInWithEmailAndPassword Success
       * @alacarte/alacartemenu.it useNavigateOnLoginSuccess@useNavigateOnLoginSuccess isLoggedIn false, exiting
       */
      Log.v('useNavigateOnLoginSuccess', 'useNavigateOnLoginSuccess', 'isLoggedIn false, exiting');
      return;
    }

    if (User.hasActiveSubscription(hostPublicSubscription)) {
      Log.v('useNavigateOnLoginSuccess', 'useNavigateOnLoginSuccess', 'User has subscription, going to profile');
      navigatorRef.current.routeReplace({
        route: RoutesLanding.Profile.name,
        hash: RoutesLanding.Profile.hashes.top,
        search: {},
      });
      return;
    }

    Log.v('useNavigateOnLoginSuccess', 'useNavigateOnLoginSuccess', 'User doesn\'t have subscription, going to plans');
    navigatorRef.current.routeReplace({
      route: RoutesLanding.Plans.name,
      hash: RoutesLanding.Plans.hashes.top,
      search: {},
    });
  }, [navigatorRef, isLoggedIn, hostPublicSubscription]);
}
