import * as React from 'react';
import styled from 'styled-components';
import { fixedHeight } from '../../components/css';
import { parseBoolOrUndefined } from '../../../../core/src/lib/HelperFunctions';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';
import { Colors } from '../../config/Colors';
import { ScreenFrame } from '../../components/ScreenFrame';
import { ScreenHeader } from '../../components/ScreenHeader';
import { Str } from '../../components/localized/Str';
import { useURLState } from '../../../../lib-react/src/contexts-browser/useURLState';

export const ScreenSuccess = () => {
  const urlState = useURLState(RoutesLanding.Success);
  const {
    showHeader,
    title,
    description,
    imageSrc,
    ctaPositive,
    ctaPositiveHref,
    ctaNegative,
    ctaNegativeHref,
  } = urlState.deconstructed.searchParams;
  return (
    <ScreenFrame
      id={RoutesLanding.Success.hashes.top}
      showHeader={parseBoolOrUndefined(showHeader)}>
      <ScreenHeader
        title={<Str k={title}/>}
        description={<Str k={description}/>}
      />
      <ContentRoot>
        <Content>
          <ImageCont>
            {imageSrc && (
              <Image src={`/${imageSrc}`}/>
            )}
          </ImageCont>
          <CtaCont>
            {ctaPositive && (
              <CtaPositive href={ctaPositiveHref}>
                <Str k={ctaPositive}/>
              </CtaPositive>
            )}
            {ctaNegative && (
              <CtaNegative href={ctaNegativeHref}>
                <Str k={ctaNegative}/>
              </CtaNegative>
            )}
          </CtaCont>
        </Content>
      </ContentRoot>
    </ScreenFrame>
  );
};

const ContentRoot = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
`;

const ImageCont = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

const Image = styled.img`
  max-width: 80%;
`;

const CtaCont = styled.div`
  margin-top: 58px;
  width: 100%;
`;

const Cta = styled.a`
  ${fixedHeight(55)};
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 11px;
  font-weight: 800;
  justify-content: center;
  letter-spacing: 1px;
  line-height: 15px;
  margin-top: 6px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`;

const CtaPositive = styled(Cta)`
  background-color: ${Colors.red};
  color: ${Colors.white} !important;
`;

const CtaNegative = styled(Cta)`
  background-color: ${Colors.accentDark};
  color: ${Colors.white};
`;
