import * as React from 'react';
import { TFormValueTypes } from '../form/useForm';
import { FormDefSignup } from './FormDefSignup';
import { Log } from '../../config/Instance';
import { safeDelay } from '../../../../core/src/lib/HelperFunctions';
import { TFormSignup } from './useFormSignup';
import { stripMetaFields } from '../../../../core/src/lib/stripMetaFields';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';
import { Analytics } from '../../lib/analytics/Analytics';
import { useNavigator } from '../../app/navigation/Navigator';
import { Validators } from '../../lib/validators/Validators';
import { AppAlacarteApiAccess } from '../../lib/api/AppAlacarteApiAccess';
import { useNavigateOnLoginSuccess } from '../../app/navigation/useNavigateOnLoginSuccess';
import { UserContext } from '../../app/user/UserContext';

export function useFormSignupOnSubmit(form: TFormSignup) {
  const { onHostLogin } = React.useContext(UserContext);
  const navigateOnLoginSuccess = useNavigateOnLoginSuccess();

  const { navigatorRef } = useNavigator();

  const onSubmit2 = React.useCallback(async (data: TFormValueTypes<typeof FormDefSignup>) => {
    const navigator = navigatorRef.current;
    try {
      const response = await AppAlacarteApiAccess.hostCreate.request({
        body: {
          ...stripMetaFields(data),
          mainLocale: navigatorRef.current.locale,
          verifyEmailRedirectUrl: navigator.getHrefToRoute({
            route: RoutesLanding.Success.name,
            hash: RoutesLanding.Success.hashes.top,
            search: {
              showHeader: `${true}`,
              title: 'landingSuccessVerifyemailTitle',
              description: 'landingSuccessVerifyemailDescription',
              imageSrc: 'assets/svg/alacarte-logo.svg',
              ctaPositive: 'landingSuccessVerifyemailCtaPositive',
              ctaPositiveHref: navigator.getHrefToRoute({
                route: RoutesLanding.Profile.name,
                hash: RoutesLanding.Profile.hashes.top,
                search: {},
              }),
            },
          }),
        },
      });

      await Analytics.userAlias({ hostId: response.hostId });
    } catch (e) {
      Log.v('SignupFormContext', 'useSignupFormOnSubmit', `Signup error ${e?.message}`);
      return Validators.setError({
        form,
        fieldName: FormDefSignup.meta_globalError.name,
        errorString: e?.message ?? '',
      });
    }

    try {
      await safeDelay(250);
      await onHostLogin({
        email: data.email,
        password: data.password,
      });
      navigateOnLoginSuccess();
    } catch (e) {
      Log.v('SignupFormContext', 'useSignupFormOnSubmit', `Login error ${e?.message}`);
      navigator.routeReplace({
        route: RoutesLanding.Login.name,
        hash: RoutesLanding.Login.hashes.top,
        search: {},
      });
    }
  }, [
    form,
    navigatorRef,
    onHostLogin,
    navigateOnLoginSuccess,
  ]);

  const onSubmit = form.handleSubmit(onSubmit2);

  return React.useCallback(async () => {
    form.clearErrors();
    await onSubmit();
  }, [form, onSubmit]);
}
