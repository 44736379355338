// import { FilterTypeNotStartingWith } from './Types';
// FilterTypeNotStartingWith<T, 'meta_'>
export function stripMetaFields<T>(data: T): any {
  return Object.keys(data).reduce((acc, key) => {
    if (!key.startsWith('meta_')) {
      acc[key] = data[key];
    }
    return acc;
  }, {} as any);
}
