import * as React from 'react';
import { StyledHotKeys } from '../../StyledHotKeys';
import { WizardScreen } from '../WizardScreen';
import { Str } from '../../localized/Str';
import { Strings } from '../../../../../core/src/locale/Strings';
import { WizardTextField } from '../WizardTextField';
import { useFormFocusController } from '../../../../../lib-react/src/hooks/useFormFocusController';
import { TWizardSalesmanState, useWizardSalesmanState } from './useWizardSalesmanState';
import { RoutesLanding } from '../../../../../core/src/lib/apis/routes/RoutableLanding';
import { useWizardSalesmanNavigator } from './useWizardSalesmanNavigator';
import { useWizardSalesmanErrors } from './useWizardSalesmanErrors';
import { WizardTheme } from './WizardTheme';
import { useWizardSalesmanAnalyticsTracker } from './useWizardSalesmanAnalyticsTracker';
import { Validator } from '../../../../../core/src/lib/error/Validators';
import { ELocale } from '../../../../../core/src/locale/Locale';

export const ScreenWizardSalesmanStart = () => {
  useWizardSalesmanAnalyticsTracker();

  const {
    onGeneralFocus,
    getInputRef,
  } = useFormFocusController();

  const {
    state,
    setState,
  } = useWizardSalesmanState();

  const navigateToVenueScreen = useWizardSalesmanNavigator(RoutesLanding.WizardSalesVenue.name);

  const errors = useWizardSalesmanErrors({
    mapStateToErrors(st: TWizardSalesmanState, locale: ELocale) {
      // todo refactor to make this pattern reusable
      const email = Validator.expectEmail(st.email ?? '');
      if (email.length > 0) {
        return { email: email[0].message(locale) };
      }
      return {};
    },
  });

  return (
    <StyledHotKeys
      keyMap={{ ENTER: 'Enter' }}
      handlers={{ ENTER: onGeneralFocus }}
    >
      <WizardScreen
        theme={WizardTheme}
        title={<Str k={Strings.keys.landingWizardSalesHomeTitle}/>}
        subtitle={<Str k={Strings.keys.landingWizardSalesHomeSubtitle}/>}
        description={<Str k={Strings.keys.landingWizardSalesHomeDescription}/>}
        globalError={errors.globalError}
        content={(
          <WizardTextField
            required
            theme={WizardTheme}
            variant="standard"
            type="email"
            label={<Str k={Strings.keys.landingWizardSalesHomeEmailLabel}/>}
            helperText={<Str k={Strings.keys.landingWizardSalesHomeEmailHelper}/>}
            value={state.email || ''}
            setValue={(email) => setState({ email })}
            error={errors.errors.email}
            inputRef={getInputRef({
              tabIndex: 0,
              clickNext: true,
            })}
          />
        )}
        premise={<Str k={Strings.keys.landingWizardSalesHomePremise}/>}
        onPositive={!errors.hasError ? navigateToVenueScreen : undefined}
        positiveText={<Str k={Strings.keys.landingWizardSalesHomePositive}/>}
        positiveElementRef={getInputRef({
          tabIndex: 1,
          clickThis: true,
          focusNext: true,
          clickThisOnGeneralFocus: true,
        })}
      />
    </StyledHotKeys>
  );
};
