
import styled from 'styled-components';
import { Row } from '../css';

export const FormRow = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
`;
