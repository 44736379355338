import { RoutesLanding } from '../../../core/src/lib/apis/routes/RoutableLanding';
import { isProduction } from '../../../core/src/lib/Environment';

type TRoutesLandingOptions = {
  [k in keyof typeof RoutesLanding]: {
    requireLogin: boolean;
    onlyAnonymous: boolean;
    title: string;
  }
};

export const RoutesLandingOptions: TRoutesLandingOptions = {
  Home: {
    requireLogin: false,
    onlyAnonymous: false,
    title: 'landingRouteHomeTitle',
  } as const,

  Start: {
    requireLogin: false,
    onlyAnonymous: false,
    title: 'landingRouteHomeTitle',
  } as const,

  Plans: {
    requireLogin: false,
    onlyAnonymous: false,
    title: 'landingRoutePlansTitle',
  } as const,

  Profile: {
    requireLogin: isProduction(),
    onlyAnonymous: false,
    title: 'landingRouteProfileTitle',
  } as const,

  Login: {
    requireLogin: false,
    onlyAnonymous: isProduction(),
    title: 'landingRouteLoginTitle',
  } as const,

  Signup: {
    requireLogin: false,
    onlyAnonymous: isProduction(),
    title: 'landingRouteSignupTitle',
  } as const,

  LegalTermsApp: {
    requireLogin: false,
    onlyAnonymous: false,
    title: 'landingRouteLegaltermsappTitle',
  } as const,

  LegalTermsLanding: {
    requireLogin: false,
    onlyAnonymous: false,
    title: 'landingRouteLegaltermslandingTitle',
  } as const,

  LegalPrivacyPolicy: {
    requireLogin: false,
    onlyAnonymous: false,
    title: 'landingRouteLegalprivacypolicyTitle',
  } as const,

  Legal: {
    requireLogin: false,
    onlyAnonymous: false,
    title: 'landingRouteLegalTitle',
  } as const,

  WizardSalesStart: {
    requireLogin: false,
    onlyAnonymous: false,
    title: 'landingRouteWizardTitle',
  } as const,

  WizardSalesVenue: {
    requireLogin: false,
    onlyAnonymous: false,
    title: 'landingRouteWizardTitle',
  } as const,

  WizardSalesInfo: {
    requireLogin: false,
    onlyAnonymous: false,
    title: 'landingRouteWizardTitle',
  } as const,

  WizardSalesDone: {
    requireLogin: false,
    onlyAnonymous: false,
    title: 'landingRouteWizardTitle',
  } as const,

  Success: {
    requireLogin: false,
    onlyAnonymous: false,
    title: 'landingRouteSuccessTitle',
  } as const,
};
