import * as React from 'react';
import { useSafeState } from '../../../lib-react/src/hooks/useSafeState';
import { useSyncedDataRef } from '../../../lib-react/src/hooks/useSyncedDataRef';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const initial = {
  width: 480,
  height: 850,
};

function useWindowDimensions() {
  // React snap initial sizes
  const [windowDimensions, , dimensionsRef] = useSafeState(initial);

  const updateDimensionsRef = useSyncedDataRef(function () {
    const dimensions = getWindowDimensions();
    dimensionsRef.current.setState(getWindowDimensions());
    initial.width = dimensions.width;
    initial.height = dimensions.height;
  });

  React.useEffect(() => {
    const updateDimensions = () => updateDimensionsRef.current();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [updateDimensionsRef]);

  React.useEffect(() => {
    const updateDimensions = () => updateDimensionsRef.current();
    const interval = setInterval(updateDimensions, 500);
    return () => clearInterval(interval);
  }, [updateDimensionsRef]);

  return windowDimensions;
}

export type TWithWindowDimensionsProps<P> = P & {
  dimensions: ReturnType<typeof useWindowDimensions>;
};

export function withWindowDimensions<P>(Component: React.FunctionComponent<TWithWindowDimensionsProps<P>>): React.FunctionComponent<P> {
  return function (props: P) {
    const dimensions = useWindowDimensions();
    return (
      // @ts-ignore
      <Component
        dimensions={dimensions}
        {...props}
      />
    );
  } as React.FunctionComponent<P>;
}

