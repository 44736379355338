import * as React from 'react';
import styled from 'styled-components';
import { SvgWarningIcon } from '../assets/Assets';

type TErrorProps = {
  message: React.ReactNode;
  focusOnMount?: boolean;
};

export const ErrorView = ({
  message,
  focusOnMount = false,
}: TErrorProps) => {
  const ref = React.createRef<HTMLDivElement>();
  React.useEffect(() => {
    if (focusOnMount) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  return (
    <ErrorContainer ref={ref}>
      <SvgWarningIcon
        width={25}
        height={25}
        fill="red"
      />
      <ErrorMessageContainer>
        {message}
      </ErrorMessageContainer>
    </ErrorContainer>
  );
};

const ErrorContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
`;

const ErrorMessageContainer = styled.div`
  color: red;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 19px;
  margin-left: 12px;
  text-align: center;
`;
