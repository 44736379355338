import * as React from 'react';

import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  TextField,
} from '@mui/material';
import styled from 'styled-components';
import { Colors } from '../config/Colors';

export const MuiTextField = styled(TextField)<{ labelBackground?: string }>`
  & .MuiOutlinedInput-root {
    border-radius: 0;
  }

  & .MuiInputLabel-root {
    background-color: ${({ labelBackground }) => labelBackground || Colors.white};
    margin-top: -2px;
    padding: 0 4px;
  }
`;

type TMuiCheckboxProps = {
  label: React.ReactNode;
  helperText?: React.ReactNode;
  error?: boolean;
  required?: boolean;
  inputRef?: React.MutableRefObject<any>;
  [key: string]: any;
};

export const MuiCheckbox = ({
  label,
  helperText,
  error = false,
  required = false,
  inputRef,
  ...other
}: TMuiCheckboxProps) => (
  <>
    <StyledCheckboxFormControlLabel
      hasError={!!error}
      label={label}
      {...other}
      control={(
        <StyledCheckbox
          inputRef={inputRef}
          required={required}
        />
      )}
    />
    <StyledCheckboxHelperText hasError={!!error}>
      {(helperText) && (
        <>
          {helperText}
        </>
      )}
    </StyledCheckboxHelperText>
  </>
);

const StyledCheckboxFormControlLabel = styled(FormControlLabel)<{ hasError: boolean }>`
  & .MuiTypography-root {
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 16px;
  }

  ${({ hasError }) => (hasError ? `
    color: #d32f2f;
  ` : '')}

  text-align: left;
`;

const StyledCheckbox = styled(Checkbox)`
`;

const StyledCheckboxHelperText = styled(FormHelperText)<{ hasError: boolean }>`
  ${({ hasError }) => (hasError ? `
    color: #d32f2f;
  ` : '')}
`;
