import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../config/Colors';
import {
  ClipLoader,
  PulseLoader,
} from 'react-spinners';

import { TClassNameProps } from './TReactChildrenProps';
import stringify from 'fast-json-stable-stringify';

type TReactSpinnerProps = {
  className?: string;
  size?: number;
  color?: string;
};

export function hocBuildSpinner(Component: any, props: TReactSpinnerProps) {
  return ({ className }: TClassNameProps) => {
    return React.useMemo(() => (
      <Component
        className={className}
        {...props}
      />
    ), [className, stringify(props)]);
  };
}

export const LoaderClip = ({
  className,
  size = 20,
  color = Colors.accentDark,
}: TReactSpinnerProps) => (
  <LoaderRoot className={className}>
    <ClipLoader
      size={size}
      color={color}
      speedMultiplier={0.3}
    />
  </LoaderRoot>
);

export const LoaderPulse = ({
  className,
  size = 10,
  color = Colors.accentDark,
}: TReactSpinnerProps) => (
  <LoaderRoot className={className}>
    <PulseLoader
      size={size}
      color={color}
    />
  </LoaderRoot>
);

export const LoaderClipAccentDark20 = hocBuildSpinner(LoaderClip, { color: Colors.accentDark });

export const LoaderClipWhite20 = hocBuildSpinner(LoaderClip, { color: Colors.white });

export const LoaderPulseWhite = hocBuildSpinner(LoaderPulse, { color: Colors.white });

const LoaderRoot = styled.div`
  padding-top: 4px;
`;
