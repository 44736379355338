import * as React from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';
import { MuiTextField } from '../Mui';
import { Str } from '../localized/Str';
import { TWizardTheme } from './WizardScreenBase';

type TWizardTextFieldProps = {
  theme: TWizardTheme;
  value: string;
  setValue: (val: string) => void;
  error?: string;
  label: React.ReactNode;
  setFocused?: (focused: boolean) => void;
  inputRef?: React.MutableRefObject<any>;
  helperText?: React.ReactNode;
  type?: string;
  variant?: 'standard' | 'outlined';
  required?: boolean;
};

export function WizardTextField({
  theme,
  value,
  setValue,
  error: constantError,
  label,
  setFocused,
  helperText,
  inputRef,
  type = 'text',
  variant = 'outlined',
  required = false,
}: TWizardTextFieldProps) {
  const onChange = React.useCallback((e) => setValue(e.target.value), [setValue]);

  const [error, setError] = React.useState<any>();
  React.useEffect(() => {
    if (!_.isEmpty(error) && _.isEmpty(constantError)) {
      setError('');
    }
  }, [error, constantError]);

  const onBlur = React.useCallback(() => {
    setError(constantError);
    setFocused?.(false);
  }, [setError, constantError, setFocused]);

  // Force label to move out of the way when component
  // is mounted with a default value
  // React.useEffect(() => setValue(value), []);

  const onFocus = React.useCallback(() => {
    setFocused?.(true);
  }, [setFocused]);

  return (
    <StyledMuiTextField
      onBlur={onBlur}
      onFocus={onFocus}
      fullWidth
      theme={theme}
      value={value}
      onChange={onChange}
      labelBackground={variant === 'standard' ? 'transparent' : undefined}
      variant={variant}
      type={type}
      error={!_.isEmpty(error)}
      required={required}
      label={label}
      inputRef={inputRef}
      helperText={_.isEmpty(error)
        ? helperText
        : <Str k={error}/>}
    />
  );
}

const StyledMuiTextField = styled(MuiTextField)<{ error: boolean } & TWithWizardTheme>`
  width: 100%;

  & .MuiOutlinedInput-root {
    border-radius: 0;
  }

  & .MuiInputLabel-root {
    background-color: ${({ theme }) => theme.colorBackground};
    color: ${({ theme }) => theme.colorPrimary};
    margin-top: -2px;
    padding: 0 4px;
  }

  & .MuiFormHelperText-root {
    color: ${({ theme, error }) => (error ? theme.red : theme.colorSecondary)};
  }

  & .MuiInputBase-input {
    color: ${({ theme }) => theme.colorPrimary};
  }

  & .MuiInput-underline:after {
    border-bottom-color: ${({ theme, error }) => (error ? theme.red : theme.colorSecondary)};
  }

  & .MuiInput-underline:before {
    border-bottom-color: ${({ theme, error }) => (error ? theme.red : theme.colorSecondary)};
  }
`;
