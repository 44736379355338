import { useWizardSalesmanState } from './useWizardSalesmanState';
import { useLocale } from '../../../locale/useLocale';
import { useWizardSalesmanNavigator } from './useWizardSalesmanNavigator';
import { RoutesLanding } from '../../../../../core/src/lib/apis/routes/RoutableLanding';
import * as React from 'react';
import { AppAlacarteApiAccess } from '../../../lib/api/AppAlacarteApiAccess';
import { Config } from '../../../config/Config';
import { Log } from '../../../config/Instance';

export function useWizardSalesmanInfoOnContinue() {
  const { state } = useWizardSalesmanState();

  const locale = useLocale();
  const navigateToDoneScreen = useWizardSalesmanNavigator(RoutesLanding.WizardSalesDone.name);
  return React.useCallback(async () => {
    try {
      await AppAlacarteApiAccess.wizardSalesmanComplete.request({
        body: {
          coupon: Config.STRIPE_COUPON_WIZARDSALESMAN_ID,
          stripeCoupon: Config.STRIPE_COUPON_WIZARDSALESMAN_ID,
          email: state.email || '',
          hostName: state.hostName || '',
          name: state.name,
          surname: state.surname,
          hostAddressLine1: state.hostAddressLine1,
          hostCity: state.hostCity,
          hostCoordinates: state.hostCoordinates,
          hostCountry: state.hostCountry,
          hostPhone: state.hostPhone,
          hostPlaceId: state.hostPlaceId,
          hostPostalCode: state.hostPostalCode,
          hostState: state.hostState,
          mainLocale: locale,
        },
      });
    } catch (e) {
      // Best effort no errors
      Log.v('WizardSalesman', '/info', `Got error ${e && e.message}`);
    }

    navigateToDoneScreen();
  }, [navigateToDoneScreen, locale, state]);
}
