import * as React from 'react';
import { TFormContact } from './useFormContact';
import { TFormValueTypes } from '../form/useForm';
import { Log } from '../../config/Instance';
import { FormDefContact } from './FormDefContact';
import { stripMetaFields } from '../../../../core/src/lib/stripMetaFields';
import { useNavigator } from '../../app/navigation/Navigator';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';
import { AppAlacarteApiAccess } from '../../lib/api/AppAlacarteApiAccess';

export function useFormContactOnSubmit(form: TFormContact) {
  const { navigatorRef } = useNavigator();

  const onSubmit = React.useCallback(async (data: TFormValueTypes<typeof FormDefContact>) => {
    const navigator = navigatorRef.current;
    try {
      await AppAlacarteApiAccess.contactEmail.request({ body: { ...stripMetaFields(data) } });

      navigator.routeReplace({
        route: RoutesLanding.Success.name,
        hash: RoutesLanding.Success.hashes.top,
        search: {
          showHeader: `${true}`,
          title: 'success.landingContactTitle',
          description: 'landingSuccessContactDescription',
          imageSrc: 'assets/svg/logo-vertical.svg',
          ctaPositive: 'landingSuccessContactCtaPositive',
          ctaPositiveHref: navigator.getHrefToRoute({
            route: RoutesLanding.Home.name,
            hash: RoutesLanding.Home.hashes.top,
            search: {},
          }),
        },
      });
    } catch (e) {
      Log.v('useFormContactOnSubmit', 'useFormContactOnSubmit', `Signup error ${e?.message}`);
      form.setError(
        FormDefContact.meta_globalError.name,
        { message: e?.message || '' },
        { shouldFocus: true },
      );
    }
  }, [navigatorRef]);

  return form.handleSubmit(onSubmit);
}
