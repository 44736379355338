import { useForm } from 'react-hook-form';
import { TFormValueTypes } from '../form/useForm';
import { FormDefLogin } from './FormDefLogin';
import { useFormLoginOnSubmit } from './useFormLoginOnSubmit';

export type TFormLogin = ReturnType<typeof useFormOfLoginType>;

export function useFormOfLoginType() {
  return useForm<TFormValueTypes<typeof FormDefLogin>>();
}

export function useFormLogin() {
  const form = useFormOfLoginType();
  const onSubmit = useFormLoginOnSubmit(form);
  return {
    form,
    onSubmit,
  };
}

