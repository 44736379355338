
export const ElementIds = {
  mainScriptAppNavigator: 'mainScriptAppNavigator',
  headerBurger: 'header__burger',
  navigator__bg: 'navigator__bg',
};

export const Elements = {
  get app() {
    return document.getElementById('root');
  },
  get getMainScriptAppNavigator() {
    return document.getElementById(ElementIds.mainScriptAppNavigator);
  },

  get process() {
    return document.querySelector('.process');
  },
  get processRestaurant() {
    return this.process?.querySelector('[data-ref="restaurant"]');
  },
  get processRestaurantList() {
    return this.process?.querySelector('[data-list="restaurant"]');
  },
  get processCustomer() {
    return this.process?.querySelector('[data-ref="customer"]');
  },
  get processCustomerList() {
    return this.process?.querySelector('[data-list="customer"]');
  },

  get info() {
    return document.getElementById('info');
  },
  get infoAccordions() {
    return document.querySelector('.info__accordions-container');
  },

  get headerBurger() {
    return document.getElementById(ElementIds.headerBurger);
  },

  get navigator__bg() {
    return document.getElementById(ElementIds.navigator__bg);
  },
};
