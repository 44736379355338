import * as React from 'react';

import styled from 'styled-components';
import { fixedHeight, fixedWidth, Row } from '../../components/css';
import { Colors } from '../../config/Colors';
import { Str } from '../../components/localized/Str';
import { LocalizedLink } from '../../components/router/LocalizedLink';
import { LocalizedImg } from '../../components/localized/LocalizedImg';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';
import { Strings } from '../../../../core/src/locale/Strings';

type THomeSectionHeroProps = {
  //
};

export const HomeSectionHero = (props: THomeSectionHeroProps) => {
  return (
    <section className="hero">
      <div className="hero__wrapper u-wrapper">
        <div className="hero__column hero__column--centerd">
          <h1 className="hero__title u-title">
            <Str k={Strings.keys.landingHeroTitle}/>
          </h1>
          <hr className="hero__line"/>
          <p className="hero__content">
            <Str k={Strings.keys.landingHeroContent}/>
          </p>
          <LocalizedLink
            route={RoutesLanding.Home.name}
            hash={RoutesLanding.Home.hashes.info}
            search={{}}
            title={(
              <ButtonRed data-anchor="info">
                <Str k={Strings.keys.landingHeroDiscoverMore}/>
              </ButtonRed>
            )}
          />
        </div>
        <div className="hero__column">
          <LocalizedImg
            k={Strings.keys.landingAssetHomeHeroPicture}
          />
        </div>
      </div>
    </section>
  );
};

const ButtonRed = styled(Row)`
  ${fixedHeight(55)};
  ${fixedWidth(210)};
  align-items: center;
  background-color: ${Colors.red};
  color: ${Colors.white};
  cursor: pointer;
  font-size: 11px;
  font-weight: 800;
  justify-content: center;
  letter-spacing: 1px;
  line-height: 15px;
  padding: 0 35px;
  text-align: center;
`;
