import * as React from 'react';
import { LocalizedSource } from '../../components/localized/LocalizedSource';
import { Strings } from '../../../../core/src/locale/Strings';
import { ImgGirlSquared } from '../../assets/Assets';

type THomeSectionHalfPictureProps = {
  //
};

export const HomeSectionHalfPicture = (props: THomeSectionHalfPictureProps) => {
  return (
    <div className="half-picture">
      <div className="half-picture__column half-picture__column--centered">
        <span className="half-picture__bg"></span>

        {/* {{!-- <LocalizedImg k={'landingAssetHomeHalfpicturePlaceholder)}/> --}} */}
        <video className="video--mbl" crossOrigin="anonymous" loop muted playsInline autoPlay>
          <LocalizedSource type="video/mp4" k={Strings.keys.landingAssetHomeHalfpictureVideo}/>
        </video>
        <video className="video--dsk" crossOrigin="anonymous" loop muted playsInline autoPlay>
          <LocalizedSource type="video/mp4" k={Strings.keys.landingAssetHomeHalfpictureVideohd}/>
        </video>
      </div>

      <div className="half-picture__column half-picture__column--picture">
        <img src={ImgGirlSquared}/>
      </div>
    </div>
  );
};
