import * as React from 'react';

import styled from 'styled-components';
import { Str } from './localized/Str';
import { Strings } from '../../../core/src/locale/Strings';

export const Footer = () => {
  return (
    <Root className="b-footer footer">
      <div className="footer__wrapper">
        <a href="https://www.madideas.io">
          <strong className="footer__item">
            <Str k={Strings.keys.landingFooterTop}/>
          </strong>
        </a>
        <div className="footer__item">
          <Str k={Strings.keys.landingFooterDetails}/>
        </div>
      </div>
    </Root>
  );
};

const Root = styled.footer`
`;
