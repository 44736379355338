import * as React from 'react';
import { PropsWithChildren } from 'react';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { Refs } from '../../../../core/src/db/DbRefs';
import { THostPublicSubscription } from '../../../../core/src/models/db/HostPublicSubscription/HostPublicSubscriptionTypes';
import { Firebase } from '../Firebase';
import * as _ from 'lodash';
import { Log } from '../../config/Instance';
import firebase from 'firebase';
import { AbsSafeRef } from '../../../../core/src/lib/model/SafeRef';

type TLoginParams = {
  email: string;
  password: string;
};

export const UserContext = React.createContext({
  firebaseUser: undefined as undefined | firebase.User,
  host: undefined as undefined | THost,
  hostPublicSubscription: undefined as undefined | THostPublicSubscription,
  isLoggedIn: false,
  onHostLogin: _.noop as (params: TLoginParams) => Promise<any>,
});

export const UserContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const {
    firebaseUser,
    onHostLogin,
  } = useFirebaseUser();

  const host = useSubscribeToHost(firebaseUser);
  const hostPublicSubscription = useHostPublicSubscription(firebaseUser);

  return (
    <UserContext.Provider
      value={{
        firebaseUser,
        host,
        hostPublicSubscription,
        onHostLogin,
        isLoggedIn: [
          firebaseUser,
          host,
          hostPublicSubscription,
        ].every((item) => item != null),
      }}>
      {children}
    </UserContext.Provider>
  );
};

function useFirebaseUser() {
  const currentUser = Firebase.getCurrentUser();
  const [firebaseUser, setFirebaseUser] = React.useState<firebase.User | undefined>(currentUser);

  React.useEffect(() => {
    currentUser && setFirebaseUser(currentUser);
  }, [currentUser?.uid]);

  // On mount check if already logged in
  React.useEffect(() => {
    const { unsubscribe } = Firebase.onAuthStateChanged((updatedUser) => {
      const user = updatedUser != null ? updatedUser : undefined;
      setFirebaseUser(user);
    });

    return () => unsubscribe();
  }, [setFirebaseUser]);

  const onHostLogin = React.useCallback(async (params: TLoginParams) => {
    const updatedUser = await Firebase.authSignInWithEmailAndPassword(params.email, params.password);
    setFirebaseUser(updatedUser);
  }, []);

  return {
    firebaseUser,
    onHostLogin,
  };
}

function useSubscribeToHost(user: firebase.User | undefined): THost | undefined {
  const subscriptionKey = 'useSubscribeToHost';
  const hostId = user?.uid;

  const [host, setHost] = React.useState<THost | undefined>();

  // Subscribe to Host
  React.useEffect(() => {
    if (!hostId) {
      return;
    }

    const pbp = { hostId };
    Refs.host(pbp).bindByKey({
      key: subscriptionKey,
      onChange(value: THost) {
        Log.v('UserContext', 'useSubscribeToHost', `${hostId} host changed`);
        setHost(value);
      },
    });

    return () => {
      AbsSafeRef.globalUnBindByKey(subscriptionKey);
    };
  }, [subscriptionKey, hostId, setHost]);

  return host;
}

function useHostPublicSubscription(user: firebase.User | undefined): THostPublicSubscription | undefined {
  const subscriptionKey = 'useSubscribeToHostPublicSubscription';
  const hostId = user?.uid;

  const [hostPublicSubscription, setHostPublicSubscription] = React.useState<THostPublicSubscription | undefined>();

  // Subscribe to HostPublicSubscription
  React.useEffect(() => {
    if (!hostId) {
      return;
    }

    const pbp = { hostId };
    Refs.hostPublicSubscription(pbp).bindByKey({
      key: subscriptionKey,
      onChange(value: THostPublicSubscription) {
        Log.v('UserContext', 'useHostPublicSubscription', `${hostId} subscription changed`);
        setHostPublicSubscription(value);
      },
    });

    return () => {
      AbsSafeRef.globalUnBindByKey(subscriptionKey);
    };
  }, [subscriptionKey, hostId, setHostPublicSubscription]);

  return hostPublicSubscription;
}
