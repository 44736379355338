import * as React from 'react';
import { RoutesLanding } from '../../../../../core/src/lib/apis/routes/RoutableLanding';
import * as _ from 'lodash';
import { uuid } from '../../../../../core/src/lib/UUID';
import { useSyncedDataRef } from '../../../../../lib-react/src/hooks/useSyncedDataRef';
import { isSupportedLangOrDefault } from '../../../../../core/src/lib/formatters/LocaleLib';
import { useURLState } from '../../../../../lib-react/src/contexts-browser/useURLState';

export type TWizardSalesmanState = {
  referralId?: string;
  sessionId?: string;
  email?: string;
  name?: string;
  surname?: string;
  hostName?: string;
  hostCoordinates?: string;
  hostPlaceId?: string;
  hostAddressLine1?: string;
  hostPhone?: string;
  hostCountry?: string;
  hostState?: string;
  hostPostalCode?: string;
  hostCity?: string;
};

export function useWizardSalesmanState() {
  const urlState = useURLState(RoutesLanding.WizardSalesStart);
  // useURLSync(window, urlState.url);

  const setState = React.useCallback((state: Partial<TWizardSalesmanState>) => {
    urlState.updateUrl({
      searchParams: state,
      pathParams: { locale: isSupportedLangOrDefault(urlState.deconstructed.pathParams.locale) },
    });
  }, [urlState]);

  // On mount ensure sessionId is set
  React.useEffect(() => {
    if (_.isEmpty(urlState.deconstructed.searchParams.sessionId)) {
      setState({ sessionId: uuid() });
    }
  }, []);

  const ref = useSyncedDataRef({
    urlState,
    setState,
  });

  return {
    baseURL: urlState.deconstructed.baseURL,
    state: urlState.deconstructed.searchParams as TWizardSalesmanState,
    setState,
    ref,
  };
}
