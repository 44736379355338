import { TGenericRoutableRoute } from '../../../core/src/lib/define/routable/RoutableTypes';
import { SecondParameter } from '../../../core/src/lib/Types';
import { URLToolbox } from '../../../core/src/lib/define/url-toolbox/URLToolbox';
import * as React from 'react';
import { useSyncedDataRef } from '../hooks/useSyncedDataRef';
import { RoutableRoute } from '../../../core/src/lib/define/routable/RoutableRoute';
import { URLStateContext } from './URLStateContext';

type TUpdateURLSecondParam<Route extends TGenericRoutableRoute> = SecondParameter<URLToolbox<Route['pathParams'], Route['params']>['updateURL']>;

export function useURLState<Route extends TGenericRoutableRoute>(route: Route) {
  const {
    url,
    setUrl,
  } = React.useContext(URLStateContext);

  const urlRef = useSyncedDataRef(url);
  const setUrlRef = useSyncedDataRef(setUrl);

  const toolbox = RoutableRoute.buildURLToolbox(route);
  const toolboxRef = useSyncedDataRef(toolbox);

  const deconstructed = React.useMemo(() => {
    return toolboxRef.current.deconstructURL(url);
  }, [url]);

  const updateUrl = React.useCallback((params: TUpdateURLSecondParam<Route>) => {
    const deconstructedURLUpdated = toolboxRef.current.updateURL(
      urlRef.current,
      params,
    );
    setUrlRef.current(deconstructedURLUpdated);
  }, [toolboxRef, urlRef, setUrlRef]);

  return {
    url,
    updateUrl,
    deconstructed,
  };
}
