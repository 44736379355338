import * as React from 'react';
import { Colors } from '../../config/Colors';
import { Config } from '../../config/Config';
import styled from 'styled-components';
import { TawkTo } from '../../lib/TawkTo';
import { useStripeOnCheckoutClick } from '../../app/stripe/useStripeOnCheckoutClick';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';
import { useNavigator } from '../../app/navigation/Navigator';
import { ScreenFrame } from '../../components/ScreenFrame';
import { ScreenHeader } from '../../components/ScreenHeader';
import { Str } from '../../components/localized/Str';
import { PlanBox } from './PlanBox';
import { Strings } from '../../../../core/src/locale/Strings';
import { ESubscriptionType } from '../../../../core/src/models/db/hostSubscription/HostSubscriptionTypes';
import { useDeconstructedUrl } from '../../../../lib-react/src/hooks/useDeconstructedUrl';
import { UserContext } from '../../app/user/UserContext';

export const ScreenPlans = () => {
  const onStarterCheckoutClick = useStripeOnCheckoutClick(Config.STRIPE_PRICE_STARTER_PLAN_ID);
  const onPremiumCheckoutClick = useStripeOnCheckoutClick(Config.STRIPE_PRICE_PREMIUM_PLAN_ID);

  const onGoldCheckoutClick = React.useCallback(async () => {
    TawkTo.openChat();
  }, []);

  const { isLoggedIn } = React.useContext(UserContext);
  const { navigatorRef } = useNavigator();
  const navigateToSignup = React.useCallback(async () => {
    navigatorRef.current.routePush({
      route: RoutesLanding.Signup.name,
      hash: RoutesLanding.Signup.hashes.top,
      search: {},
    });
  }, [navigatorRef]);

  const { searchParams } = useDeconstructedUrl(RoutesLanding.Plans, window.location.href);
  React.useEffect(() => {
    (async () => {
      // todo fix once we can reliably wait for auth state, also ensure params.expectAuthEmail is the one logged in
      await new Promise((resolve) => setTimeout(resolve, 500));
      if (searchParams.plan === ESubscriptionType.Premium) {
        return onPremiumCheckoutClick();
      }
      if (searchParams.plan === ESubscriptionType.Starter) {
        return onStarterCheckoutClick();
      }
      if (searchParams.plan === ESubscriptionType.Gold) {
        return onGoldCheckoutClick();
      }
    })();
  }, [searchParams, onStarterCheckoutClick, onPremiumCheckoutClick]);

  return (
    <ScreenFrame id={RoutesLanding.Plans.hashes.top}>
      <ScreenHeader
        title={<Str k={Strings.keys.landingScreenPlansTitle}/>}
        description={<Str k={Strings.keys.landingScreenPlansDescription}/>}
      />
      <PlansPromoContent>
        <Str k={Strings.keys.landingPlansPromo}/>
      </PlansPromoContent>
      <PlansBoxesContainer className="plans__boxes-container">
        <PlanBox
          themeColor={Colors.white}
          themeColorNegative={Colors.accentDark}
          title={<Str k={Strings.keys.landingPlansPlanStarterTitle}/>}
          description={<Str k={Strings.keys.landingPlansPlanStarterDescription}/>}
          priceNew={<Str k={Strings.keys.landingPlansPlanStarterPrice}/>}
          priceInfo={<Str k={Strings.keys.landingPlansPlanStarterPriceInfo}/>}
          priceDescription={<Str k={Strings.keys.landingPlansPlanStarterPriceDesc}/>}
          priceCheckoutText={<Str k={Strings.keys.landingPlansPlanStarterPriceCta}/>}
          onCheckoutClick={isLoggedIn
            ? onStarterCheckoutClick
            : navigateToSignup}
          priceBenefits={[
            Strings.keys.landingPlansPlanStarterBenefits0,
            Strings.keys.landingPlansPlanStarterBenefits1,
            Strings.keys.landingPlansPlanStarterBenefits2,
            Strings.keys.landingPlansPlanStarterBenefits3,
            Strings.keys.landingPlansPlanStarterBenefits4,
            Strings.keys.landingPlansPlanStarterBenefits5,
            Strings.keys.landingPlansPlanStarterBenefits6,
            Strings.keys.landingPlansPlanStarterBenefits7,
            Strings.keys.landingPlansPlanStarterBenefits8,
            Strings.keys.landingPlansPlanStarterBenefits9,
            Strings.keys.landingPlansPlanStarterBenefits10,
          ].map((k) => (
            <Str key={k} k={k}/>
          ))}
        />
        <PlanBox
          themeColor={Colors.accentDark}
          themeColorNegative={Colors.white}
          title={<Str k={Strings.keys.landingPlansPlanPremiumTitle}/>}
          description={<Str k={Strings.keys.landingPlansPlanPremiumDescription}/>}
          priceNew={<Str k={Strings.keys.landingPlansPlanPremiumPrice}/>}
          priceInfo={<Str k={Strings.keys.landingPlansPlanPremiumPriceInfo}/>}
          priceDescription={<Str k={Strings.keys.landingPlansPlanPremiumPriceDesc}/>}
          priceCheckoutText={<Str k={Strings.keys.landingPlansPlanPremiumPriceCta}/>}
          onCheckoutClick={isLoggedIn
            ? onPremiumCheckoutClick
            : navigateToSignup}
          priceBenefits={[
            Strings.keys.landingPlansPlanPremiumBenefits0,
            Strings.keys.landingPlansPlanPremiumBenefits1,
            Strings.keys.landingPlansPlanPremiumBenefits2,
            Strings.keys.landingPlansPlanPremiumBenefits3,
            Strings.keys.landingPlansPlanPremiumBenefits4,
            Strings.keys.landingPlansPlanPremiumBenefits5,
            Strings.keys.landingPlansPlanPremiumBenefits6,
            Strings.keys.landingPlansPlanPremiumBenefits7,
            Strings.keys.landingPlansPlanPremiumBenefits8,
            Strings.keys.landingPlansPlanPremiumBenefits9,
            Strings.keys.landingPlansPlanPremiumBenefits10,
            Strings.keys.landingPlansPlanPremiumBenefits11,
            Strings.keys.landingPlansPlanPremiumBenefits12,
            Strings.keys.landingPlansPlanPremiumBenefits13,
            Strings.keys.landingPlansPlanPremiumBenefits14,
            Strings.keys.landingPlansPlanPremiumBenefits15,
            Strings.keys.landingPlansPlanPremiumBenefits16,
            Strings.keys.landingPlansPlanPremiumBenefits17,
            Strings.keys.landingPlansPlanPremiumBenefits18,
            Strings.keys.landingPlansPlanPremiumBenefits19,
            Strings.keys.landingPlansPlanPremiumBenefits20,
            Strings.keys.landingPlansPlanPremiumBenefits21,
            Strings.keys.landingPlansPlanPremiumBenefits22,
            Strings.keys.landingPlansPlanPremiumBenefits23,
          ].map((k) => (
            <Str key={k} k={k}/>
          ))}
          highlightColor={Colors.red}
          highlightText={<Str k={Strings.keys.landingPlansMostsold}/>}
        />
        <PlanBox
          themeColor={Colors.white}
          themeColorNegative={Colors.accentDark}
          title={<Str k={Strings.keys.landingPlansPlanGoldTitle}/>}
          description={<Str k={Strings.keys.landingPlansPlanGoldDescription}/>}
          priceNew={<Str k={Strings.keys.landingPlansPlanGoldPrice}/>}
          priceInfo={<Str k={Strings.keys.landingPlansPlanGoldPriceInfo}/>}
          priceDescription={<Str k={Strings.keys.landingPlansPlanGoldPriceDesc}/>}
          priceCheckoutText={<Str k={Strings.keys.landingPlansPlanGoldPriceCta}/>}
          onCheckoutClick={onGoldCheckoutClick}
          priceBenefits={[
            Strings.keys.landingPlansPlanGoldBenefits0,
            Strings.keys.landingPlansPlanGoldBenefits1,
            Strings.keys.landingPlansPlanGoldBenefits2,
            Strings.keys.landingPlansPlanGoldBenefits3,
            Strings.keys.landingPlansPlanGoldBenefits4,
          ].map((k) => (
            <Str key={k} k={k}/>
          ))}
        />
      </PlansBoxesContainer>
    </ScreenFrame>
  );
};

const PlansPromoContent = styled.div`
  color: ${Colors.red};
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
`;

const PlansBoxesContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 40px;

  &:not(:last-child) {
    margin-right: -1px;
  }
`;

