import { Validators } from '../../lib/validators/Validators';

export const FormDefSignup = {
  name: {
    name: 'name' as const,
    defaultValue: '',
    validation: { ...Validators.makeRequired({ errorString: 'landingFormFieldRequired' }) },
  },
  surname: {
    name: 'surname' as const,
    defaultValue: '',
    validation: { ...Validators.makeRequired({ errorString: 'landingFormFieldRequired' }) },
  },
  email: {
    name: 'email' as const,
    defaultValue: '',
    validation: {
      ...Validators.makeRequired({ errorString: 'landingFormFieldRequired' }),
      validateWithForm: {
        valid: Validators.isValidEmail(() => ({
          fieldName: FormDefSignup.email.name,
          errorString: 'landingFormValidationErrorEmailinvalid',
        })),
      },
    },
  },
  password: {
    name: 'password' as const,
    defaultValue: '',
    validation: {
      ...Validators.makeRequired({ errorString: 'landingFormFieldRequired' }),
      ...Validators.makeMinLength({
        value: 6,
        errorString: 'landingFormValidationErrorMinlen',
      }),
      ...Validators.makeMaxLength({
        value: 30,
        errorString: 'landingFormValidationErrorMaxlen',
      }),
    },
  },
  hostDescription: {
    name: 'hostDescription' as const,
    defaultValue: '',
    validation: { ...Validators.makeRequired({ errorString: 'landingFormFieldRequired' }) },
  },
  hostName: {
    name: 'hostName' as const,
    defaultValue: '',
    validation: { ...Validators.makeRequired({ errorString: 'landingFormFieldRequired' }) },
  },
  hostPhone: {
    name: 'hostPhone' as const,
    defaultValue: '',
    validation: { ...Validators.makeRequired({ errorString: 'landingFormFieldRequired' }) },
  },
  hostVatId: {
    name: 'hostVatId' as const,
    defaultValue: '',
    validation: { ...Validators.makeRequired({ errorString: 'landingFormFieldRequired' }) },
  },
  hostAddressLine1: {
    name: 'hostAddressLine1' as const,
    defaultValue: '',
    validation: { ...Validators.makeRequired({ errorString: 'landingFormFieldRequired' }) },
  },
  hostAddressLine2: {
    name: 'hostAddressLine2' as const,
    defaultValue: '',
    validation: {},
  },
  hostCity: {
    name: 'hostCity' as const,
    defaultValue: '',
    validation: {},
  },
  hostCoordinates: {
    name: 'hostCoordinates' as const,
    defaultValue: '',
    validation: {},
  },
  hostCountry: {
    name: 'hostCountry' as const,
    defaultValue: '',
    validation: {},
  },
  hostDenomination: {
    name: 'hostDenomination' as const,
    defaultValue: '',
    validation: {},
  },
  hostPlaceId: {
    name: 'hostPlaceId' as const,
    defaultValue: '',
    validation: {},
  },
  hostPostalCode: {
    name: 'hostPostalCode' as const,
    defaultValue: '',
    validation: {},
  },
  hostState: {
    name: 'hostState' as const,
    defaultValue: '',
    validation: {},
  },
  meta_confirmEmail: {
    name: 'meta_confirmEmail' as const,
    defaultValue: '',
    validation: {
      ...Validators.makeRequired({ errorString: 'landingFormFieldRequired' }),
      validateWithForm: {
        equalToEmail: Validators.fieldsAreEqual(() => ({
          fieldName: FormDefSignup.meta_confirmEmail.name,
          equalToFieldName: FormDefSignup.email.name,
          errorString: 'landingFormValidationErrorEmailmismatch',
        })),
      },
    },
  },
  meta_confirmPassword: {
    name: 'meta_confirmPassword' as const,
    defaultValue: '',
    validation: {
      validateWithForm: {
        equalToPassword: Validators.fieldsAreEqual(() => ({
          fieldName: FormDefSignup.meta_confirmPassword.name,
          equalToFieldName: FormDefSignup.password.name,
          errorString: 'landingFormValidationErrorPasswordmismatch',
        })),
      },
    },
  },
  newsletterAccepted: {
    name: 'newsletterAccepted' as const,
    defaultValue: true,
    validation: {},
  },
  meta_acceptConditions: {
    name: 'meta_acceptConditions' as const,
    defaultValue: false,
    validation: {
      validateWithForm: {
        accepted: Validators.booleanIsTrue(() => ({
          fieldName: FormDefSignup.meta_acceptConditions.name,
          errorString: 'landingFormValidationErrorTacnotaccepted',
        })),
      },
    },
  },
  meta_globalError: {
    name: 'meta_globalError' as const,
    defaultValue: null,
    validation: {},
  },
};
