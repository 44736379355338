import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../config/Colors';
import { hexToRGB } from '../../../../core/src/lib/HelperFunctions';
import { FormRow } from '../../components/form/FormRow';
import { MediaMaxWidth } from '../../components/header/HeaderStyle';

type TSignupFromSectionProps = {
  children: React.ReactNode;
  title?: React.ReactNode;
  className?: string;
};

export const SignupFormSection = ({
  children,
  title,
  className,
}: TSignupFromSectionProps) => {
  return (
    <Root className={className}>
      {title && (
        <SectionInfo>
          <SectionTitle>
            {title}
          </SectionTitle>
        </SectionInfo>
      )}
      <SectionContent>
        {children}
      </SectionContent>
      <hr/>
    </Root>
  );
};

const mediaMaxWidth = MediaMaxWidth.md;

const Root = styled(FormRow)`
  border-bottom: 1px solid ${hexToRGB(Colors.accentDark, 0.2)};
  padding: 10px 0;
`;

const SectionInfo = styled.div`
  flex: 0.4;
  @media ${mediaMaxWidth} {
    flex: 1;
    min-width: 80%;
  }
`;

const SectionTitle = styled.div`
  color: #2B2C33;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 44px;
  text-align: left;
  @media ${mediaMaxWidth} {
    padding-bottom: 12px;
  }
`;

const SectionContent = styled.div`
  flex: 0.6;
  @media ${mediaMaxWidth} {
    flex: 1;
    min-width: 80%;
  }
`;
