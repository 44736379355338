import * as _ from 'lodash';
import { generatePath, useHistory } from 'react-router';
import { urlParamsToObj } from '../../../../core/src/lib/HelperFunctions';
import { RoutesLanding, TRoutesLandingNames } from '../../../../core/src/lib/apis/routes/RoutableLanding';
import { TNavigationParam } from './Navigator';
import { ELocale, localeToLang } from '../../../../core/src/locale/Locale';

export type TNavigatorParams = {
  locale: ELocale;
  history: ReturnType<typeof useHistory>;
};

export abstract class AbsNavigator {
  static allPaths(route: TRoutesLandingNames) {
    return [
      RoutesLanding[route].path,
      ..._.values(RoutesLanding[route].secondaryPaths),
    ];
  }

  static routePathnames(route: TRoutesLandingNames, locale: ELocale) {
    return AbsNavigator.allPaths(route)
      .map((path) => AbsNavigator.makeBasePath(path, locale));
  }

  static routePathname(route: TRoutesLandingNames, locale: ELocale) {
    return AbsNavigator.makeBasePath(RoutesLanding[route].path, locale);
  }

  static makeBasePath(path: string, locale: ELocale) {
    return generatePath(path, { locale: localeToLang(locale) });
  }

  readonly locale: ELocale;

  protected readonly history: ReturnType<typeof useHistory>;

  static makeSearch(params: Record<string, string> = {}): string {
    const urlParams = new URLSearchParams();
    _.forOwn(params, (value, key) => urlParams.set(key, value));
    return urlParams.toString();
  }

  constructor(params: TNavigatorParams) {
    this.locale = params.locale;
    this.history = params.history;
  }

  protected readonly generateLocation = (params: TNavigationParam) => {
    const url = new URL(window.location.href);

    url.pathname = !_.isEmpty(params.route)
      ? AbsNavigator.routePathname(params.route, params.locale ?? this.locale)
      : AbsNavigator.makeBasePath(params.path as string, params.locale ?? this.locale);

    url.hash = params.hash ?? url.hash;

    url.search = AbsNavigator.makeSearch({
      ...params.search,
      ...urlParamsToObj(url.search),
    });
    return url;
  };
}
