import * as React from 'react';
import { TWizardSalesmanState, useWizardSalesmanState } from './useWizardSalesmanState';
import { deepMerge } from '../../../../../core/src/lib/HelperFunctions';
import * as _ from 'lodash';
import { useSyncedDataRef } from '../../../../../lib-react/src/hooks/useSyncedDataRef';
import { ELocale } from '../../../../../core/src/locale/Locale';
import { useLocale } from '../../../locale/useLocale';

type TUseWizardSalesmanErrorsParams = {
  mapStateToErrors?: (state: Partial<TWizardSalesmanState>, locale: ELocale) => Partial<TWizardSalesmanState>;
};

export function useWizardSalesmanErrors(params: TUseWizardSalesmanErrorsParams) {
  const locale = useLocale();
  const [globalError, setGlobalError] = React.useState<string | undefined>('');

  const [errors, _setErrors] = React.useState<Partial<TWizardSalesmanState>>({});

  const setErrors = React.useCallback((partial: Partial<TWizardSalesmanState>) => {
    _setErrors((errs) => deepMerge(errs, partial));
  }, [_setErrors]);

  const hasError = [globalError, ..._.values(errors)].some((err) => {
    return !_.isEmpty(err);
  });

  const { state } = useWizardSalesmanState();

  const mapStateToErrorsRef = useSyncedDataRef(params.mapStateToErrors);
  React.useEffect(() => {
    const mapStateToErrors = mapStateToErrorsRef.current;
    const mappedErrors = (mapStateToErrors && mapStateToErrors(state, locale) || {});
    _setErrors(mappedErrors);
  }, [state, locale, _setErrors, mapStateToErrorsRef]);

  return {
    globalError,
    setGlobalError,
    errors,
    setErrors,
    hasError,
  };
}
