import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './legacy/scss/globals.scss';

import { ScreenHome } from './screens/home/ScreenHome';
import { ScreenPlans } from './screens/plans/ScreenPlans';
import { ScreenProfile } from './screens/profile/ScreenProfile';
import { ScreenLogin } from './screens/login/ScreenLogin';
import { ScreenSignup } from './screens/signup/ScreenSignup';
import { defaultMuiTheme } from './defaultMuiTheme';
import { ScreenSuccess } from './screens/success/ScreenSuccess';
import { AbsNavigator } from './app/navigation/AbsNavigator';
import { ThemeProvider } from '@mui/material/styles';
import { withScreenParameters } from './components/withScreenParameters';
import {
  ScreenLegal,
  ScreenLegalPrivacyPolicy,
  ScreenLegalTermsApp,
  ScreenLegalTermsLanding,
} from './screens/legal/ScreenLegal';
import { SiteMap } from './components/SiteMap';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { RoutesLanding } from '../../core/src/lib/apis/routes/RoutableLanding';
import { ScreenWizardSalesmanStart } from './components/wizard/sales/ScreenWizardSalesmanStart';
import { ScreenWizardSalesmanVenue } from './components/wizard/sales/ScreenWizardSalesmanVenue';
import { ScreenWizardSalesmanInfo } from './components/wizard/sales/ScreenWizardSalesmanInfo';
import { ScreenWizardSalesmanDone } from './components/wizard/sales/ScreenWizardSalesmanDone';
import { BrowserContextsProvider } from '../../lib-react/src/contexts-browser/BrowserContextsProvider';
import { useUserAnalyticsIdentification } from './app/user/useUserIdentification';
import { UserContextProvider } from './app/user/UserContext';
import { ScreenStart } from './screens/home/ScreenStart';

const AppScreenPlans = withScreenParameters(RoutesLanding.Plans.name, ScreenPlans);
const AppScreenProfile = withScreenParameters(RoutesLanding.Profile.name, ScreenProfile);
const AppScreenLogin = withScreenParameters(RoutesLanding.Login.name, ScreenLogin);
const AppScreenSignup = withScreenParameters(RoutesLanding.Signup.name, ScreenSignup);
const AppScreenLegal = withScreenParameters(RoutesLanding.Legal.name, ScreenLegal);
const AppScreenLegalTermsApp = withScreenParameters(RoutesLanding.LegalTermsApp.name, ScreenLegalTermsApp);
const AppScreenLegalTermsLanding = withScreenParameters(RoutesLanding.LegalTermsLanding.name, ScreenLegalTermsLanding);
const AppScreenLegalPrivacyPolicy = withScreenParameters(RoutesLanding.LegalPrivacyPolicy.name, ScreenLegalPrivacyPolicy);

const AppWizardSalesStart = withScreenParameters(RoutesLanding.WizardSalesStart.name, ScreenWizardSalesmanStart);
const AppWizardSalesVenue = withScreenParameters(RoutesLanding.WizardSalesVenue.name, ScreenWizardSalesmanVenue);
const AppWizardSalesInfo = withScreenParameters(RoutesLanding.WizardSalesInfo.name, ScreenWizardSalesmanInfo);
const AppWizardSalesDone = withScreenParameters(RoutesLanding.WizardSalesDone.name, ScreenWizardSalesmanDone);

const AppScreenSuccess = withScreenParameters(RoutesLanding.Success.name, ScreenSuccess);
const AppScreenHome = withScreenParameters(RoutesLanding.Home.name, ScreenHome);
const AppScreenStart = withScreenParameters(RoutesLanding.Start.name, ScreenStart);

export const App = () => {
  return (
    <ThemeProvider theme={defaultMuiTheme}>
      <BrowserContextsProvider window={window}>
        <BrowserRouter>
          <UserContextProvider>
            <ConnectedApp/>
            <SiteMap/>
          </UserContextProvider>
        </BrowserRouter>
      </BrowserContextsProvider>
    </ThemeProvider>
  );
};

export const ConnectedApp = () => {
  useUserAnalyticsIdentification();
  return (
    <Switch>
      <Route path={AbsNavigator.allPaths(RoutesLanding.Plans.name)} component={AppScreenPlans}/>
      <Route path={AbsNavigator.allPaths(RoutesLanding.Profile.name)} component={AppScreenProfile}/>
      <Route path={AbsNavigator.allPaths(RoutesLanding.Login.name)} component={AppScreenLogin}/>
      <Route path={AbsNavigator.allPaths(RoutesLanding.Signup.name)} component={AppScreenSignup}/>
      <Route path={AbsNavigator.allPaths(RoutesLanding.LegalTermsApp.name)}
        component={AppScreenLegalTermsApp}/>
      <Route path={AbsNavigator.allPaths(RoutesLanding.LegalTermsLanding.name)}
        component={AppScreenLegalTermsLanding}/>
      <Route path={AbsNavigator.allPaths(RoutesLanding.LegalPrivacyPolicy.name)}
        component={AppScreenLegalPrivacyPolicy}/>
      <Route path={AbsNavigator.allPaths(RoutesLanding.Legal.name)} component={AppScreenLegal}/>

      <Route path={AbsNavigator.allPaths(RoutesLanding.WizardSalesDone.name)} component={AppWizardSalesDone}/>
      <Route path={AbsNavigator.allPaths(RoutesLanding.WizardSalesInfo.name)} component={AppWizardSalesInfo}/>
      <Route path={AbsNavigator.allPaths(RoutesLanding.WizardSalesStart.name)}
        component={AppWizardSalesStart}/>
      <Route path={AbsNavigator.allPaths(RoutesLanding.WizardSalesVenue.name)}
        component={AppWizardSalesVenue}/>

      <Route path={AbsNavigator.allPaths(RoutesLanding.Success.name)} component={AppScreenSuccess}/>
      <Route path={AbsNavigator.allPaths(RoutesLanding.Home.name)} component={AppScreenHome}/>
      <Route path={AbsNavigator.allPaths(RoutesLanding.Start.name)} component={AppScreenStart}/>
    </Switch>
  );
};
