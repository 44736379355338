import * as React from 'react';

import { useGetLocalizedString } from '../../locale/useLocale';
import styled from 'styled-components';

type TLocalizedImg = {
  k: string | undefined;
  className?: string;
};

export const LocalizedImg = ({
  k,
  className,
}: TLocalizedImg) => {
  const getLocalizedString = useGetLocalizedString();
  if (!k) {
    return null;
  }

  const val = getLocalizedString(k);
  if (!val) {
    return (
      <StyledImg
        className={className}
        alt={k}
        src={k}
      />
    );
  }

  return (
    <StyledImg
      className={className}
      alt={k}
      src={val}
    />
  );
};

const StyledImg = styled.img`

`;
