import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../config/Colors';

type THeaderButtonProps = {
  title: React.ReactNode;

};

export const HeaderLink = ({ title }: THeaderButtonProps) => (
  <Root>
    {title}
  </Root>
);

const Root = styled.div`
  color: ${Colors.accentDark};
  cursor: pointer;
  font-size: 11px;
  font-weight: 800;
  letter-spacing: 0.5px;
  line-height: 15px;
  margin-top: 4px;
`;
