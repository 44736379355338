import * as React from 'react';
import styled from 'styled-components';
import { Footer } from './Footer';
import { Header } from './header/Header';

export type TScreenFrameProps = {
  id: string;
  children: React.ReactNode;
  showHeader?: boolean;
};

export const ScreenFrame = ({
  id,
  children,
  showHeader = true,
}: TScreenFrameProps) => {
  return (
    <Root id={id}>
      {showHeader && (
        <Header/>
      )}
      <ContentContainer>
        <Content>
          {children}
        </Content>
      </ContentContainer>
      <FooterSpacer>
        <Footer/>
      </FooterSpacer>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const Content = styled.div`
  padding-bottom: 120px;
`;

const FooterSpacer = styled.div`
  align-items: flex-end;
  display: flex;
`;
