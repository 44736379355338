export const AppAlacarteRoutes = {
  status: {
    method: 'get' as const,
    route: '/status',
    pathParams: undefined,
    params: undefined,
    body: undefined,
    response: { version: null as any as string },
  },

  contactEmail: {
    method: 'post' as const,
    route: '/contact',
    pathParams: undefined,
    params: null as any,
    body: {
      name: null as any as string,
      hostName: null as any as string,
      phone: null as any as string,
      email: null as any as string,
    },
    response: {},
  },

  handleUserPasswordReset: {
    method: 'post' as const,
    route: '/user-password-reset',
    pathParams: undefined,
    params: null as any,
    body: {
      email: null as any as string,
      redirectUrl: null as any as string,
    },
    response: {},
  },

  wizardSalesmanComplete: {
    method: 'post' as const,
    route: '/wizard-salesman-complete',
    pathParams: undefined,
    params: null as any,
    body: {
      coupon: null as any as string,
      email: null as any as string,
      name: null as any as (string | undefined),
      surname: null as any as (string | undefined),
      hostName: null as any as string,
      hostCoordinates: null as any as (string | undefined),
      hostPlaceId: null as any as (string | undefined),
      hostAddressLine1: null as any as (string | undefined),
      hostPhone: null as any as (string | undefined),
      hostCountry: null as any as (string | undefined),
      hostState: null as any as (string | undefined),
      hostPostalCode: null as any as (string | undefined),
      hostCity: null as any as (string | undefined),
      mainLocale: null as any as (('it-IT' | 'en-US' | 'es-ES') | undefined),
      stripeCoupon: null as any as (string | undefined),
    },
    response: {},
  },

  hostQrCreate: {
    method: 'post' as const,
    useBearerAuth: true,
    params: null as any,
    route: `/host-qr`,
    pathParams: undefined,
    body: null as any as {
      tableId?: string;
      tableNumber?: string;
      skipGuestsCheck?: string;
    }[],
    response: null as any as {
      _id?: string;
      content: string;
      qrContent: string;
      qr: string;
    }[],
  },

  stripeCustomerPortalSessionCreate: {
    method: 'post' as const,
    useBearerAuth: true,
    params: null as any,
    route: '/stripe-customer-portal-session-create',
    pathParams: undefined,
    body: { redirectUrl: null as any as string },
    response: { redirect: null as any as string },
  },

  stripeCheckoutSessionCreate: {
    method: 'post' as const,
    useBearerAuth: true,
    params: null as any,
    route: '/stripe-checkout-session-create',
    pathParams: undefined,
    body: {
      successUrl: null as any as string,
      cancelUrl: null as any as string,
      stripeCheckoutPriceId: null as any as string,
    },
    response: { redirect: null as any as string },
  },

  userVerifyEmail: {
    method: 'post' as const,
    useBearerAuth: true,
    credentials: 'include' as const,
    params: null as any,
    route: '/user-verify-email',
    pathParams: undefined,
    body: { verifyEmailRedirectUrl: null as any as string },
    response: {},
  },

  hostCreate: {
    method: 'post' as const,
    params: undefined,
    route: '/user',
    pathParams: undefined,
    body: {
      name: null as any as string,
      surname: null as any as string,
      email: null as any as string,
      password: null as any as string,
      hostName: null as any as string,
      hostDescription: null as any as string,
      hostPhone: null as any as string,
      hostVatId: null as any as string,
      hostDenomination: null as any as string,
      hostCountry: null as any as string,
      hostState: null as any as string,
      hostPostalCode: null as any as string,
      hostCity: null as any as string,
      hostAddressLine1: null as any as string,
      hostAddressLine2: null as any as string,
      hostPlaceId: null as any as string,
      hostCoordinates: null as any as string,
      verifyEmailRedirectUrl: null as any as string,
      mainLocale: null as any as (('it-IT' | 'en-US' | 'es-ES') | undefined),
      stripeCoupon: null as any as (string | undefined),
    },
    response: {
      firebaseUid: null as any as string,
      firebaseEmailVerified: null as any as string,
      firebaseDisabled: null as any as string,
      firebaseDisplayName: null as any as string,
      firebaseEmail: null as any as string,
      hostId: null as any as string,
      hostAddress: null as any as string,
      hostCoordinates: null as any as string,
      hostDenomination: null as any as string,
      hostDescription: null as any as string,
      hostEmail: null as any as string,
      hostName: null as any as string,
      hostPhone: null as any as string,
      hostPlaceId: null as any as string,
      hostVatId: null as any as string,
      stripeCustomerAddressCity: null as any as string,
      stripeCustomerAddressCountry: null as any as string,
      stripeCustomerAddressState: null as any as string,
      stripeCustomerAddressLine1: null as any as string,
      stripeCustomerAddressLine2: null as any as string,
      stripeCustomerAddressPostalCode: null as any as string,
      stripeCustomerDeleted: null as any as string,
      stripeCustomerDescription: null as any as string,
      stripeCustomerEmail: null as any as string,
      stripeCustomerId: null as any as string,
      stripeCustomerIntentId: null as any as string,
      stripeCustomerIntentDescription: null as any as string,
      hostSubscriptionStartedOn: null as any as string,
      hostSubscriptionEndsOn: null as any as string,
      hostSubscriptionId: null as any as string,
    },
  },

  hostDelete: {
    method: 'delete' as const,
    params: undefined,
    route: '/user/:uid',
    pathParams: { uid: '' as string },
    body: {},
    response: [] as Array<{
      op: string;
      err?: string | null;
    }>,
  },

  adminUserPatch: {
    method: 'patch' as const,
    params: undefined,
    route: '/admin/user/:uid',
    pathParams: { uid: '' as string },
    body: { customClaims: null as any as Record<string, any> },
    response: { uid: '' as string },
  },
};
