import { Validators } from '../../lib/validators/Validators';

export const FormDefContact = {
  name: {
    name: 'name' as const,
    defaultValue: '',
    validation: {
      ...Validators.makeRequired({ errorString: 'landingFormFieldRequired' }),
      ...Validators.makeMinLength({
        value: 3,
        errorString: 'landingFormValidationErrorMinlen',
      }),
      ...Validators.makeMaxLength({
        value: 40,
        errorString: 'landingFormValidationErrorMaxlen',
      }),
    },
  },
  hostName: {
    name: 'hostName' as const,
    defaultValue: '',
    validation: {
      ...Validators.makeRequired({ errorString: 'landingFormFieldRequired' }),
      ...Validators.makeMinLength({
        value: 3,
        errorString: 'landingFormValidationErrorMinlen',
      }),
      ...Validators.makeMaxLength({
        value: 200,
        errorString: 'landingFormValidationErrorMaxlen',
      }),
    },
  },
  email: {
    name: 'email' as const,
    defaultValue: '',
    validation: {
      ...Validators.makeRequired({ errorString: 'landingFormFieldRequired' }),
      validateWithForm: {
        valid: Validators.isValidEmail(() => ({
          fieldName: FormDefContact.email.name,
          errorString: 'landingFormValidationErrorEmailinvalid',
        })),
      },
    },
  },
  phone: {
    name: 'phone' as const,
    defaultValue: '',
    validation: {
      validateWithForm: {
        valid: Validators.isValidPhone(() => ({
          fieldName: FormDefContact.phone.name,
          errorString: 'landingFormValidationErrorPhoneinvalid',
        })),
      },
    },
  },
  meta_acceptConditions: {
    name: 'meta_acceptConditions' as const,
    defaultValue: false,
    validation: {
      validateWithForm: {
        accepted: Validators.booleanIsTrue(() => ({
          fieldName: FormDefContact.meta_acceptConditions.name,
          errorString: 'landingFormValidationErrorTacnotaccepted',
        })),
      },
    },
  },
  meta_globalError: {
    name: 'meta_globalError' as const,
    defaultValue: null,
    validation: {},
  },
};
