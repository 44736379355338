import * as React from 'react';

import styled from 'styled-components';
import { Str } from '../../components/localized/Str';
import { ScreenHeader } from '../../components/ScreenHeader';
import { ScreenFrame } from '../../components/ScreenFrame';

type TLegalDocumentFrameProps = {
  id: string;
  titleKey: string;
  descriptionKey: string;
  contentKey: string;
};

export const LegalDocumentFrame = ({
  id,
  titleKey,
  descriptionKey,
  contentKey,
}: TLegalDocumentFrameProps) => {
  return (
    <ScreenFrame id={id}>
      <ScreenHeader
        title={<Str k={titleKey}/>}
        description={<Str k={descriptionKey}/>}
      />
      <ContentRoot>
        <Content>
          <StyledString k={contentKey}/>
        </Content>
      </ContentRoot>
    </ScreenFrame>
  );
};

const ContentRoot = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  all: initial;
  padding: 0 5%;
`;

const StyledString = styled(Str)`
  .__terms html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
    margin: revert;
    margin-block: revert;
  }
`;
