import * as React from 'react';
import { useGetLocalizedString } from '../../locale/useLocale';

type TSectionData = {
  title: string;
  content: string;
  mbl: {
    src: string;
  };
  dsk: {
    src: string;
  };
};

type TSectionInfoData = {
  info: {
    restaurant: {
      list: TSectionData[];
    };
    customer: {
      list: TSectionData[];
    };
  };
};

export function useGetInfoSectionData(): TSectionInfoData {
  const getLocalizedString = useGetLocalizedString();
  return React.useMemo(() => ({
    info: {
      customer: {
        list: [
          {
            title: getLocalizedString('landingInfoCustomerList0Title'),
            content: getLocalizedString('landingInfoCustomerList0Content'),
            mbl: { src: getLocalizedString('landingAssetHomeInfoCustomerList0Mbl') },
            dsk: { src: getLocalizedString('landingAssetHomeInfoCustomerList0Dsk') },
          },
          {
            title: getLocalizedString('landingInfoCustomerList1Title'),
            content: getLocalizedString('landingInfoCustomerList1Content'),
            mbl: { src: getLocalizedString('landingAssetHomeInfoCustomerList1Mbl') },
            dsk: { src: getLocalizedString('landingAssetHomeInfoCustomerList1Dsk') },
          },
          {
            title: getLocalizedString('landingInfoCustomerList2Title'),
            content: getLocalizedString('landingInfoCustomerList2Content'),
            mbl: { src: getLocalizedString('landingAssetHomeInfoCustomerList2Mbl') },
            dsk: { src: getLocalizedString('landingAssetHomeInfoCustomerList2Dsk') },
          },
          {
            title: getLocalizedString('landingInfoCustomerList3Title'),
            content: getLocalizedString('landingInfoCustomerList3Content'),
            mbl: { src: getLocalizedString('landingAssetHomeInfoCustomerList3Mbl') },
            dsk: { src: getLocalizedString('landingAssetHomeInfoCustomerList3Dsk') },
          },
        ],
      },
      restaurant: {
        list: [
          {
            title: getLocalizedString('landingInfoRestaurantList0Title'),
            content: getLocalizedString('landingInfoRestaurantList0Content'),
            mbl: { src: getLocalizedString('landingAssetHomeInfoRestaurantList0Mbl') },
            dsk: { src: getLocalizedString('landingAssetHomeInfoRestaurantList0Dsk') },
          },
          {
            title: getLocalizedString('landingInfoRestaurantList1Title'),
            content: getLocalizedString('landingInfoRestaurantList1Content'),
            mbl: { src: getLocalizedString('landingAssetHomeInfoRestaurantList1Mbl') },
            dsk: { src: getLocalizedString('landingAssetHomeInfoRestaurantList1Dsk') },
          },
          {
            title: getLocalizedString('landingInfoRestaurantList2Title'),
            content: getLocalizedString('landingInfoRestaurantList2Content'),
            mbl: { src: getLocalizedString('landingAssetHomeInfoRestaurantList2Mbl') },
            dsk: { src: getLocalizedString('landingAssetHomeInfoRestaurantList2Dsk') },
          },
          {
            title: getLocalizedString('landingInfoRestaurantList3Title'),
            content: getLocalizedString('landingInfoRestaurantList3Content'),
            mbl: { src: getLocalizedString('landingAssetHomeInfoRestaurantList3Mbl') },
            dsk: { src: getLocalizedString('landingAssetHomeInfoRestaurantList3Dsk') },
          },
          {
            title: getLocalizedString('landingInfoRestaurantList4Title'),
            content: getLocalizedString('landingInfoRestaurantList4Content'),
            mbl: { src: getLocalizedString('landingAssetHomeInfoRestaurantList4Mbl') },
            dsk: { src: getLocalizedString('landingAssetHomeInfoRestaurantList4Dsk') },
          },
        ],
      },
    },
  }), [getLocalizedString]);
}
