import { useForm } from 'react-hook-form';
import { FormDefContact } from './FormDefContact';
import { TFormValueTypes } from '../form/useForm';
import { useFormContactOnSubmit } from './useFormContactOnSubmit';

export type TFormContact = ReturnType<typeof useFormOfContactType>;

export function useFormOfContactType() {
  return useForm<TFormValueTypes<typeof FormDefContact>>();
}

export function useFormContact() {
  const form = useFormOfContactType();
  const onSubmit = useFormContactOnSubmit(form);
  return {
    form,
    onSubmit,
  };
}
