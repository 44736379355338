import * as React from 'react';
import { User } from '../../app/user/User';
import { Colors } from '../../config/Colors';
import styled from 'styled-components';
import { fixedHeight, Row } from '../../components/css';
import { useNavigateToHost } from '../../app/navigation/useNavigateToHost';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';
import { ScreenFrame } from '../../components/ScreenFrame';
import { ScreenHeader } from '../../components/ScreenHeader';
import { Str } from '../../components/localized/Str';
import { ProfileBoxHostSubscription } from './ProfileBoxHostSubscription';
import { ProfileBoxSimpleAction } from './ProfileBoxSimpleAction';
import { ProfileBoxGenerateQrs } from './ProfileBoxGenerateQrs';
import { ProfileBoxPrinterDownload } from './ProfileBoxPrinterDownload';
import { Strings } from '../../../../core/src/locale/Strings';
import { UserContext } from '../../app/user/UserContext';

export const ScreenProfile = () => {
  const { hostPublicSubscription } = React.useContext(UserContext);
  const navigateToHost = useNavigateToHost();
  return (
    <ScreenFrame id={RoutesLanding.Profile.hashes.top}>
      <ScreenHeader
        title={<Str k={Strings.keys.landingScreenProfileTitle}/>}
      />
      <ProfileRow>
        <ProfileBoxHostSubscription/>
        {User.hasActiveSubscription(hostPublicSubscription) && (
          <ProfileBoxSimpleAction
            contentTitle={<Str k={Strings.keys.landingProfileHostTitle}/>}
            themeColor={Colors.aLaCarteTheme}
            negativeThemeColor={Colors.accentDark}
            content={(
              <BoxImageContainer>
                <BoxImage130 src="/assets/svg/logo-vertical.svg"/>
              </BoxImageContainer>
            )}
            ctaText={<Str k={Strings.keys.landingProfileHostTitle}/>}
            ctaOnClick={navigateToHost}
          />
        )}
        {User.hasActiveSubscription(hostPublicSubscription) && (
          <ProfileBoxGenerateQrs/>
        )}
        {User.hasActiveSubscription(hostPublicSubscription) && (
          <ProfileBoxPrinterDownload/>
        )}
      </ProfileRow>
    </ScreenFrame>
  );
};

const ProfileRow = styled(Row)`
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const BoxImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`;

const BoxImage130 = styled.img`
  ${fixedHeight(130)};
`;
