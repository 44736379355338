import { TPlacesResponse } from './GooglePlacesModule';
import * as React from 'react';
import { GoogleMapsModule } from './GoogleMapsModule';
import { googleMapsLoad } from './googleMapsLoad';
import { useSyncedDataRef } from '../../../../lib-react/src/hooks/useSyncedDataRef';

type TUseGooglePlacesPickerParams = {
  mapDivId: string;
  onSinglePlacePick: (obj: TPlacesResponse) => void;
};

export function useGooglePlacesPicker({
  mapDivId,
  onSinglePlacePick,
}: TUseGooglePlacesPickerParams) {
  const onSinglePlacePickRef = useSyncedDataRef(onSinglePlacePick);
  const googlePlacesModuleRef = React.useRef<GoogleMapsModule | null>(null);

  const [currentPickerPlaces, setCurrentPickerPlaces] = React.useState<TPlacesResponse[]>([]);
  const setCurrentPickerPlacesRef = useSyncedDataRef(setCurrentPickerPlaces);

  React.useEffect(() => {
    let mapResizeInterval = null;

    async function load() {
      googlePlacesModuleRef.current = await googleMapsLoad({
        mapDivId,
        onSinglePlacePick(obj: TPlacesResponse) {
          onSinglePlacePickRef.current(obj);
        },
        onMultipleSearchResults(objs: TPlacesResponse[]) {
          setCurrentPickerPlacesRef.current(objs);
        },
      });

      // Make sure the map always fits the page
      // @ts-ignore
      mapResizeInterval = setInterval(() => googlePlacesModuleRef.current.resizeMap(), 1000);
    }

    load();
    return () => {
      // @ts-ignore
      clearInterval(mapResizeInterval);
    };
  }, [onSinglePlacePickRef, googlePlacesModuleRef]);

  const onSearchChange = React.useCallback((value: string) => {
    const googlePlacesModule = googlePlacesModuleRef.current;
    googlePlacesModule && googlePlacesModule.onSearch(value);
  }, [googlePlacesModuleRef]);

  const onSelectExternally = React.useCallback((place: TPlacesResponse) => {
    const googlePlacesModule = googlePlacesModuleRef.current;
    googlePlacesModule && googlePlacesModule.selectSinglePlace(place);
  }, [googlePlacesModuleRef]);

  return {
    onSearchChange,
    currentPickerPlaces,
    onSelectExternally,
  };
}
