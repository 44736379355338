import * as React from 'react';

import styled from 'styled-components';
import { MediaMaxWidth } from '../../components/header/HeaderStyle';
import { LocalizedImg } from '../../components/localized/LocalizedImg';
import { Str } from '../../components/localized/Str';
import { Strings } from '../../../../core/src/locale/Strings';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';

type THomeSectionInfoProps = {
  //
};

export const HomeSectionInfo = (props: THomeSectionInfoProps) => {
  return (
    <Root id={RoutesLanding.Home.hashes.info} className="info" data-anchorref="info">
      <div className="info__wrapper u-wrapper">
        <InfoColumn className="info__column">
          <h3 className="u-title">
            <Str k={Strings.keys.landingInfoTitle}/>
          </h3>

          <p>
            <Str k={Strings.keys.landingInfoContent}/>
          </p>
          <div className="info__filters">
            <button className="filter-button filter-button--dark filter-button--active" data-ref="restaurant">
              <Str k={Strings.keys.landingInfoRestaurantButton}/>
            </button>
            <button className="filter-button filter-button--dark" data-ref="customer">
              <Str k={Strings.keys.landingInfoCustomerButton}/>
            </button>
          </div>

          <div className="info__accordions-container">
            <ul className="info__accordions-wrapper info__accordions-wrapper--active" data-list="restaurant">
              <li className="accordion accordion--active">
                <span className="accordion__line"><hr/></span>
                <div className="accordion__head">
                  <h4>
                    <Str k={Strings.keys.landingInfoRestaurantList0Title}/>
                  </h4>
                  <img src="/assets/svg/arrow.svg"/>
                </div>

                <div className="accordion__content">
                  <div className="accordion__wrapper">
                    <p>
                      <Str k={Strings.keys.landingInfoRestaurantList0Content}/>
                    </p>
                    <AccordionImgContainer>
                      <AccordionImg k={'landingAssetHomeInfoRestaurantList0Mbl'}/>
                    </AccordionImgContainer>
                  </div>
                </div>
              </li>

              <li className="accordion">
                <span className="accordion__line"><hr/></span>
                <div className="accordion__head">
                  <h4>
                    <Str k={Strings.keys.landingInfoRestaurantList1Title}/>
                  </h4>
                  <img src="/assets/svg/arrow.svg"/>
                </div>

                <div className="accordion__content">
                  <div className="accordion__wrapper">
                    <p>
                      <Str k={Strings.keys.landingInfoRestaurantList1Content}/>
                    </p>
                    <AccordionImgContainer>
                      <AccordionImg k={'landingAssetHomeInfoRestaurantList1Mbl'}/>
                    </AccordionImgContainer>
                  </div>
                </div>
              </li>

              <li className="accordion">
                <span className="accordion__line"><hr/></span>
                <div className="accordion__head">
                  <h4>
                    <Str k={Strings.keys.landingInfoRestaurantList2Title}/>
                  </h4>
                  <img src="/assets/svg/arrow.svg"/>
                </div>

                <div className="accordion__content">
                  <div className="accordion__wrapper">
                    <p>
                      <Str k={Strings.keys.landingInfoRestaurantList2Content}/>
                    </p>
                    <AccordionImgContainer>
                      <AccordionImg k={'landingAssetHomeInfoRestaurantList2Mbl'}/>
                    </AccordionImgContainer>
                  </div>
                </div>
              </li>

              <li className="accordion">
                <span className="accordion__line"><hr/></span>
                <div className="accordion__head">
                  <h4>
                    <Str k={Strings.keys.landingInfoRestaurantList3Title}/>
                  </h4>
                  <img src="/assets/svg/arrow.svg"/>
                </div>

                <div className="accordion__content">
                  <div className="accordion__wrapper">
                    <p>
                      <Str k={Strings.keys.landingInfoRestaurantList3Content}/>
                    </p>
                    <AccordionImgContainer>
                      <AccordionImg k={'landingAssetHomeInfoRestaurantList3Mbl'}/>
                    </AccordionImgContainer>
                  </div>
                </div>
              </li>

              <li className="accordion">
                <span className="accordion__line"><hr/></span>
                <div className="accordion__head">
                  <h4>
                    <Str k={Strings.keys.landingInfoRestaurantList4Title}/>
                  </h4>
                  <img src="/assets/svg/arrow.svg"/>
                </div>

                <div className="accordion__content">
                  <div className="accordion__wrapper">
                    <p>
                      <Str k={Strings.keys.landingInfoRestaurantList4Content}/>
                    </p>
                    <AccordionImgContainer>
                      <AccordionImg k={'landingAssetHomeInfoRestaurantList4Mbl'}/>
                    </AccordionImgContainer>
                  </div>
                </div>
              </li>
            </ul>

            <ul className="info__accordions-wrapper" data-list="customer">
              <li className="accordion">
                <span className="accordion__line"><hr/></span>
                <div className="accordion__head">
                  <h4>
                    <Str k={Strings.keys.landingInfoCustomerList0Title}/>
                  </h4>
                  <img src="/assets/svg/arrow.svg"/>
                </div>

                <div className="accordion__content">
                  <div className="accordion__wrapper">
                    <p>
                      <Str k={Strings.keys.landingInfoCustomerList0Content}/>
                    </p>
                    <AccordionImgContainer>
                      <AccordionImg k={'landingAssetHomeInfoCustomerList0Mbl'}/>
                    </AccordionImgContainer>
                  </div>
                </div>
              </li>

              <li className="accordion">
                <span className="accordion__line"><hr/></span>
                <div className="accordion__head">
                  <h4>
                    <Str k={Strings.keys.landingInfoCustomerList1Title}/>
                  </h4>
                  <img src="/assets/svg/arrow.svg"/>
                </div>
                <div className="accordion__content">
                  <div className="accordion__wrapper">
                    <p>
                      <Str k={Strings.keys.landingInfoCustomerList1Content}/>
                    </p>
                    <AccordionImgContainer>
                      <AccordionImg k={'landingAssetHomeInfoCustomerList1Mbl'}/>
                    </AccordionImgContainer>
                  </div>
                </div>
              </li>

              <li className="accordion">
                <span className="accordion__line"><hr/></span>
                <div className="accordion__head">
                  <h4>
                    <Str k={Strings.keys.landingInfoCustomerList2Title}/>
                  </h4>
                  <img src="/assets/svg/arrow.svg"/>
                </div>
                <div className="accordion__content">
                  <div className="accordion__wrapper">
                    <p>
                      <Str k={Strings.keys.landingInfoCustomerList2Content}/>
                    </p>
                    <AccordionImgContainer>
                      <AccordionImg k={'landingAssetHomeInfoCustomerList2Mbl'}/>
                    </AccordionImgContainer>
                  </div>
                </div>
              </li>

              <li className="accordion">
                <span className="accordion__line"><hr/></span>
                <div className="accordion__head">
                  <h4>
                    <Str k={Strings.keys.landingInfoCustomerList3Title}/>
                  </h4>
                  <img src="/assets/svg/arrow.svg"/>
                </div>

                <div className="accordion__content">
                  <div className="accordion__wrapper">
                    <p>
                      <Str k={Strings.keys.landingInfoCustomerList3Content}/>
                    </p>
                    <AccordionImgContainer>
                      <AccordionImg k={'landingAssetHomeInfoCustomerList3Mbl'}/>
                    </AccordionImgContainer>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </InfoColumn>
        <InfoColumnImages className="info__column info__column--picture">
          <InfoImagesTypeCont className="info__item info__item--restaurant">
            <InfoImageWrapHost className="info__picture-cont" data-frame="restaurant">
              <InfoImageContentImg k={'landingAssetHomeInfoRestaurantList0Dsk'}/>
              <InfoImageFrameImg k={'landingAssetHomeInfoRestaurantFrame'}/>
            </InfoImageWrapHost>
          </InfoImagesTypeCont>

          <InfoImagesTypeCont className="info__item info__item--customer">
            <InfoImageWrapGuest className="info__picture-cont" data-frame="customer">
              <InfoImageContentImg k={'landingAssetHomeInfoCustomerList0Dsk'}/>
              <InfoImageFrameImg k={'landingAssetHomeInfoCustomerFrame'}/>
            </InfoImageWrapGuest>
          </InfoImagesTypeCont>
        </InfoColumnImages>
      </div>
    </Root>
  );
};

const Root = styled.div`
`;

const InfoColumn = styled.div`

`;

const InfoColumnImages = styled(InfoColumn)`
  @media ${MediaMaxWidth.sm} {
    display: none;
  }
`;

const InfoImagesTypeCont = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: center;
`;

const InfoImageWrap = styled.div`
  position: relative;

  [data-picture] {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
  }

  [data-frame] {
    position: relative;
    width: 100%;
  }
`;

const InfoImageContentImg = styled(LocalizedImg)`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

const InfoImageFrameImg = styled(LocalizedImg)`
  position: relative;
  width: 100%;
`;

const InfoImageWrapHost = styled(InfoImageWrap)`
  margin-top: 20%;
  width: 100%;

  [data-picture] {
    transform: translate(-50%, -50%);
  }
`;

const InfoImageWrapGuest = styled(InfoImageWrap)`
  margin-top: -100%;
  width: 70%;

  [data-picture] {
    transform: translate(-50%, -50%) scale(1.02);
  }
`;

const AccordionImgContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const AccordionImg = styled(LocalizedImg)`
  max-width: 90vh;
`;
