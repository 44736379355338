import * as React from 'react';
import { Analytics } from '../../lib/analytics/Analytics';
import { useNavigator } from '../navigation/Navigator';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';
import { Firebase } from '../Firebase';

export function useOnHostLogout() {
  const { navigatorRef } = useNavigator();

  return React.useCallback(async () => {
    await Firebase.authLogout();
    // await AppAlacarteApiAccess.sessionLogout.request({});
    await Analytics.reset();

    navigatorRef.current.routeReplace({
      route: RoutesLanding.Login.name,
      hash: RoutesLanding.Login.hashes.top,
      search: {},
    });
  }, [navigatorRef]);
}
