import { Validators } from '../../lib/validators/Validators';

export const FormDefLogin = {
  email: {
    name: 'email' as const,
    defaultValue: '',
    validation: {
      ...Validators.makeRequired({ errorString: 'landingFormFieldRequired' }),
      validateWithForm: {
        valid: Validators.isValidEmail(() => ({
          fieldName: FormDefLogin.email.name,
          errorString: 'landingFormValidationErrorEmailinvalid',
        })),
      },
    },
  },
  password: {
    name: 'password' as const,
    defaultValue: '',
    validation: {
      ...Validators.makeRequired({ errorString: 'landingFormFieldRequired' }),
      ...Validators.makeMinLength({
        value: 6,
        errorString: 'landingFormValidationErrorMinlen',
      }),
      ...Validators.makeMaxLength({
        value: 30,
        errorString: 'landingFormValidationErrorMaxlen',
      }),
    },
  },
  meta_globalError: {
    name: 'meta_globalError' as const,
    defaultValue: null,
    validation: {},
  },
};
