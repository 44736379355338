import { subscriptionTypeToFeatureFlag } from './UserSubscription';
import { enumToArray } from '../../../../core/src/lib/HelperFunctions';
import {
  ESubscriptionFeatureFlags,
  ESubscriptionType,
} from '../../../../core/src/models/db/hostSubscription/HostSubscriptionTypes';
import { THostPublicSubscription } from '../../../../core/src/models/db/HostPublicSubscription/HostPublicSubscriptionTypes';

export class User {
  static hasActiveSubscription(hostPublicSubscription: THostPublicSubscription | undefined): hostPublicSubscription is THostPublicSubscription {
    return hostPublicSubscription != null && hostPublicSubscription.stripeSubscriptionType != null;
  }

  static hasAccessToFeature(hostPublicSubscription: THostPublicSubscription | undefined, feature: ESubscriptionFeatureFlags): boolean {
    if (!User.hasActiveSubscription(hostPublicSubscription)) {
      return false;
    }

    const subscriptionType = hostPublicSubscription.stripeSubscriptionType;
    if (!enumToArray(ESubscriptionType).includes(subscriptionType)) {
      return false;
    }

    const subscriptionFeatures = subscriptionTypeToFeatureFlag[subscriptionType as ESubscriptionType];
    return subscriptionFeatures.includes(feature);
  }
}
