import * as React from 'react';
import { TFormSignup } from './useFormSignup';
import { TPlacesResponse } from '../placesPicker/GooglePlacesModule';
import { setIfPropertyNotEmpty } from '../../../../core/src/lib/HelperFunctions';
import { FormDefSignup } from './FormDefSignup';
import {
  GoogleAddressComponentTypes,
  googleFindAddressComponent,
} from '../placesPicker/GoogleMapsHelpers';

function buildValueSetter(
  form: TFormSignup,
  name: string,
) {
  return function (value: any) {
    form.setValue(name as any, value);
  };
}

export const useFormSignupOnPlacePick = (form: TFormSignup) => {
  return React.useCallback((obj: TPlacesResponse) => {
    setIfPropertyNotEmpty(
      buildValueSetter(form, FormDefSignup.hostCoordinates.name),
      obj?.geometry?.location,
      (location) => `${location.lat()},${location.lng()}`,
    );

    setIfPropertyNotEmpty(
      buildValueSetter(form, FormDefSignup.hostPlaceId.name),
      obj?.place_id,
    );

    setIfPropertyNotEmpty(
      buildValueSetter(form, FormDefSignup.hostAddressLine1.name),
      obj?.formatted_address,
    );

    setIfPropertyNotEmpty(
      buildValueSetter(form, FormDefSignup.hostPhone.name),
      obj?.international_phone_number,
    );

    setIfPropertyNotEmpty(
      buildValueSetter(form, FormDefSignup.hostCountry.name),
      googleFindAddressComponent(obj, GoogleAddressComponentTypes.country),
      (comp) => comp.short_name,
    );

    setIfPropertyNotEmpty(
      buildValueSetter(form, FormDefSignup.hostState.name),
      googleFindAddressComponent(obj, GoogleAddressComponentTypes.administrative_area_level_1),
      (comp) => comp.long_name,
    );

    setIfPropertyNotEmpty(
      buildValueSetter(form, FormDefSignup.hostPostalCode.name),
      googleFindAddressComponent(obj, GoogleAddressComponentTypes.postal_code),
      (comp) => comp.short_name,
    );

    setIfPropertyNotEmpty(
      buildValueSetter(form, FormDefSignup.hostCity.name),
      googleFindAddressComponent(obj, GoogleAddressComponentTypes.administrative_area_level_2),
      (comp) => comp.long_name,
    );
  }, []);
};
