import * as _ from 'lodash';
import {
  TAnyForm,
  TFormFieldDefinition,
} from './useForm';

type TFormatRulesProps = {
  form: TAnyForm;
  definition: TFormFieldDefinition;
};

export function formatRules({
  form,
  definition,
}: TFormatRulesProps) {
  return {
    ...definition.validation,
    validate: {
      ...(function () {
        const def = definition.validation;

        if (!def.validate) {
          return {};
        }

        if (typeof def.validate === 'function') {
          return { validate: def.validate };
        }

        return { ...def.validate };
      }()),

      ...(function () {
        const def = definition.validation;

        function buildValidateWithForm(validator: (f, fieldValue) => boolean) {
          return function (fieldValue) {
            return validator(form, fieldValue);
          };
        }

        if (!def.validateWithForm) {
          return {};
        }

        if (typeof def.validateWithForm === 'function') {
          return { validateWithForm: buildValidateWithForm(def.validateWithForm) };
        }

        return {
          ..._.mapValues(def.validateWithForm, (value) => {
            return buildValidateWithForm(value);
          }),
        };
      }()),
    },
  };
}
