import { Loader } from '@googlemaps/js-api-loader';
import { AsyncReturnType } from '../../../../core/src/lib/Types';
import { GoogleMapsMarkerModule, mKey } from './GoogleMapsMarkerModule';
import { GooglePlacesModule, TPlacesResponse } from './GooglePlacesModule';
import * as _ from 'lodash';

type TGooglePlacesModuleParams = {
  loader: Loader;
  google: AsyncReturnType<any>;
  map: google.maps.Map;
  onSinglePlacePick: (obj: TPlacesResponse) => void;
  onMultipleSearchResults: (obj: TPlacesResponse[]) => void;
};

export class GoogleMapsModule {
  readonly loader: Loader;

  readonly google: AsyncReturnType<any>;

  readonly map: google.maps.Map;

  readonly onSinglePlacePick: (obj: TPlacesResponse) => void;

  readonly _onMultipleSearchResults: (obj: TPlacesResponse[]) => void;

  readonly markerModule: GoogleMapsMarkerModule;

  readonly placesModule: GooglePlacesModule;

  constructor(params: TGooglePlacesModuleParams) {
    this.loader = params.loader;
    this.google = params.google;
    this.map = params.map;
    this.onSinglePlacePick = params.onSinglePlacePick;
    this._onMultipleSearchResults = params.onMultipleSearchResults;

    this.placesModule = new GooglePlacesModule({ placesModule: this });

    this.markerModule = new GoogleMapsMarkerModule({
      placesModule: this,
      onSingleMarkerSelected: this.onSingleMarkerSelected,
    });

    // Place initial marker in center
    const center = this.map.getCenter();
    if (center) {
      this.markerModule.placeMarkers([center]);
    }

    // When map is clicked, place a marker
    this.map.addListener('click', (event) => {
      this.markerModule.setMarker(event.latLng);
    });
  }

  readonly resizeMap = () => {
    google.maps.event.trigger(this.map, 'resize');
  };

  readonly onSearch = _.throttle(async (query: string) => {
    const results = await this.placesModule.onSearch(query);

    const positions = results
      .map((result) => result?.geometry?.location)
      .filter((result): result is google.maps.LatLng => result != null);

    const searchResults = positions
      .map((position) => this.placesModule.searchResults[mKey(position)])
      .filter((searchResult): searchResult is google.maps.places.PlaceResult => searchResult != null);
    this._onMultipleSearchResults(searchResults);

    this.markerModule.placeMarkers(positions);
  }, 1000);

  readonly onSingleMarkerSelected = (marker: google.maps.Marker) => {
    const position = marker.getPosition();
    if (position) {
      const searchResult = this.placesModule.searchResults[mKey(position)];
      if (searchResult) {
        this.onSinglePlacePick(searchResult);
      }
    }
  };

  readonly selectSinglePlace = (place: TPlacesResponse) => {
    const location = this.placesModule.reversedSearchResults[place.place_id as string];
    this.markerModule.placeMarkers([location]);
  };
}
