import * as React from 'react';
import { Colors } from '../../config/Colors';
import styled from 'styled-components';
import { fixedHeight } from '../../components/css';
import {
  SvgLogoApple,
  SvgLogoLinux,
  SvgLogoWindows,
} from '../../assets/Assets';
import { ProfileBox } from './ProfileBox';
import { BoxImageContainer } from './ScreenProfile';
import { Str } from '../../components/localized/Str';
import { AppStoreLinks } from '../../../../core/src/lib/AppStoreLinks';
import { Strings } from '../../../../core/src/locale/Strings';

export const ProfileBoxPrinterDownload = () => {
  return (
    <ProfileBox
      contentTitle={<Str k={Strings.keys.landingProfilePrinterTitle}/>}
      themeColor={Colors.accentLight}
      content={(
        <BoxImageContainer>
          <BoxImage60 src="/assets/svg/stampa.svg"/>
        </BoxImageContainer>
      )}
      contentDescription={<Str k={Strings.keys.landingProfilePrinterDescription}/>}
      action={(
        <ActionContainer>
          <ActionLink target='_blank' href={AppStoreLinks.chmMacLink()}><DownloadLogo src={SvgLogoApple}/></ActionLink>
          <ActionLink target='_blank' href={AppStoreLinks.chmWindowsLink()}><DownloadLogo src={SvgLogoWindows}/></ActionLink>
          <ActionLink target='_blank' href={AppStoreLinks.chmLinuxLink()}><DownloadLogo src={SvgLogoLinux}/></ActionLink>
        </ActionContainer>
      )}
    />
  );
};

const ActionContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const BoxImage60 = styled.img`
  ${fixedHeight(60)};
`;

const DownloadLogo = styled.img`
  width: 40px;
`;

const ActionLink = styled.a`
`;
