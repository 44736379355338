import { GoogleMapsModule } from './GoogleMapsModule';

// markerKey = lat,lng;
export type TMarkerKey = string;

type TGoogleMapsMarkerModuleParams = {
  placesModule: GoogleMapsModule;
  onSingleMarkerSelected: (marker: google.maps.Marker) => void;
};

export class GoogleMapsMarkerModule {
  private readonly placesModule: GoogleMapsModule;

  private readonly onSingleMarkerSelected: (marker: google.maps.Marker) => void;

  private readonly markers = {} as Record<TMarkerKey, google.maps.Marker>;

  constructor(params: TGoogleMapsMarkerModuleParams) {
    this.placesModule = params.placesModule;
    this.onSingleMarkerSelected = params.onSingleMarkerSelected;
  }

  readonly placeMarkers = (positions: google.maps.LatLng[]) => {
    const newMarkerKeys = positions.map(mKey);

    // Delete old markers
    Object.values(this.markers).forEach((currentMarker) => {
      const currentMarkerPosition = currentMarker.getPosition();
      if (!currentMarkerPosition) {
        return;
      }
      const currentMarketKey = mKey(currentMarkerPosition);
      if (!newMarkerKeys.includes(currentMarketKey)) {
        this.clearMarker(currentMarkerPosition);
      }
    });

    // Set new markers
    positions.forEach((position) => {
      const newMarkerKey = mKey(position);
      if (this.markers[newMarkerKey] != null) {
        // Marker already exists
        return;
      }

      // Save new marker
      this.markers[newMarkerKey] = new google.maps.Marker({
        position,
        map: this.placesModule.map,
        draggable: true,
      });
    });

    // Notify one marker selected
    this.checkSingleMarkerSelected();

    // Center markers
    this.centerMarkers();
  };

  readonly setMarker = (position: google.maps.LatLng) => {
    this.clearAllMarkers();
    this.placeMarkers([position]);
  };

  readonly clearMarker = (position: google.maps.LatLng) => {
    const markerKey = mKey(position);
    this.markers[markerKey] && this.markers[markerKey].setMap(null);
    delete this.markers[markerKey];
  };

  readonly clearAllMarkers = () => {
    Object.values(this.markers).forEach((marker) => {
      const position = marker.getPosition();
      position && this.clearMarker(position);
    });
  };

  readonly centerMarkers = () => {
    const markerArr = Object.values(this.markers);

    // Fit 1 marker
    if (markerArr.length === 1) {
      const position = markerArr[0].getPosition();
      if (position) {
        this.placesModule.map.setCenter(position);
        this.placesModule.map.setZoom(15);
      }
      return;
    }

    // Fit distant markers
    const bounds = new google.maps.LatLngBounds();
    markerArr.forEach((marker) => {
      const position = marker.getPosition();
      position && bounds.extend(position);
    });
    this.placesModule.map.fitBounds(bounds);
  };

  private readonly checkSingleMarkerSelected = () => {
    const markerKeys = Object.keys(this.markers);
    if (markerKeys.length === 1) {
      this.onSingleMarkerSelected(this.markers[markerKeys[0]]);
    }
  };
}

export function mKey(position: google.maps.LatLng): TMarkerKey {
  const lat = typeof position.lat == 'function'
    ? position.lat()
    : position.lat;

  const lng = typeof position.lng == 'function'
    ? position.lng()
    : position.lng;

  return `${lat},${lng}`;
}
