import * as React from 'react';
import { StyledHotKeys } from '../../StyledHotKeys';
import { WizardScreen } from '../WizardScreen';
import { Str } from '../../localized/Str';
import { Strings } from '../../../../../core/src/locale/Strings';
import { WizardTextField } from '../WizardTextField';
import { useFormFocusController } from '../../../../../lib-react/src/hooks/useFormFocusController';
import { Colors } from '../../../config/Colors';
import { TWizardSalesmanState, useWizardSalesmanState } from './useWizardSalesmanState';
import { RoutesLanding } from '../../../../../core/src/lib/apis/routes/RoutableLanding';
import { useWizardSalesmanNavigator } from './useWizardSalesmanNavigator';
import { useWizardSalesmanErrors } from './useWizardSalesmanErrors';
import { DropdownList } from '../../DropdownList';
import { TWithWindowDimensionsProps, withWindowDimensions } from '../../useWindowDimensions';
import styled from 'styled-components';
import { useWizardSalesmanMapPicker } from './useWizardSalesmanMapPicker';
import { useWizardSalesmanAnalyticsTracker } from './useWizardSalesmanAnalyticsTracker';
import { WizardTheme } from './WizardTheme';
import { ELocale } from '../../../../../core/src/locale/Locale';

export const ScreenWizardSalesmanVenue = () => {
  useWizardSalesmanAnalyticsTracker();

  const {
    onGeneralFocus,
    getInputRef,
  } = useFormFocusController();

  const { state } = useWizardSalesmanState();

  const {
    mapDivId,
    search,
    setSearch,
    currentPickerPlaces,
    onSelectExternally,
    onSearchChange,
  } = useWizardSalesmanMapPicker();

  const navigateToInfoScreen = useWizardSalesmanNavigator(RoutesLanding.WizardSalesInfo.name);

  const errors = useWizardSalesmanErrors({
    mapStateToErrors(st: TWizardSalesmanState, locale: ELocale) {
      return {};
    },
  });

  return (
    <StyledHotKeys
      keyMap={{ ENTER: 'Enter' }}
      handlers={{ ENTER: onGeneralFocus }}
    >
      <WizardScreen
        theme={WizardTheme}
        title={<Str k={Strings.keys.landingWizardSalesVenueTitle}/>}
        globalError={errors.globalError}
        content={(
          <VenueRoot>
            <VenueCurrentName>
              {state.hostName}
            </VenueCurrentName>
            <VenueMap id={mapDivId}/>
            <VenueSearch>
              <DropdownList
                backgroundColor={WizardTheme.colorBackground}
                color={WizardTheme.colorPrimary}
                pickables={currentPickerPlaces}
                onPick={(place) => {
                  onSelectExternally(place);
                  setSearch(`${place.name} - ${place.formatted_address}`);
                }}
                pickableToString={(picker) => `${picker.name} - ${picker.formatted_address}`}
                renderAnchor={(setFocused) => (
                  <WizardTextField
                    theme={WizardTheme}
                    variant="standard"
                    label={<Str k={Strings.keys.landingWizardSalesVenueSearchLabel}/>}
                    value={search}
                    setValue={onSearchChange}
                    error={errors.errors.hostPlaceId}
                    setFocused={setFocused}
                    inputRef={getInputRef({
                      tabIndex: 0,
                      clickNext: true,
                    })}
                  />
                )}
              />
            </VenueSearch>
          </VenueRoot>
        )}
        onPositive={!errors.hasError ? navigateToInfoScreen : undefined}
        positiveText={<Str k={Strings.keys.landingWizardSalesVenuePositive}/>}
        onNegative={navigateToInfoScreen}
        negativeText={<Str k={Strings.keys.landingWizardSalesVenueNegative}/>}
        positiveElementRef={getInputRef({
          tabIndex: 1,
          clickThis: true,
          focusNext: true,
          clickThisOnGeneralFocus: true,
        })}
      />
    </StyledHotKeys>
  );
};

const VenueRoot = withWindowDimensions(styled.div<TWithWindowDimensionsProps<any>>`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: ${({ dimensions }) => dimensions.height / 2}px;
`);

const VenueCurrentName = styled.div`
  color: ${Colors.red};
  font-size: 0.8rem;
  text-align: left;
`;

const VenueMap = styled.div`
  display: flex;
  flex: 1;
  margin-top: 12px;
`;

const VenueSearch = styled.div`
  margin-top: 20px;
`;
