import * as React from 'react';
import { TawkTo } from '../lib/TawkTo';

export function useEnsureTawkNotExpanded() {
  React.useEffect(() => {
    (async () => {
      await TawkTo.waitForLoaded();
      await TawkTo.hideWidget();
    })();
  }, []);
}
