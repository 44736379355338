import * as React from 'react';
import * as _ from 'lodash';
import { formatRules } from './formatRules';
import { Controller } from 'react-hook-form';
import { MuiCheckbox } from '../Mui';
import {
  fieldIsRequired,
  TAnyForm,
  TFormFieldDefinition,
} from './useForm';
import { Str } from '../localized/Str';

type TFormTextFieldProps<Name = any> = {
  form: TAnyForm;
  definition: TFormFieldDefinition<Name, boolean>;
  label: React.ReactNode;
  helperText?: React.ReactNode;
  inputRef?: React.MutableRefObject<any>;
};

export function FormCheckbox<Name>({
  form,
  definition,
  label,
  helperText,
  inputRef,
}: TFormTextFieldProps<Name>) {
  const fieldName = definition.name as any;
  return (
    <Controller
      defaultValue={definition.defaultValue}
      control={form.control}
      name={fieldName}
      rules={formatRules({
        form,
        definition,
      })}
      render={function ({ field, fieldState }) {
        const [error, setError] = React.useState('');
        React.useEffect(() => setError(fieldState?.error?.message ?? ''), [setError, fieldState.invalid]);
        return (
          <MuiCheckbox
            {...field}
            error={fieldState.invalid}
            required={fieldIsRequired(definition)}
            label={label}
            inputRef={inputRef}
            helperText={_.isEmpty(error)
              ? helperText
              : <Str k={error}/>}
          />
        );
      }}
    />
  );
}
