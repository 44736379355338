import { useWizardSalesmanState } from './useWizardSalesmanState';
import { useNavigator } from '../../../app/navigation/Navigator';
import * as React from 'react';
import { RoutesLanding, TRoutesLandingNames } from '../../../../../core/src/lib/apis/routes/RoutableLanding';

export function useWizardSalesmanNavigator<R extends TRoutesLandingNames>(routeName: R) {
  const { ref } = useWizardSalesmanState();

  const { navigatorRef } = useNavigator();
  return React.useCallback(() => {
    const state = ref.current.urlState.deconstructed.searchParams;
    // @ts-ignore
    navigatorRef.current.routePush({
      route: routeName,
      hash: RoutesLanding[routeName].hashes.top,
      search: {
        name: state.name,
        surname: state.surname,
        email: state.email,
        hostName: state.hostName,
        hostDescription: '',
        hostPhone: state.hostPhone,
        hostVatId: '',
        hostDenomination: state.hostName,
        hostCountry: state.hostCountry,
        hostState: state.hostState,
        hostPostalCode: state.hostPostalCode,
        hostCity: state.hostCity,
        hostAddressLine1: state.hostAddressLine1,
        hostAddressLine2: '',
        hostPlaceId: state.hostPlaceId,
        hostCoordinates: state.hostCoordinates,
      },
    });
  }, [navigatorRef, routeName]);
}
