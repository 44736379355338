import { useForm } from 'react-hook-form';
import { TFormValueTypes } from '../form/useForm';
import { FormDefSignup } from './FormDefSignup';
import { useFormSignupOnSubmit } from './useFormSignupOnSubmit';
import { useFormSignupOnPlacePick } from './useFormSignupOnPlacePick';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';
import { useURLState } from '../../../../lib-react/src/contexts-browser/useURLState';

export type TFormSignup = ReturnType<typeof useFormOfSignupType>;

export function useFormOfSignupType() {
  const urlState = useURLState(RoutesLanding.Signup);
  const initialState = urlState.deconstructed.searchParams;

  return useForm<TFormValueTypes<typeof FormDefSignup>>({
    defaultValues: {
      ...initialState,
      meta_confirmEmail: initialState?.email ?? '',
    },
  });
}

export function useFormSignup() {
  const form = useFormOfSignupType();
  const onSubmit = useFormSignupOnSubmit(form);
  const onPlacePick = useFormSignupOnPlacePick(form);
  return {
    form,
    onSubmit,
    onPlacePick,
  };
}

