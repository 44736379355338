import * as React from 'react';

import {
  TAnyForm,
  useWatchError,
} from './useForm';
import * as _ from 'lodash';
import { ErrorView } from '../ErrorView';

type TFormGlobalErrorProps = {
  form: TAnyForm;
  fieldName: string;
};

export const FormGlobalError = ({
  form,
  fieldName,
}: TFormGlobalErrorProps) => {
  const error = useWatchError(form, fieldName);
  return (
    <>
      {!_.isEmpty(error) && (
        <ErrorView
          focusOnMount={true}
          message={error}
        />
      )}
    </>
  );
};
