import * as React from 'react';
import { RoutesLanding, TRoutesLandingNames } from '../../../core/src/lib/apis/routes/RoutableLanding';
import { FirstParameter } from '../../../core/src/lib/Types';
import { useGetLocalizedString, useLocale } from '../locale/useLocale';
import { isPreRendering } from '../lib/ReactSnap';
import { useNavigator } from '../app/navigation/Navigator';
import { LocalizedRedirect } from './router/LocalizedRedirect';
import { RoutesLandingOptions } from '../lib/RoutesLandingOptions';
import { enumToArray } from '../../../core/src/lib/HelperFunctions';
import { ELocale, localeToLang } from '../../../core/src/locale/Locale';
import { useSingleSignOnCheck } from '../../../lib-react/src/contexts-browser/useSingleSignOnCheck';
import { useContinueUrls } from '../../../lib-react/src/contexts-browser/useContinueUrls';
import { UserContext } from '../app/user/UserContext';

function useIdentifiedRedirect<R extends TRoutesLandingNames>(route: R): boolean {
  const routeOptions = RoutesLandingOptions[route];
  const { isLoggedIn } = React.useContext(UserContext);
  return routeOptions.requireLogin
    && !isLoggedIn
    && !isPreRendering();
}

function useAnonymousRedirect<R extends TRoutesLandingNames>(route: R): boolean {
  const routeOptions = RoutesLandingOptions[route];
  const { isLoggedIn } = React.useContext(UserContext);
  return routeOptions.onlyAnonymous
    && isLoggedIn
    && !isPreRendering();
}

function useSetTitle<R extends TRoutesLandingNames>(route: R) {
  const routeOptions = RoutesLandingOptions[route];
  const getLocalizedString = useGetLocalizedString();
  document.title = getLocalizedString(routeOptions.title);
}

function useSetLangHeaders<R extends TRoutesLandingNames>(route: R) {
  const locale = useLocale();
  const { navigator } = useNavigator();

  function langLinkToHead({
    hrefLang,
    href,
  }: any) {
    const link = document.createElement('link');
    link.setAttribute('rel', 'alternate');
    link.setAttribute('hrefLang', hrefLang);
    link.setAttribute('href', href);
    document.head.appendChild(link);
  }

  // Set main lang
  document.head.lang = localeToLang(locale);

  // Add current locale
  langLinkToHead({
    hrefLang: 'x-default',
    href: navigator.locationHref({
      path: RoutesLanding[route].path.replace('/:locale', ''),
      search: undefined as any,
    }),
  });

  // Add other locales
  enumToArray<ELocale>(ELocale).forEach((supportedLocale) => {
    langLinkToHead({
      hrefLang: localeToLang(supportedLocale),
      href: navigator.locationHref({
        route,
        locale: supportedLocale,
        search: undefined as any,
      }),
    });
  });
}

export function withScreenParameters<R extends TRoutesLandingNames, T extends React.FunctionComponent>(route: R, Component: T): T {
  return function (props: FirstParameter<T>) {
    const { navigatorRef } = useNavigator();

    useSingleSignOnCheck({
      onRedirectToAuthScreen(): void {
        navigatorRef.current.routeReplace({
          route: RoutesLanding.Login.name,
          hash: RoutesLanding.Login.hashes.top,
          search: { redirectUrl: window.location.href },
        });
      },
    });
    useContinueUrls({});

    const shouldRedirectToLogin = useIdentifiedRedirect(route);
    const shouldRedirectToProfile = useAnonymousRedirect(route);
    useSetTitle(route);
    useSetLangHeaders(route);

    if (shouldRedirectToLogin) {
      return (
        <LocalizedRedirect
          route={RoutesLanding.Login.name}
          hash={RoutesLanding.Login.hashes.top}
          search={{}}
        />
      );
    }

    if (shouldRedirectToProfile) {
      return (
        <LocalizedRedirect
          route={RoutesLanding.Profile.name}
          hash={RoutesLanding.Profile.hashes.top}
          search={{}}
        />
      );
    }

    return (
      // @ts-ignore
      <Component {...props} />
    );
  } as any as T;
}
