import * as React from 'react';
import * as _ from 'lodash';
import { formatRules } from './formatRules';
import { Controller } from 'react-hook-form';
import {
  fieldIsRequired,
  TAnyForm,
  TFormFieldDefinition,
} from './useForm';
import styled from 'styled-components';
import { MuiTextField } from '../Mui';
import { MediaMaxWidth } from '../header/HeaderStyle';
import { Str } from '../localized/Str';

type TFormTextFieldProps<Name = any> = {
  form: TAnyForm;
  definition: TFormFieldDefinition<Name, string>;
  label: React.ReactNode;
  helperText?: React.ReactNode;
  type?: string;
  variant?: 'standard' | 'outlined';
  siblingCount?: number;
  inputRef?: React.MutableRefObject<any>;
};

export function FormTextField<Name>({
  form,
  definition,
  label,
  helperText,
  type = 'text',
  variant = 'outlined',
  siblingCount = 0,
  inputRef,
}: TFormTextFieldProps<Name>) {
  const fieldName = definition.name as any;
  return (
    <Controller
      defaultValue={definition.defaultValue}
      control={form.control}
      name={fieldName}
      rules={formatRules({
        form,
        definition,
      })}
      render={function ({ field, fieldState }) {
        const [error, setError] = React.useState('');
        React.useEffect(() => setError(fieldState?.error?.message ?? ''), [setError, fieldState.invalid]);
        return (
          <Spacer siblingCount={siblingCount}>
            <StyledMuiTextField
              {...field}
              fullWidth
              labelBackground={variant === 'standard' ? 'transparent' : undefined}
              variant={variant}
              type={type}
              required={fieldIsRequired(definition)}
              label={label}
              inputRef={inputRef}
              error={fieldState.invalid}
              helperText={_.isEmpty(error)
                ? helperText
                : <Str k={error}/>}
            />
          </Spacer>
        );
      }}
    />
  );
}

const Spacer = styled.div<{ siblingCount: number }>`
  margin: 4px 0;
  width: ${({ siblingCount }) => (() => {
    return siblingCount >= 2
      ? (100 - (2 * siblingCount)) / siblingCount
      : 100;
  })()}%;

  @media ${MediaMaxWidth.md} {
    width: 100%;
    margin: 8px 0;
  }
`;

const StyledMuiTextField = styled(MuiTextField)`
`;
