import * as React from 'react';
import { TPlacesResponse } from '../../placesPicker/GooglePlacesModule';
import { setIfPropertyNotEmpty } from '../../../../../core/src/lib/HelperFunctions';
import { GoogleAddressComponentTypes, googleFindAddressComponent } from '../../placesPicker/GoogleMapsHelpers';
import { useGooglePlacesPicker } from '../../placesPicker/useGooglePlacesPicker';
import { useWizardSalesmanState } from './useWizardSalesmanState';

export function useWizardSalesmanMapPicker() {
  const mapDivId = 'map';
  const { setState } = useWizardSalesmanState();

  const onSinglePlacePick = React.useCallback((obj: TPlacesResponse) => {
    setIfPropertyNotEmpty(
      (hostName: string) => setState({ hostName }),
      obj?.name,
    );

    setIfPropertyNotEmpty(
      (hostCoordinates: string) => setState({ hostCoordinates }),
      obj?.geometry?.location,
      (location) => `${location.lat()},${location.lng()}`,
    );

    setIfPropertyNotEmpty(
      (hostPlaceId: string) => setState({ hostPlaceId }),
      obj?.place_id,
    );

    setIfPropertyNotEmpty(
      (hostAddressLine1: string) => setState({ hostAddressLine1 }),
      obj?.formatted_address,
    );

    setIfPropertyNotEmpty(
      (hostPhone: string) => setState({ hostPhone }),
      obj?.international_phone_number,
    );

    setIfPropertyNotEmpty(
      (hostCountry: string) => setState({ hostCountry }),
      googleFindAddressComponent(obj, GoogleAddressComponentTypes.country),
      (comp) => comp.short_name,
    );

    setIfPropertyNotEmpty(
      (hostState: string) => setState({ hostState }),
      googleFindAddressComponent(obj, GoogleAddressComponentTypes.administrative_area_level_1),
      (comp) => comp.long_name,
    );

    setIfPropertyNotEmpty(
      (hostPostalCode: string) => setState({ hostPostalCode }),
      googleFindAddressComponent(obj, GoogleAddressComponentTypes.postal_code),
      (comp) => comp.short_name,
    );

    setIfPropertyNotEmpty(
      (hostCity: string) => setState({ hostCity }),
      googleFindAddressComponent(obj, GoogleAddressComponentTypes.administrative_area_level_2),
      (comp) => comp.long_name,
    );
  }, [setState]);

  const [search, setSearch] = React.useState('');

  const {
    onSearchChange: _onSearchChange,
    currentPickerPlaces,
    onSelectExternally,
  } = useGooglePlacesPicker({
    mapDivId,
    onSinglePlacePick,
  });

  const onSearchChange = React.useCallback((value: string) => {
    setSearch(value);
    _onSearchChange(value);
  }, [setSearch, _onSearchChange]);

  return {
    mapDivId,
    search,
    setSearch,
    currentPickerPlaces,
    onSelectExternally,
    onSinglePlacePick,
    onSearchChange,
  };
}
