import * as React from 'react';
import styled from 'styled-components';

type TProfileBoxHeaderProps = {
  title: React.ReactNode;
  description?: React.ReactNode;
};

export const ProfileBoxHeader = ({
  title,
  description,
}: TProfileBoxHeaderProps) => {
  return (
    <Root>
      <TitleContainer>
        {title}
      </TitleContainer>
      {description && (
        <DescriptionContainer>
          {description}
        </DescriptionContainer>
      )}
    </Root>
  );
};

const Root = styled.div`
`;

const TitleContainer = styled.div`
  color: #2B2C33;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
`;

const DescriptionContainer = styled.div`
  color: #2B2C33;
  font-size: 16px;
  font-weight: initial;
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 20px;
  text-align: center;
  text-transform: initial;
`;
