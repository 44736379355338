import * as React from 'react';
import { Str } from '../../components/localized/Str';
import { LocalizedImg } from '../../components/localized/LocalizedImg';
import { Strings } from '../../../../core/src/locale/Strings';

type THomeSectionPictureProps = {
  //
};

export const HomeSectionPicture = (props: THomeSectionPictureProps) => {
  return (
    <section className="process">
      <div className="process__wrapper u-wrapper">
        <h4>
          <Str k={Strings.keys.landingProcessEyelet}/>
        </h4>
        <h3 className="u-title">
          <Str k={Strings.keys.landingProcessTitle}/>
        </h3>

        <div className="process__filters">
          <button className="filter-button filter-button--active" data-ref="restaurant">
            <Str k={Strings.keys.landingProcessFiltersRestaurant}/>
          </button>
          <button className="filter-button" data-ref="customer">
            <Str k={Strings.keys.landingProcessFiltersCustomer}/>
          </button>
        </div>

        <div className="process__icon-list">
          <ul className="icon-list" data-list="restaurant">

            <li className="icon-list__item icon-list__item--icon">
              <LocalizedImg k={Strings.keys.landingAssetHomeProcessRestaurant0Picture}/>
              <p className="icon-list__content"><Str k={Strings.keys.landingProcessIconsRestaurant0Content}/></p>
            </li>

            <li className="icon-list__item icon-list__item--dotted">
              <svg className="line line--dsk" viewBox="0 0 100 1" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="100" y2="0"/>
              </svg>
              <svg className="line line--mbl" viewBox="0 0 1 100" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="0" y2="100"/>
              </svg>
            </li>

            <li className="icon-list__item icon-list__item--icon">
              <LocalizedImg k={Strings.keys.landingAssetHomeProcessRestaurant1Picture}/>
              <p className="icon-list__content"><Str k={Strings.keys.landingProcessIconsRestaurant1Content}/></p>
            </li>

            <li className="icon-list__item icon-list__item--dotted">
              <svg className="line line--dsk" viewBox="0 0 100 1" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="100" y2="0"/>
              </svg>
              <svg className="line line--mbl" viewBox="0 0 1 100" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="0" y2="100"/>
              </svg>
            </li>

            <li className="icon-list__item icon-list__item--icon">
              <LocalizedImg k={Strings.keys.landingAssetHomeProcessRestaurant2Picture}/>
              <p className="icon-list__content"><Str k={Strings.keys.landingProcessIconsRestaurant2Content}/></p>
            </li>

            <li className="icon-list__item icon-list__item--dotted">
              <svg className="line line--dsk" viewBox="0 0 100 1" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="100" y2="0"/>
              </svg>
              <svg className="line line--mbl" viewBox="0 0 1 100" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="0" y2="100"/>
              </svg>
            </li>

            <li className="icon-list__item icon-list__item--icon">
              <LocalizedImg k={Strings.keys.landingAssetHomeProcessRestaurant3Picture}/>
              <p className="icon-list__content"><Str k={Strings.keys.landingProcessIconsRestaurant3Content}/></p>
            </li>

            <li className="icon-list__item icon-list__item--dotted">
              <svg className="line line--dsk" viewBox="0 0 100 1" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="100" y2="0"/>
              </svg>
              <svg className="line line--mbl" viewBox="0 0 1 100" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="0" y2="100"/>
              </svg>
            </li>

            <li className="icon-list__item icon-list__item--icon">
              <LocalizedImg k={Strings.keys.landingAssetHomeProcessRestaurant4Picture}/>
              <p className="icon-list__content"><Str k={Strings.keys.landingProcessIconsRestaurant4Content}/></p>
            </li>

            <li className="icon-list__item icon-list__item--dotted">
              <svg className="line line--dsk" viewBox="0 0 100 1" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="100" y2="0"/>
              </svg>
              <svg className="line line--mbl" viewBox="0 0 1 100" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="0" y2="100"/>
              </svg>
            </li>

            <li className="icon-list__item icon-list__item--icon">
              <LocalizedImg k={Strings.keys.landingAssetHomeProcessRestaurant5Picture}/>
              <p className="icon-list__content"><Str k={Strings.keys.landingProcessIconsRestaurant5Content}/></p>
            </li>

            <li className="icon-list__item icon-list__item--dotted">
              <svg className="line line--dsk" viewBox="0 0 100 1" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="100" y2="0"/>
              </svg>
              <svg className="line line--mbl" viewBox="0 0 1 100" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="0" y2="100"/>
              </svg>
            </li>

          </ul>

          <ul className="icon-list" data-list="customer">
            <li className="icon-list__item icon-list__item--icon">
              <LocalizedImg k={Strings.keys.landingAssetHomeProcessCustomer0Picture}/>
              <p className="icon-list__content"><Str k={Strings.keys.landingProcessIconsCustomer0Content}/></p>
            </li>

            <li className="icon-list__item icon-list__item--dotted">
              <svg className="line line--dsk" viewBox="0 0 100 1" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="100" y2="0"/>
              </svg>
              <svg className="line line--mbl" viewBox="0 0 1 100" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="0" y2="100"/>
              </svg>
            </li>

            <li className="icon-list__item icon-list__item--icon">
              <LocalizedImg k={Strings.keys.landingAssetHomeProcessCustomer1Picture}/>
              <p className="icon-list__content"><Str k={Strings.keys.landingProcessIconsCustomer1Content}/></p>
            </li>

            <li className="icon-list__item icon-list__item--dotted">
              <svg className="line line--dsk" viewBox="0 0 100 1" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="100" y2="0"/>
              </svg>
              <svg className="line line--mbl" viewBox="0 0 1 100" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="0" y2="100"/>
              </svg>
            </li>

            <li className="icon-list__item icon-list__item--icon">
              <LocalizedImg k={Strings.keys.landingAssetHomeProcessCustomer2Picture}/>
              <p className="icon-list__content"><Str k={Strings.keys.landingProcessIconsCustomer2Content}/></p>
            </li>

            <li className="icon-list__item icon-list__item--dotted">
              <svg className="line line--dsk" viewBox="0 0 100 1" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="100" y2="0"/>
              </svg>
              <svg className="line line--mbl" viewBox="0 0 1 100" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="0" y2="100"/>
              </svg>
            </li>

            <li className="icon-list__item icon-list__item--icon">
              <LocalizedImg k={Strings.keys.landingAssetHomeProcessCustomer3Picture}/>
              <p className="icon-list__content"><Str k={Strings.keys.landingProcessIconsCustomer3Content}/></p>
            </li>

            <li className="icon-list__item icon-list__item--dotted">
              <svg className="line line--dsk" viewBox="0 0 100 1" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="100" y2="0"/>
              </svg>
              <svg className="line line--mbl" viewBox="0 0 1 100" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="0" y2="100"/>
              </svg>
            </li>

            <li className="icon-list__item icon-list__item--icon">
              <LocalizedImg k={Strings.keys.landingAssetHomeProcessCustomer4Picture}/>
              <p className="icon-list__content"><Str k={Strings.keys.landingProcessIconsCustomer4Content}/></p>
            </li>

            <li className="icon-list__item icon-list__item--dotted">
              <svg className="line line--dsk" viewBox="0 0 100 1" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="100" y2="0"/>
              </svg>
              <svg className="line line--mbl" viewBox="0 0 1 100" xmlns="http://www.w3.org/2000/svg">
                <line stroke="#fff" strokeLinecap="round" strokeLinejoin="round" x1="0" y1="0" x2="0" y2="100"/>
              </svg>
            </li>

          </ul>
        </div>
      </div>
    </section>
  );
};
