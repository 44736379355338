import * as React from 'react';

import { useInitializeHomeBindings } from './useInitializeHomeBindings';
import styled from 'styled-components';
import { Header } from '../../components/header/Header';
import { HomeSectionHalfPicture } from './HomeSectionHalfPicture';
import { HomeSectionHero } from './HomeSectionHero';
import { HomeSectionInfo } from './HomeSectionInfo';
import { HomeSectionPicture } from './HomeSectionPicture';
import { HomeSectionAdvantages } from './HomeSectionAdvantages';
import { HomeSectionContact } from './HomeSectionContact';
import { HomeSectionDownload } from './HomeSectionDownload';
import { Footer } from '../../components/Footer';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';

export function ScreenHome() {
  useInitializeHomeBindings();
  return (
    <Root id={RoutesLanding.Home.hashes.top}>
      <Header/>
      <Content>
        <HomeSectionHero/>
        <HomeSectionHalfPicture/>
        <HomeSectionInfo/>
        <HomeSectionPicture/>
        <HomeSectionAdvantages/>
        <HomeSectionContact/>
        <HomeSectionDownload/>
      </Content>
      <Footer/>
    </Root>
  );
}

const Root = styled.div``;

const Content = styled.div``;
