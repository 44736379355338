import * as React from 'react';
import { TRoutesLandingNames } from '../../../../core/src/lib/apis/routes/RoutableLanding';

import { HashLink } from 'react-router-hash-link';
import {
  TNavigationParam,
  useNavigator,
} from '../../app/navigation/Navigator';
import * as _ from 'lodash';

type TLocalizedLinkProps<R extends TRoutesLandingNames> = TNavigationParam<R> & {
  title: React.ReactNode;
  hashLinkKey?: string;
  className?: string;
};

export function LocalizedLink<R extends TRoutesLandingNames>({
  title,
  hashLinkKey,
  className,
  ...locationParams
}: TLocalizedLinkProps<R>) {
  const { navigator } = useNavigator();
  const location = navigator.location(locationParams);
  const search = !_.isEmpty(window.location.search) ? `${window.location.search}` : '';
  const hashStr = !_.isEmpty(location.hash) ? `${location.hash}` : '';
  const to = `${location.pathname}${search}${hashStr}`;
  return (
    <HashLink
      key={hashLinkKey ?? to}
      className={className}
      to={to}
      smooth
    >
      {title}
    </HashLink>
  );
}

