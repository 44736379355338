import * as React from 'react';
import styled from 'styled-components';
import { useToggle } from '../../../lib-react/src/hooks/useToggle';
import { useOnClickOutside } from '../../../lib-react/src/contexts-browser/useOnClickOutside';

export type TDropdownListProps<Pickable> = {
  pickables: Pickable[];
  onPick: (pickable: Pickable) => void;
  pickableToString: (pickable: Pickable) => string;
  backgroundColor: string;
  color: string;
  renderAnchor: (setFocused: (focused: boolean) => void) => React.ReactNode;
};

export function DropdownList<Pickable>({
  pickables,
  onPick,
  pickableToString,
  renderAnchor,
  backgroundColor,
  color,
}: TDropdownListProps<Pickable>) {
  const [anchorFocused, , setAnchorFocused, setAnchorUnfocused] = useToggle(false);

  const [anchorRef, setAnchorRef] = React.useState<any>();
  const rootRef = React.useRef();
  const position = anchorRef?.getBoundingClientRect();

  const visible = anchorFocused && position != null;

  useOnClickOutside({
    ref: rootRef,
    onClickOutside: setAnchorUnfocused,
  });

  return (
    <Root ref={rootRef}>
      <Anchor ref={(r) => {
        // @ts-ignore
        setAnchorRef(r);
      }}>
        {renderAnchor(setAnchorFocused)}
      </Anchor>
      <Dropdown
        backgroundColor={backgroundColor}
        top={position?.bottom ?? 0}
        visible={visible}
        left={position?.left ?? 0}
        width={position?.right - position?.left}
      >
        {pickables.map((pickable) => {
          const name = pickableToString(pickable);
          return (
            <Pickable
              key={name}
              color={color}
              onClick={() => {
                onPick(pickable);
                setAnchorUnfocused();
              }}>
              {name}
            </Pickable>
          );
        })}
      </Dropdown>
    </Root>
  );
}

const Root = styled.div`
`;

const Anchor = styled.div`
`;

type TDropdownProps = {
  visible: boolean;
  top: number;
  left: number;
  width: number;
  backgroundColor: string;
};

const Dropdown = styled.div<TDropdownProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
  left: ${({ left }) => left};
  max-width: ${({ width }) => width}px;
  padding: 10px;
  position: absolute;
  top: ${({ top }) => top};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  width: ${({ width }) => width}px;
  z-index: 99999;
`;

const Pickable = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  cursor: pointer;
`;
