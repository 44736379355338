import * as React from 'react';
import { useNavigator } from '../navigation/Navigator';
import { AppAlacarteApiAccess } from '../../lib/api/AppAlacarteApiAccess';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';

export function useStripeOnCheckoutClick(stripeCheckoutPriceId: string) {
  const { navigatorRef } = useNavigator();

  // todo creates url's that are too long. They need to be cleaned
  return React.useCallback(async () => {
    const navigator = navigatorRef.current;
    const profileHref = navigator.getHrefToRoute({
      route: RoutesLanding.Profile.name,
      hash: RoutesLanding.Profile.hashes.top,
      search: {},
    });

    const { redirect } = await AppAlacarteApiAccess.stripeCheckoutSessionCreate.request({
      body: {
        successUrl: profileHref,
        cancelUrl: profileHref,
        stripeCheckoutPriceId,
      },
    });
    window.location.href = redirect;
  }, [navigatorRef, stripeCheckoutPriceId]);
}
