import * as React from 'react';
import { StyledHotKeys } from '../../StyledHotKeys';
import { WizardScreen } from '../WizardScreen';
import { Str } from '../../localized/Str';
import { Strings } from '../../../../../core/src/locale/Strings';
import { useFormFocusController } from '../../../../../lib-react/src/hooks/useFormFocusController';
import { Colors } from '../../../config/Colors';
import { RoutesLanding } from '../../../../../core/src/lib/apis/routes/RoutableLanding';
import { useWizardSalesmanNavigator } from './useWizardSalesmanNavigator';
import { useWizardSalesmanErrors } from './useWizardSalesmanErrors';
import styled from 'styled-components';
import { fixedHeight } from '../../css';
import { SvgAlacarteLogoVertical } from '../../../assets/Assets';
import { useWizardSalesmanAnalyticsTracker } from './useWizardSalesmanAnalyticsTracker';
import { WizardTheme } from './WizardTheme';

export const ScreenWizardSalesmanDone = () => {
  useWizardSalesmanAnalyticsTracker();

  const { onGeneralFocus } = useFormFocusController();

  const navigateToSignupScreen = useWizardSalesmanNavigator(RoutesLanding.Signup.name);
  const errors = useWizardSalesmanErrors({});
  return (
    <StyledHotKeys
      keyMap={{ ENTER: 'Enter' }}
      handlers={{ ENTER: onGeneralFocus }}
    >
      <WizardScreen
        theme={WizardTheme}
        title={<Str k={Strings.keys.landingWizardSalesDoneTitle}/>}
        description={<DoneContentImg src={SvgAlacarteLogoVertical}/>}
        globalError={errors.globalError}
        content={(
          <DoneContentRoot>
            <DoneContentInfo>
              <Str k={Strings.keys.landingWizardSalesDoneInfo}/>
            </DoneContentInfo>
            <DoneContentContinue>
              <Str k={Strings.keys.landingWizardSalesDoneContinue}/>
            </DoneContentContinue>
            <DoneContentContinueButton
              onClick={navigateToSignupScreen}
              theme={WizardTheme}>
              <Str k={Strings.keys.landingWizardSalesDoneContinueLabel}/>
            </DoneContentContinueButton>
          </DoneContentRoot>
        )}
      />
    </StyledHotKeys>
  );
};

const DoneContentRoot = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;

  & > * {
    margin-top: 16px;
  }
`;

const DoneContentImg = styled.img`
  ${fixedHeight(120)};
  margin-top: 48px;
`;

const DoneContentInfo = styled.div`
  color: ${Colors.red};
  font-size: 1.5rem;
  text-align: center;
`;

const DoneContentContinue = styled.div`
  font-size: 0.9rem;
  margin-top: 64px;
  text-align: center;
`;

const DoneContentContinueButton = styled.div`
  align-items: center;
  ${fixedHeight(40)};
  background-color: ${({ theme }) => theme.colorAccent};
  color: ${({ theme }) => theme.colorPrimary};
  cursor: pointer;
  display: flex;
  font-size: 0.7rem;
  font-weight: 800;
  justify-content: center;
  letter-spacing: 1px;
  line-height: 15px;
  margin-top: 64px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`;
