import * as React from 'react';
import styled from 'styled-components';
import { TPlacesResponse } from './GooglePlacesModule';
import { fixedHeight } from '../css';
import { MuiTextField } from '../Mui';
import { useGooglePlacesPicker } from './useGooglePlacesPicker';
import { Str } from '../localized/Str';
import { Strings } from '../../../../core/src/locale/Strings';
import { DropdownList } from '../DropdownList';
import { Colors } from '../../config/Colors';

type TPlacesPickerProps = {
  onSinglePlacePick: (obj: TPlacesResponse) => void;
  inputRef?: React.MutableRefObject<any>;
};

export const PlacesPicker = ({
  onSinglePlacePick,
  inputRef,
}: TPlacesPickerProps) => {
  const mapDivId = 'map';

  const {
    onSearchChange,
    currentPickerPlaces,
    onSelectExternally,
  } = useGooglePlacesPicker({
    mapDivId,
    onSinglePlacePick,
  });

  const [search, setSearch] = React.useState('');
  const onSetSearch = React.useCallback((e) => {
    setSearch(e.target.value);
    onSearchChange(e.target.value);
  }, [setSearch]);

  return (
    <Root>
      <DropdownList
        backgroundColor={Colors.white}
        color={Colors.accentDark}
        pickables={currentPickerPlaces}
        onPick={(place) => {
          onSelectExternally(place);
          setSearch(`${place.name} - ${place.formatted_address}`);
        }}
        pickableToString={(picker) => `${picker.name} - ${picker.formatted_address}`}
        renderAnchor={(setFocused) => (
          <SearchBoxContainer key={'PlacesPickerSearchBox'}>
            <StyledStyledMuiTextField
              variant="standard"
              label={<Str k={Strings.keys.landingPlacesSearch}/>}
              value={search}
              onChange={onSetSearch}
              inputRef={inputRef}
              onFocus={() => setFocused(true)}
            />
          </SearchBoxContainer>
        )}
      />
      <StyledMap id={mapDivId}/>
    </Root>
  );
};

const Root = styled.div`
  ${fixedHeight(400)};
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StyledMap = styled.div`
  display: flex;
  flex: 1;
  height: 400px;
`;

const SearchBoxContainer = styled.div`
`;

const StyledStyledMuiTextField = styled(MuiTextField)`
  width: 100%;
`;
