import * as React from 'react';
import { useWizardSalesmanState } from './useWizardSalesmanState';
import { Analytics } from '../../../lib/analytics/Analytics';
import { AnalyticsEvents } from '../../../lib/analytics/AnalyticsEvents';
import * as _ from 'lodash';
import { useSyncedDataRef } from '../../../../../lib-react/src/hooks/useSyncedDataRef';

export function useWizardSalesmanAnalyticsTracker() {
  const { baseURL, state } = useWizardSalesmanState();

  const getAnalyticsEventRef = useSyncedDataRef(function () {
    return {
      eventId: AnalyticsEvents.WizardSalesman.eventId,
      stepId: _.last(`${baseURL}`.split('/')),
      email: state.email,
      hostAddressLine1: state.hostAddressLine1,
      hostCity: state.hostCity,
      hostCoordinates: state.hostCoordinates,
      hostCountry: state.hostCountry,
      hostPhone: state.hostPhone,
      hostPlaceId: state.hostPlaceId,
      hostPostalCode: state.hostPostalCode,
      hostState: state.hostState,
      referralId: state.referralId,
      sessionId: state.sessionId,
    };
  });

  React.useEffect(() => {
    (async () => {
      await Analytics.track(getAnalyticsEventRef.current());
    })();

    return () => {
      (async () => {
        await Analytics.track(getAnalyticsEventRef.current());
      })();
    };
  }, []);
}
