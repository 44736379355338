import * as React from 'react';
import { TFormValueTypes } from '../form/useForm';
import { FormDefLogin } from './FormDefLogin';
import { Log } from '../../config/Instance';
import { TFormLogin } from './useFormLogin';
import { stripMetaFields } from '../../../../core/src/lib/stripMetaFields';
import { useNavigator } from '../../app/navigation/Navigator';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';
import { useNavigateOnLoginSuccess } from '../../app/navigation/useNavigateOnLoginSuccess';
import { UserContext } from '../../app/user/UserContext';

export function useFormLoginOnSubmit(form: TFormLogin) {
  const { onHostLogin } = React.useContext(UserContext);
  const navigateOnLoginSuccess = useNavigateOnLoginSuccess();

  const { navigatorRef } = useNavigator();
  const onSubmit = React.useCallback(async (data: TFormValueTypes<typeof FormDefLogin>) => {
    try {
      await onHostLogin({ ...stripMetaFields(data) });
      navigateOnLoginSuccess();
    } catch (e) {
      Log.v('useFormLoginOnSubmit', 'useFormLoginOnSubmit', `Login error ${e?.message}`);
      navigatorRef.current.routeReplace({
        route: RoutesLanding.Login.name,
        hash: RoutesLanding.Login.hashes.top,
        search: {},
      });
    }
  }, [
    onHostLogin,
    navigatorRef,
    navigateOnLoginSuccess,
  ]);

  return form.handleSubmit(onSubmit);
}
