import * as React from 'react';
import { TGenericRoutableRoute } from '../../../core/src/lib/define/routable/RoutableTypes';
import { RoutableRoute } from '../../../core/src/lib/define/routable/RoutableRoute';

export function useDeconstructedUrl<Route extends TGenericRoutableRoute>(route: Route, url: string) {
  const toolbox = RoutableRoute.buildURLToolbox(route);
  return React.useMemo(() => {
    return toolbox.deconstructURL(url);
  }, [route.name, url]);
}
