import * as React from 'react';

import { ScreenHeader } from '../../components/ScreenHeader';
import styled from 'styled-components';
import { fixedWidth } from '../../components/css';
import { ScreenFrame } from '../../components/ScreenFrame';
import { Str } from '../../components/localized/Str';
import { Strings } from '../../../../core/src/locale/Strings';
import { LoginForm } from './LoginForm';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';

export const ScreenLogin = () => {
  return (
    <ScreenFrame id={RoutesLanding.Login.hashes.top}>
      <CenterContent>
        <StyledHeader
          title={<Str k={Strings.keys.landingScreenLoginTitle}/>}
          description={<Str k={Strings.keys.landingScreenLoginDescription}/>}
        />
        <ContentRoot>
          <Content>
            <LoginForm/>
          </Content>
        </ContentRoot>
      </CenterContent>
    </ScreenFrame>
  );
};

const CenterContent = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const ContentRoot = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  ${fixedWidth(440)};
  display: flex;
  justify-content: center;
`;

const StyledHeader = styled(ScreenHeader)`
  padding-top: 120px;
`;
