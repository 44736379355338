import * as React from 'react';
import { User } from '../../app/user/User';
import { Colors } from '../../config/Colors';
import styled from 'styled-components';
import { useStripeOnManageSubscriptionClick } from '../../app/stripe/useStripeOnManageSubscriptionClick';
import { useNavigator } from '../../app/navigation/Navigator';
import { Dt } from '../../../../core/src/lib/formatters/Dt';
import { ProfileBoxSimpleAction } from './ProfileBoxSimpleAction';
import { Str } from '../../components/localized/Str';
import { RoutesLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';
import { Strings } from '../../../../core/src/locale/Strings';
import { UserContext } from '../../app/user/UserContext';

export const ProfileBoxHostSubscription = () => {
  const { hostPublicSubscription } = React.useContext(UserContext);
  return (
    <>
      {User.hasActiveSubscription(hostPublicSubscription) && (
        <ProfileBoxHostActiveSubscription/>
      )}
      {!User.hasActiveSubscription(hostPublicSubscription) && (
        <ProfileBoxHostInactiveSubscription/>
      )}
    </>
  );
};

const ProfileBoxHostActiveSubscription = () => {
  const { hostPublicSubscription } = React.useContext(UserContext);
  const stripeOnManageSubscriptionClick = useStripeOnManageSubscriptionClick();
  return (
    <ProfileBoxSimpleAction
      themeColor={Colors.accentDark}
      negativeThemeColor={Colors.white}
      contentTitle={<Str k={Strings.keys.landingProfileSubscriptionTitle}/>}
      contentDescription={<Str k={Strings.keys.landingProfileSubscriptionType}/>}
      content={(
        <Content>
          <ContentSubscriptionType>
            {hostPublicSubscription?.stripeSubscriptionType}
          </ContentSubscriptionType>
          <ContentSubscriptionExpiry>
            <span>
              <Str k={Strings.keys.landingProfileSubscriptionExpiry}/>
              {' '}
              {Dt.dateFormat(hostPublicSubscription?.endsOn || 0)}
            </span>
          </ContentSubscriptionExpiry>
        </Content>
      )}
      ctaText={<Str k={Strings.keys.landingProfileSubscriptionManage}/>}
      ctaOnClick={stripeOnManageSubscriptionClick}
    />
  );
};

const ProfileBoxHostInactiveSubscription = () => {
  const { navigatorRef } = useNavigator();
  const onManageSubscriptionClick = React.useCallback(() => {
    navigatorRef.current.routePush({
      route: RoutesLanding.Plans.name,
      hash: RoutesLanding.Plans.hashes.top,
      search: {},
    });
  }, [navigatorRef]);
  return (
    <ProfileBoxSimpleAction
      themeColor={Colors.accentDark}
      negativeThemeColor={Colors.white}
      contentTitle={<Str k={Strings.keys.landingProfileSubscriptionTitle}/>}
      contentDescription={<Str k={Strings.keys.landingProfileSubscriptionNoSubscription}/>}
      content={(
        <Content>
          <>
          </>
        </Content>
      )}
      ctaText={<Str k={Strings.keys.landingPlansPlanStarterPriceCta}/>}
      ctaOnClick={onManageSubscriptionClick}
    />
  );
};

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px 0;
`;

export const ContentSubscriptionType = styled.div`
  color: #2B2C33;
  flex: 0.5;
  font-size: 42px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 40px;
  text-align: center;
`;

export const ContentSubscriptionExpiry = styled.div`
  align-items: center;
  color: #2B2C33;
  display: flex;
  flex: 0.5;
  font-size: 16px;
  font-weight: initial;
  justify-content: center;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
`;
